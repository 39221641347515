export const uploaderConfig = {
    global: {
        maxUploadFileSize: 209_715_200, // 100 MB - 104_857_600 byts, 200MB - 209_715_200
        autoCloseOnSuccess: true,
        autoCloseOnSuccessTimeout: 2000 // 2 s
    },
    session: {
        allowerMimeType: [
            "application/pdf",
            "image/jpeg",
            "image/jpg",
            "image/png",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/plain",
            "application/msword",
            "application/vnd.ms-excel",
            "text/csv",
            "application/octet-stream",
            "text/xml",
            "application/zip",
            "application/x-zip-compressed"
        ]
    },
    announcement: {
        allowerMimeType: [
            "application/pdf",
            "image/jpeg",
            "image/jpg",
            "image/png",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "text/plain",
            "application/msword",
            "application/octet-stream",
            "text/xml",
            "application/zip",
            "application/x-zip-compressed"
        ]
    },
    interpellation: {
        allowerMimeType: [
            "application/pdf",
            "image/jpeg",
            "image/jpg",
            "image/png",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "text/plain",
            "application/vnd.oasis.opendocument.text",
            "application/vnd.oasis.opendocument.spreadsheet",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/rtf",
            "application/msword"
        ]
    },
    document: {
        allowerMimeType: [
            "application/pdf",
            "image/jpeg",
            "image/jpg",
            "image/png",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "text/plain",
            "application/msword",
            "application/octet-stream",
            "text/xml",
            "application/zip",
            "application/x-zip-compressed"
        ]
    }
}