<template>
    <section class="discussion-guest d-flex flex-column gap-2" 
             tabindex="0"
             @keyup.esc="() => searchValue = ''" >
        <section v-if="isLock === false" class="d-flex flex-column gap-2">
            <section class="d-flex flex-row justify-content-center flex-wrap gap-2">
                <SearchInputTextComponent ref="searchPerson"
                                          v-model="searchValue"
                                          :placeholder="'Wyszukaj osobę...'"
                                          :withButton="false" />
                <AddNewQuestButton @enableAddNewGuestModal="$store.commit('showAddNewGuestModal')" />
            </section>

            <perfect-scrollbar class="discussion-guests-list d-flex flex-row flex-wrap gap-2 justify-content-center align-content-start">
                <DiscussionQuestItem v-for="(item, key) in suggestQuestList"
                                     :key="key" :item="item"
                                     @addParticipantToRequestSpeechList="addParticipantToRequestSpeechList"
                                     @giveParticipantSpeech="giveParticipantSpeech"
                                     @removeParticipantFromFavorite="addParticipantToFavorite"
                                     @addParticipantToFavorite="addParticipantToFavorite"
                                     @removeParticipant="removeParticipant" />
            </perfect-scrollbar>
            <Transition>
                <AddNewGuestModal v-if="$store.state.interactiveStore.isShowAddNewGuest" />
            </Transition>
        </section>
        <section class="d-flex flex-row justify-content-center flex-wrap gap-2 px-3 text-center" v-else>
            <h2 style="color: #000;">Brak aktywnego modułu dyskusji <br />lub sesja nie jest w trybie interaktywnym</h2>
        </section>
    </section>
</template>
<script charset="utf-8">
    // services
    import eSesjaInteractiveService from "../../../../services/esesja.interactive.service";
    import userService from "../../../../services/user.service";
    import interactiveService from "../../../../services/interactive.service";
    import { encryptStorage } from '../../../../services/encrypt.service';

    // components
    import DiscussionQuestItem from "./DiscussionQuestItem.vue";
    import AddNewQuestButton from "./AddNewQuestButton.vue";
    import AddNewGuestModal from "../Modals/AddNewGuestModal.vue"
    import SearchInputTextComponent from "../../../BasicControls/SearchInputTextComponent.vue";
import sessionService from "../../../../services/session.service";


    export default {
        name: "DiscussionQuestList",
        components: {
            DiscussionQuestItem,
            SearchInputTextComponent,
            AddNewQuestButton,
            AddNewGuestModal
        },
        computed: {
            sessionId() {
                return parseInt(this.$route.params.sessionId);
            },
            isDiscussionModule() {
                if (this.discussionModule == 1)
                    return true;

                return false;
            },

            isLock() {
                return (this.sessionStatus === false || this.isDiscussionModule === false);
            }
        },
        data() {
            return {
                internalAvailableQuestList: [],
                suggestQuestList: [],
                searchValue: "",
                isLoading: false,
                discussionModule: encryptStorage.getItem('modules').d,
                sessionStatus: false,
            }
        },
        watch: {
            searchValue: {
                immediate: true,
                handler(val) {
                    try {
                        this.$store.commit("showLoader");
                        if (val === '' || val === "") {
                            this.suggestQuestList = this.internalAvailableQuestList
                        }
                        else {
                            this.suggestQuestList = this.internalAvailableQuestList.map(item => { return { ...item } });
                            this.suggestQuestList = Array.from(this.suggestQuestList.filter(item => {
                                if (item.queryString.indexOf(val.toLowerCase()) !== -1) {
                                    if (item.name !== undefined && item.name !== null)
                                        item.name = item.name.replace(new RegExp(val, 'gi'), "<span class='highlite'>$&</span>");

                                    if (item.surname !== undefined && item.surname !== null)
                                        item.surname = item.surname.replace(new RegExp(val, 'gi'), "<span class='highlite'>$&</span>");
                                    return true;
                                }

                                return false;
                            }));
                        }
                    }
                    finally {
                        this.$store.commit("hideLoader");
                    }
                },
            },
        },
        async mounted() {
            try {
                if (this.isDiscussionModule === false) {
                    return;
                }
                this.$store.commit("showLoader");
                await this.internalFetchSessionInteractiveState();
                await this.internalConnectToInteractive();
                await this.fetchGuestLists();
                this.registerInteractiveEvents();
            }
            finally {
                this.$store.commit("hideLoader");
            }
        },
        unmounted() {
            this.unregisterInteractiveEvents();
        },
        methods: {
            async internalConnectToInteractive() {
                let responseRid = await userService.getCurrentRid();
                if (responseRid.status !== 200) {
                    throw await Promise.reject(responseRid);
                }

                await eSesjaInteractiveService.start(responseRid.data);
                await eSesjaInteractiveService.joinRoom(this.sessionId);
            },

            async fetchGuestLists() {
                let response = await interactiveService.getAvailableGuests();
                if (response.status !== 200) {
                    throw await Promise.reject(response);
                }
                this.prepareGuestData([...response.data])
            },

            async internalFetchSessionInteractiveState() {
                let response = await sessionService.isSessionInInteractive(this.sessionId);
                if (response.status !== 200) {
                    throw await Promise.reject(response);
                }
                this.sessionStatus = response.data;
            },

            prepareGuestData(data) {
                this.internalAvailableQuestList = data;
                this.internalAvailableQuestList = this.internalAvailableQuestList.map(item => {
                    let newItem = { ...item }
                    newItem.queryString = (`${item.name} ${item.surname}`).toLowerCase()
                    return newItem;
                });
                this.suggestQuestList = [...this.internalAvailableQuestList]
            },

            registerInteractiveEvents() {
                eSesjaInteractiveService.events.on("SignalRRefreshGuestLists", this.internalRefreshGuestLists)
                eSesjaInteractiveService.events.on("SignalRInteractiveSessionStateChange", this.internalInteractiveSessionState);
            },
            unregisterInteractiveEvents() {
                eSesjaInteractiveService.events.removeListener("SignalRRefreshGuestLists", this.internalRefreshGuestLists);
                eSesjaInteractiveService.events.removeListener("SignalRInteractiveSessionStateChange", this.internalInteractiveSessionState);
            },

            internalRefreshGuestLists(data) {
                try {
                    this.prepareGuestData(data);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            internalInteractiveSessionState(status) {
                this.sessionStatus = status;
            },

            async addParticipantToRequestSpeechList(uid, type) {
                try {
                    if (this.isDiscussionModule === false) {
                        return;
                    }
                    this.$store.commit("showLoader");
                    await eSesjaInteractiveService.addUserToRequestSpeech(uid, this.sessionId, type);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            async giveParticipantSpeech(uid) {
                try {
                    if (this.isDiscussionModule === false) {
                        return;
                    }
                    this.$store.commit("showLoader");
                    await eSesjaInteractiveService.giveUserSpeech(parseInt(uid), -1, this.sessionId, 0);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            async addParticipantToFavorite(uid) {
                try {
                    if (this.isDiscussionModule === false) {
                        return;
                    }
                    this.$store.commit("showLoader");
                    await eSesjaInteractiveService.addUserToFavourite(uid);
                }
                catch {
                    this.$store.commit("hideLoader");
                }
            },

            async removeParticipant(uid) {
                try {
                    if (this.isDiscussionModule === false) {
                        return;
                    }
                    this.$store.commit("showLoader");
                    await eSesjaInteractiveService.removeGuest(uid);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
        }
    }
</script>
<style lang="scss">
    .discussion-guest {
        width: 100vw;
        .discussion-guests-list{
            position: relative;
            height: calc(100vh - 100px);
        }
    }
</style>