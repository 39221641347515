<template>
    <section>
        <section class="header">
            <section class="header-left">
                <img src="../assets/imgs/logo.png" class="side-bar__logo" />
                
                <div class="header-right__text">
                    <span> {{client}} </span>
                    <img v-if="logo != null && logo.length > 0" :src="logo" class="logo" align="absmiddle" style="margin-left:20px" />
                </div>
                
            </section>
        </section>

        <section class="container" v-if="!error">
            <section class="session__top-left">
                <section class="session__top-box">
                    <img src="../assets/icons/panel/editSession/meeting-place.svg" />
                    <span class="session__top-text session__top-text--name"> {{session.name}}</span>
                </section>
                <section class="session__top-box">
                    <img src="../assets/icons/panel/editSession/meeting-date.svg" /><span class="session__top-text">{{ getDate }}</span>
                </section>
                <section class="session__top-box">
                    <img src="../assets/icons/panel/editSession/meeting-time.svg" /><span class="session__top-text">Godzina: {{ getTime }}</span>
                </section>
                <section class="session__top-box" v-if="session.meeting_place != null && session.meeting_place.length > 0">
                    <img src="../assets/icons/panel/editSession/location-dot.svg" /><span class="session__top-text">{{ session.meeting_place }}</span>
                </section>
            </section>

            <div class="sesion-container-wrapper">
                <section class="session-agenda">
                    <section v-for="(s) in session.agendaItems" :key="s.id">
                        <section class="box">
                            <section :class="s.is_active ? 'box__active' : 'box__top'" :id="'id-point-'+s.id">
                                <section>
                                    <div class="point-is-done" v-if="s.is_done">
                                        <img class="point-is-done__img" src="../assets/icons/panel/editSession/meeting-time.svg" />
                                        <span >Zakończony: {{ getTimeEndPoint(s) }}</span>
                                    </div>
                                    <div>
                                        <span class="box__title" :class="{'box--takenoff' : s.is_taken_off}">{{filterIndex(s, 0)}} {{ s.name }}</span>
                                    </div>
                                    <div v-if="s.reporter_name != null && s.reporter_name.length > 0" class="referer">
                                        <span>Referuje: <b>{{s.reporter_name}}</b></span>
                                    </div>

                                    <section v-for="(child) in s.children" :key="child.id">
                                        <section class="box-children">
                                            <section :class="child.is_active ? 'box__active' : 'box__top'" :id="'id-point-'+child.id">
                                                <section>
                                                    <div class="point-is-done" v-if="child.is_done">
                                                        <img class="point-is-done__img" src="../assets/icons/panel/editSession/meeting-time.svg" />
                                                        <span>Zakończony: {{ getTimeEndPoint(child) }}</span>
                                                    </div>
                                                    <div>
                                                        <span class="box__titlechildren" :class="{'box--takenoff' : child.is_taken_off}">{{filterIndex(child, 1)}} {{ child.name }}</span>
                                                        
                                                        <div v-if="child.reporter_name != null && child.reporter_name.length > 0" class="referer">
                                                            <span>Referuje: <b>{{child.reporter_name}}</b></span>
                                                        </div>
                                                        
                                                        <section v-for="(ch) in child.children" :key="ch.id">
                                                            <section class="box-children">
                                                                <section :class="ch.is_active ? 'box__active' : 'box__top'" :id="'id-point-'+ch.id">
                                                                    <section>
                                                                        <div class="point-is-done" v-if="ch.is_done">
                                                                            <img class="point-is-done__img" src="../assets/icons/panel/editSession/meeting-time.svg" />
                                                                            <span>Zakończony: {{ getTimeEndPoint(ch) }}</span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="box__titlechildren" :class="{'box--takenoff' : ch.is_taken_off}">{{filterIndex(ch, 2)}} {{ ch.name }}</span>
                                                                        </div>

                                                                        <div v-if="ch.reporter_name != null && ch.reporter_name.length > 0" class="referer">
                                                                            <span>Referuje: <b>{{ch.reporter_name}}</b></span>
                                                                        </div>

                                                                    </section>
                                                                </section>
                                                            </section>
                                                        </section>
                                                    </div>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
                <section class="disscusion-list" v-if="discussionList.length > 0">
                    <DiscussionListModal :discussionList="discussionList"></DiscussionListModal>
                </section>
            </div>
            
            <SpeekerModal v-if="showSpeekerModal" :speeker="speeker"></SpeekerModal>
            <InfoModal v-if="showInfoModal" :infoCase="infoCase" :elemToDispay="elemToDispay" :elemObj="elemObj"></InfoModal>
            <QuorumResultModal v-if="showQuorumResultModal" :quorumresult="quorumresult" :isPublicView="true"></QuorumResultModal>

            <InteractiveVotingResults v-if="resultsVotingVisible"
                                      :basicData="resultsVotingData"
                                      :isPublicView="true"
                                      :votingResults="res"
                                      :fromEndVoting="false"
                                      :chartData="chartData" />

            <InteractiveAttachmentModalVue v-if="showAttachmentViewer"
                                           :fileData="attachmentData.fileData"
                                           :page="attachmentData.page"
                                           :scale="attachmentData.scale"/>
            
            <InteractiveVotingPublicModal v-if="$store.state.public.interactiveVotingModalStatus"
                                    :votingPossibleAnswers="votingPossibleAnswers"
                                    :basicData="dataForVoting"
                                    :hiddenResults="hiddenResults"
                                     />

            <Transition>
                <PublicPlaceholderView v-if="$store.state.public.publicPlaceholderViewModalStatus" 
                                            :placeholder="placeholderData" />
            </Transition>
        </section>
    </section>
</template>

<script>
    import SpeekerModal from "../views/Modals/SpeekerModal.vue";
    import InfoModal from "../views/Modals/InfoModal.vue";
    import QuorumResultModal from "../components/Panel/Interactive/Modals/QuorumResultModal.vue"; 
    import DiscussionListModal from "../views/Modals/DiscussionListModal.vue";
    import InteractiveVotingResults from "../components/Panel/Content/SessionPoints/Modals/InteractiveVotingResults.vue"
    import PublicService from "../services/public.service";
    import InteractiveAttachmentModalVue from "./Modals/InteractiveAttachmentModal.vue";
    import InteractiveVotingPublicModal from "../views/Modals/InteractiveVotingPublicModal.vue";
    import PublicPlaceholderView from "../views/Modals/PublicPlaceholderView.vue";
    import moment from "moment";
    import esesjaInteractivePublicService from "../services/esesja.interactive.public.service";
    import signalrSocketAction from "../services/esesja.signalr.action";
    import AlertHelper from '@/helpers/alerts.helper.js';
    import PointsNumerationHelper from "@/helpers/pointsNumeration.helper";

export default {
    name: "PublicSession",
    data() {
        return {
            session: {},
            code: '',
            error: false,
            client: '',
            speeker: {},
            quorumresult: [],
            showSpeekerModal: false,
            showInfoModal: false,
            showQuorumResultModal: false,
            quorumIsRunning: false,
            resultsVotingVisible: false,
            hiddenResults: false,
            resultsVotingData: {},
            infoCase: 0, // 0 - basic, 1 - check quorum, 2 - voting, 3 - break 
            elemToDispay: '',
            elemObj: {},
            res: {},
            logo: '',
            numerations: {},
            tempActivePointData: null,
            discussionList: [],
            votingPossibleAnswers: [],
            tempPossibleAnswersNotVisible: [],
            dataForVoting: {},
            chartData: {
                    labels: [],
                    datasets: [{ data: [] }]
            },
            showAttachmentViewer: false,
            attachmentData: {
                fileData: {},
                page: 1,
                scale: 100,
            },
            placeholderData: {},
        };
        },
        watch: {
            showSpeekerModal: {
                immediate: true,
                handler(val) {
                    if(val === true)
                        document.body.classList.add("user-speak")
                    else
                        document.body.classList.remove("user-speak")
                }
            }
        },
    components: {
        SpeekerModal,
        InfoModal,
        QuorumResultModal,
        InteractiveVotingResults,
        DiscussionListModal,
        InteractiveAttachmentModalVue,
        InteractiveVotingPublicModal,
        PublicPlaceholderView
    },
    computed: {
        getDate() {
            return `${moment(this.session.meeting_date).format("dddd")}, ${moment(this.session.meeting_date).format("DD.MM.YYYY")}`;
        },
        getTime() {
            return moment(this.session.meeting_date).format("LT");
        },
    },
    mounted() {
        this.error = false;
        this.$store.commit("showLoader");
        this.code = this.$route.params.code;
        if (this.code != undefined && this.code != null && this.code.length == 79) {
            PublicService.getPublicSession(this.code).then((response) => {
                this.session = response.data.session;
                this.session.agendaItems = PointsNumerationHelper.setOrderToDisplayRecursive([...this.session.agendaItems]);
                this.client = response.data.client;
                this.discussionList = response.data.discussion.discussRequestList;
                this.numerations = response.data.numerations;
                let placeholder = response.data.placeholder;

                if(placeholder !== null){
                    let obj = {
                        placeholder: placeholder,
                        isActive: true,
                    }
                    this.ChangePlaceholderStatus(obj);
                }

                if (response.data.logo != null && response.data.logo.image != null) {
                    this.logo = response.data.logo.image;
                }
                else {
                    this.logo = '';
                }

                if (response.data.discussion.currentSpeech.userId > 0) {
                    this.speeker = response.data.discussion.currentSpeech;
                    this.showSpeekerModal = true;
                }

                if (response.data.sesbreak != null) {
                    this.SessionBreakStatusChange(response.data.sesbreak);
                }

                this.startInteractiveSession();
                this.registerInteractiveEvents();

                if (response.data.activePoint != null && response.data.activePoint > 0) {
                    var data = {
                        status: 0,
                        point:  response.data.activePoint
                    };

                    this.changeActivePoint(data);
                }

                this.$store.state.settings.client = response.data.clientSettings;
                
            }).catch((err) => {
                this.session = {};
                this.client = '';
                this.error = true;
                if (err.response.status == 404)
                    this.$swal(AlertHelper.errorAlert("Brak wskazanego posiedzenia lub posiedzenie tymczasowo ukryte"));
                else
                    this.$swal(AlertHelper.errorAlert("Wystąpił błąd - sprawdź dane dostępowe"));
            }).finally(() =>{
                this.$store.commit("hideLoader");
            })
        }
        else {
            this.session = {};
            this.error = true;
            this.$swal(AlertHelper.errorAlert("Błędne dane identyfikacyjne"));
        }

    },
    unmount() {
        this.unregisterInteractiveEvents();
    },
    methods: {
        filterIndex(agendaItem, level) {
            return PointsNumerationHelper.filterIndex(agendaItem.orderToDisplay, agendaItem.number, level, this.numerations.agenda_number_format_1_id, this.numerations.agenda_number_format_2_id, this.numerations.agenda_number_format_3_id);
        },
        getTimeEndPoint(item) {
            if (item.is_done) {
                return moment(item.done_at).format("DD-MM-YYYY, HH:mm");
            }
        },
        formatDateToDisplay(value) {
            var date = new Date(value)
            var d = date.toLocaleString('default', {
                year: 'numeric',
                month: 'long',
                weekday: 'long',
                day: 'numeric'
            });

            return d;
        },
        async startInteractiveSession() {
            try {
                await esesjaInteractivePublicService.start();
                await esesjaInteractivePublicService.joinRoom(this.session.id.toString());
            }
            catch {
                var t = this.$swal.mixin(AlertHelper.errorToastElement());
                t.fire(AlertHelper.errorToastAlert('Błąd otwarcia połączenia live'));
            }
        },
        async endInteractiveSession() {
            try {
                await esesjaInteractivePublicService.stop();
            }
            catch {
                var t = this.$swal.mixin(AlertHelper.errorToastElement());
                t.fire(AlertHelper.errorToastAlert('Błąd zamknięcia połączenia live'));
            }
        },
        registerInteractiveEvents() {
            esesjaInteractivePublicService.events.on(signalrSocketAction.ActiveAgendaPointChange.name, this.ActiveAgendaPointChange);
            esesjaInteractivePublicService.events.on(signalrSocketAction.SetDonePoint.name, this.SetDonePoint);
            esesjaInteractivePublicService.events.on(signalrSocketAction.SessionBreakStatusChange.name, this.SessionBreakStatusChange);
            esesjaInteractivePublicService.events.on(signalrSocketAction.AddTimeToSpeakerForPublic.name, this.AddTimeToSpeakerForPublic);
            esesjaInteractivePublicService.events.on(signalrSocketAction.CurrentAgendaItems.name, this.CurrentAgendaItems);
            esesjaInteractivePublicService.events.on(signalrSocketAction.UserStartSpeech.name, this.UserStartSpeech);
            esesjaInteractivePublicService.events.on(signalrSocketAction.UserEndSpeech.name, this.UserEndSpeech);
            esesjaInteractivePublicService.events.on(signalrSocketAction.QuorumStarted.name, this.QuorumStarted);
            esesjaInteractivePublicService.events.on(signalrSocketAction.QuorumEnded.name, this.QuorumEnded);
            esesjaInteractivePublicService.events.on(signalrSocketAction.QuorumResultsChanged.name, this.QuorumResultsChanged);
            esesjaInteractivePublicService.events.on(signalrSocketAction.QuorumSummaryReults.name, this.QuorumSummaryReults);
            esesjaInteractivePublicService.events.on(signalrSocketAction.VotingStarted.name, this.VotingStarted);
            esesjaInteractivePublicService.events.on(signalrSocketAction.VotingEnded.name, this.VotingEnded);
            esesjaInteractivePublicService.events.on(signalrSocketAction.VoteResultSummary.name, this.VoteResultSummary);
            esesjaInteractivePublicService.events.on(signalrSocketAction.SignalRReconnected.name, this.SignalRReconnected);
            esesjaInteractivePublicService.events.on(signalrSocketAction.VoiceRequestListChanged.name, this.VoiceRequestListChanged);
            esesjaInteractivePublicService.events.on(signalrSocketAction.CloseVotingResultModal.name, this.CloseVotingResultModal);
            esesjaInteractivePublicService.events.on(signalrSocketAction.CloseResultCheckQuorumModal.name, this.CloseResultCheckQuorumModal);
            esesjaInteractivePublicService.events.on(signalrSocketAction.OpenAttachment.name, this.OpenAttachment);
            esesjaInteractivePublicService.events.on(signalrSocketAction.ChangeAttachmentName.name, this.ChangeAttachmentPage);
            esesjaInteractivePublicService.events.on(signalrSocketAction.CloseAttachment.name, this.CloseAttachment);
            esesjaInteractivePublicService.events.on(signalrSocketAction.OpenInteractiveVotingPublic.name, this.OpenInteractiveVotingPublic);
            esesjaInteractivePublicService.events.on(signalrSocketAction.ShowHideVotingResultsLive.name, this.ShowHideVotingResultsLive);
            esesjaInteractivePublicService.events.on(signalrSocketAction.ChangePlaceholderStatus.name, this.ChangePlaceholderStatus);
            esesjaInteractivePublicService.events.on(signalrSocketAction.CurrentClientSettings.name, this.SetCurrentClientSettings);
        },
        unregisterInteractiveEvents() {
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.ActiveAgendaPointChange.name, this.ActiveAgendaPointChange);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.SetDonePoint.name, this.SetDonePoint);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.SessionBreakStatusChange.name, this.SessionBreakStatusChange);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.AddTimeToSpeakerForPublic.name, this.AddTimeToSpeakerForPublic);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.CurrentAgendaItems.name, this.CurrentAgendaItems);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.UserStartSpeech.name, this.UserStartSpeech);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.UserEndSpeech.name, this.UserEndSpeech);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.QuorumStarted.name, this.QuorumStarted);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.QuorumResultsChanged.name, this.QuorumResultsChanged);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.QuorumEnded.name, this.QuorumEnded);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.QuorumSummaryReults.name, this.QuorumSummaryReults);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.VotingStarted.name, this.VotingStarted);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.VotingEnded.name, this.VotingEnded);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.VoteResultSummary.name, this.VoteResultSummary);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.SignalRReconnected.name, this.SignalRReconnected);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.VoiceRequestListChanged.name, this.VoiceRequestListChanged);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.CloseVotingResultModal.name, this.CloseVotingResultModal);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.CloseResultCheckQuorumModal.name, this.CloseResultCheckQuorumModal);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.OpenAttachment.name, this.OpenAttachment);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.ChangeAttachmentName.name, this.ChangeAttachmentPage);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.CloseAttachment.name, this.CloseAttachment);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.OpenInteractiveVotingPublic.name, this.OpenInteractiveVotingPublic);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.ShowHideVotingResultsLive.name, this.ShowHideVotingResultsLive);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.ChangePlaceholderStatus.name, this.ChangePlaceholderStatus);
            esesjaInteractivePublicService.events.removeListener(signalrSocketAction.CurrentClientSettings.name, this.SetCurrentClientSettings);
        },
        ChangePlaceholderStatus(data){
            this.$store.commit("setPublicPlaceholderViewModalStatus", data.isActive);
            this.placeholderData = data.placeholder;
        },
        ShowHideVotingResultsLive(data) {
            if (data)
                this.hiddenResults = false;
            else
                this.hiddenResults = true;
        },

        SetDonePoint(points) {
            if (points != null && points.length > 0) {

                for (var i = 0; i < points.length; i++) {
                    this.session.agendaItems.forEach((el) => {
                        if (el.id == points[i]) {
                            el.is_done = true;
                            el.done_at = new Date();
                        }
                        el.children.forEach((e) => {
                            if (e.id == points[i]) {
                                e.is_done = true;
                                e.done_at = new Date();
                            }
                            e.children.forEach((c) => {
                                if (c.id == points[i]) {
                                    c.is_done = true;
                                    c.done_at = new Date();
                                }
                            });
                        });
                    });
                }
            }
        },

        ActiveAgendaPointChange(data) {
            this.changeActivePoint(data);

            var elem = document.getElementById(`id-point-${data.point}`)
            elem.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });

        },

        changeActivePoint(data) {
            if (data != null && data.point != null && data.point > 0) {
                if (data.status == 0) {
                    // active
                    this.session.agendaItems.forEach((el) => {
                        el.id == data.point ? el.is_active = true : el.is_active = false;
                        el.children.forEach((e) => {
                            e.id == data.point ? e.is_active = true : e.is_active = false;
                            e.children.forEach((c) => {
                                c.id == data.point ? c.is_active = true : c.is_active = false;
                            });
                        });
                    });

                } else {
                    // not active
                    this.session.agendaItems.forEach((el) => {
                        el.is_active = false;
                        el.children.forEach((e) => {
                            e.is_active = false;
                            e.children.forEach((c) => {
                                c.is_active = false;
                            });
                        });
                    });
                }
            }
            else {
                this.session.agendaItems.forEach((el) => {
                    el.is_active = false;
                    el.children.forEach((e) => {
                        e.is_active = false;
                        e.children.forEach((c) => {
                            c.is_active = false;
                        });
                    });
                });
            }
        },

        SessionBreakStatusChange(data) {
            if (data.state == 0) {
                this.elemObj = data;
                var time = new Date(data.startTime)
                var breakTime = moment(time).add(data.duration, 'm').toDate();
                this.elemToDispay = moment(breakTime).format("LTS");
                this.infoCase = 3;
                this.showInfoModal = true;
            } else {
                this.infoCase = 0;
                this.showInfoModal = false;
            }
        },
        AddTimeToSpeakerForPublic(data) {
            if (data != null && this.showSpeekerModal && this.speeker != null) {
                if (this.speeker.userId == data.userId && this.speeker.discussionId == data.discussionId) {
                    this.speeker.seconds = data.time;
                }  
            }
        },
        CurrentAgendaItems() {
            PublicService.getPublicSession(this.code).then((response) => {
                this.session = response.data.session;
                this.session.agendaItems = PointsNumerationHelper.setOrderToDisplayRecursive([...this.session.agendaItems]);
                if (response.data.activePoint != null) {
                    var data = { status: 0, point: response.data.activePoint };
                    this.changeActivePoint(data);

                    var elem = document.getElementById(`id-point-${response.data.activePoint}`)
                    elem.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });

                }
                else {
                    var data2 = { status: 1, point: 0  };
                    this.changeActivePoint(data2);
                }
            });
        },
        UserStartSpeech(data) {
            if (data.userId > 0) {
                this.speeker = data;
                this.showSpeekerModal = true;
            }
        },
        UserEndSpeech() {
            this.showSpeekerModal = false;
            this.speeker = {};
        },
        QuorumStarted() {
            this.showQuorumResultModal = true;
            this.quorumIsRunning = true;
        },
        QuorumEnded() {
            this.showQuorumResultModal = false;
            this.quorumIsRunning = false;
        },
        QuorumResultsChanged(data) {
            if (this.quorumIsRunning == true) {
                this.quorumresult = data;
            }
        },
        async SignalRReconnected() {
            await esesjaInteractivePublicService.joinRoom(this.session.id.toString());
        },
        QuorumSummaryReults(data) {
            this.showInfoModal = false;
            this.quorumresult = data;
            this.showQuorumResultModal = true;
        },
        VotingStarted(data) {
            this.infoCase = 2;
            this.elemToDispay = data.name;
        },
        VotingEnded(data) {
            this.infoCase = 0;
            this.elemToDispay = data;
            this.showInfoModal = false;
            this.$store.commit('setInteractiveVotingModalStatus', false);
            this.dataForVoting = {};
            this.tempPossibleAnswersNotVisible = [];
            this.votingPossibleAnswers = [];
        },
        async OpenInteractiveVotingPublic(token) {
            try {
                this.CloseVotingResultModal();
                this.$store.commit("showLoader");
                
                let response = await PublicService.getDataForVotingPublic(token);

                if (response.status === 200) {
                    //votingData for modal
                    this.dataForVoting = response.data.basicData;
                    this.votingPossibleAnswers = response.data.options.filter(x => x.option !== "NIEOBECNI" && x.option !== "NIEOBECNY" && x.option !== "BRAK GŁOSU");
                    this.$store.commit('setInteractiveVotingModalStatus', true);
                }
            }
            catch (e) {
                console.log(e);
            }
            finally {
                this.$store.commit("hideLoader");
            } 
        },
        VoiceRequestListChanged(data) {
            if (data != null && data.length > 0)
                this.discussionList = data;
            else
                this.discussionList = [];
        },
        VoteResultSummary(data) {
            this.showInfoModal = false;
            this.resultsVotingData = data.u;
            this.res = data.s;

            this.chartData = {
                labels: [],
                datasets: [{ data: [] }]
            };

            var tempdata = [];
            if (this.res != null) {
                this.chartData.datasets[0].backgroundColor = [];
                for (var i = 0; i < this.res.options.length; i++) {
                    this.chartData.labels.push(this.res.options[i].option);
                    tempdata.push(this.res.options[i].count);
                    this.chartData.datasets[0].data = tempdata;
                    //this.chartData.datasets[0].backgroundColor = 'rgba(37, 133, 153, 0.8)';
                    //this.chartData.datasets[0].label = 'Wyniki głosowania';
                    this.chartData.datasets[0].backgroundColor.push(this.formatAnswersColor(this.res.options[i].option));
                }
            }

            this.resultsVotingVisible = true;
        },
        formatAnswersColor(result) {
            if (result != null && result.length > 0) {
                switch (result) {
                    case 'ZA':
                    case 'TAK':
                        return '#79e563';
                    case 'PRZECIW':
                    case 'NIE':
                        return '#e40030';
                    case 'WSTRZYMUJĘ SIĘ':
                    case 'NIE WIEM':
                        return '#ffce56';
                    case 'BRAK GŁOSU':
                        return '#c0c0c0';
                    case 'NIEOBECNI':
                    case 'NIEOBECNY':
                        return '#000';
                    case 'WYKLUCZENI':
                    case 'WYŁĄCZENI':
                        return '#363532';
                    default:
                        return '#7cd1eb';
                }
            }
        },
        CloseVotingResultModal() {
            this.resultsVotingData = {};
            this.resultsVotingVisible = false;
            this.chartData = {
                labels: [],
                datasets: [{ data: [] }]
            };
        },
        CloseResultCheckQuorumModal() {
            this.quorumresult = [];
            this.showQuorumResultModal = false;
        },

        async OpenAttachment(data) {
            try {
                this.$store.commit("showLoader");

                let response = await PublicService.getFile(data.ssoToken);

                if (response.status === 200) {
                    this.attachmentData.page = 1;
                    this.attachmentData.fileData = response;
                    this.showAttachmentViewer = true;
                }
                
            }
            catch (e) {
                console.log(e);
            }
            finally {
                this.$store.commit("hideLoader");
            }
        },

        ChangeAttachmentPage(data) {
            this.attachmentData.page = data.page;
            this.attachmentData.scale = data.scale;
        },

        CloseAttachment() {
            this.showAttachmentViewer = false;
            this.attachmentData.fileData = {};
            this.attachmentData.page = 1;
        },
        SetCurrentClientSettings(settings){
            this.$store.state.settings.client = settings;
        }
    }
};
</script>

<style lang="scss" scoped>

    @import "../assets/scss/breakpoints.scss";

    .referer {
        font-weight: normal;
        font-size: 18px;
        color: #333956;
        padding-top: 15px;
    }

    .point-is-done {
        font-size: 14px;
        height: 30px;
        float: right;
        min-width: 180px;
        text-align: right;

        &__img {
            margin-right: 5px;
            vertical-align: middle;
        }
    }

    .header {
        width: 100%;
        box-sizing: border-box;
        padding: 26px 45px 23px 49px;
        //display: flex;
        justify-content: space-between;
        align-items: center;
        background: radial-gradient( 235.38% 418.45% at 0% 0%, #00082c 0%, #004dcc 100% );
        position: sticky;
        top: 0px;
        z-index: 6;

        &::after {
            content: "";
            position: absolute;
            background: #dde0e7;
            width: 100%;
            height: 1px;
            bottom: -1px;
            left: 0px;
        }
    }

    .header-left {
        display: flex;
        align-items: center;

        &__icon {
            margin-right: 11px;
        }

        &__text {
            font-size: 16px;
            line-height: 144%;
            color: #333956;

            &--bold {
                font-weight: 600;
            }

            &--link {
                color: #d80418;
                text-decoration: underline;
                font-weight: 600;
            }
        }

        @media only screen and (max-width:1850px) {
            width: 100%;
        }
    }

    .header-right {
        display: flex;
        align-items: center;

        &__text {
            font-weight: 600;
            font-size: 24px;
            color: white;
            margin-left: auto;
            margin-right: 0;
        }
    }

    .logo {
        width: 80px;
        margin-left: auto;
    }

    .container {
        margin: 49px 48px;

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 20px;
        }
    }

    .sesion-container-wrapper {
        display: flex;
        flex-direction: row;
    }

    .session-agenda {
        flex-grow: 1;
    }

    .disscusion-list {
        min-width: 300px;
        padding-left: 15px;
    }

    .box {
        background: #ffffff;
        width: 100%;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        margin-bottom: 12px;
        transition: all 0.2s ease-in-out;

        &__title {
            font-size: 1.7em;
            font-weight: 500;
            margin-right: 16px;
            //font-size: 16px;
            line-height: 132%;
            color: #00082c;
            overflow-wrap: break-word;
            word-break: break-word;
        }

        &__top {
            //display: flex;
            align-items: center;
            padding: 12px;
            justify-content: space-between;
            font-size: 16px;
        }

        &__active {
            align-items: center;
            padding: 29px;
            justify-content: space-between;
            border: 1px solid green;
            background: #f7fff7;
            border-radius: 12px;
            font-size: 24px;
        }

        &--takenoff {
            text-decoration: line-through !important;
        }
    }

    .box-children {
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        margin: 12px 12px 0px 32px;
        transition: all 0.2s ease-in-out;

        &__titlechildren {
            margin-left: 32px;
            line-height: 132%;
            color: #00082c;
            text-align: justify;
            word-break: break-word;
            white-space: normal;
            font-size: 1.6em;
            font-weight: 500;
        }
    }

    .session {
        &__top-left {
            display: flex;
            background-color: #e8ecf2;
            border-radius: 10px;
            margin: 0px 0px 20px 0px;
            text-align: center;
        }

        &__top-box {
            display: flex;
            align-items: center;
            padding: 14px 15px;
            position: relative;
            column-gap: 11px;

            &:nth-child(1)::after,
            &:nth-child(2)::after,
            &:nth-child(3)::after {
                content: "";
                position: absolute;
                right: 0px;
                width: 1px;
                height: 24px;
                background-color: #d1d6df;
            }

            &:nth-child(4) {
                .session__top-text{
                    text-align: left;
                    text-wrap: wrap;
                }
            }
        }

        &__top-text {
            white-space: wrap;
            text-wrap: nowrap;
        }
    }

</style>
