<template>
    <section class="attachment-viewer" @keyup="keyPressed" @wheel="wheeled" ref="attachInternaModal" tabindex="0">
        <section class="attachment-viewer--body--prev">
            <button class="attachment-viewer--button attachment-viewer--button--small" v-if="fileType.isPfd"
                @click="prevPage" :disabled="isBusy">
                <img src="@/assets/icons/panel/editSession/arrow-left.svg" />
            </button>
        </section>
        <section class="attachment-viewer--body--content">
            <section class="scale-buttons" v-if="fileType.isPfd" ref="scaleButton">
                <section style="display: flex; align-items: baseline;">
                    <button class="attachment-viewer--button attachment-viewer--button--small" @click="scaleUp"
                        :disabled="isBusy" style="margin-right: 2px;">+</button>
                    {{ scale }}%
                    <button class="attachment-viewer--button attachment-viewer--button--small" @click="scaleDown"
                        :disabled="isBusy">-</button>
                </section>
                <section style="display: flex;">
                    <input type="range" v-model="range" @change="rangeChange" min=10 max=250 style="width: 400px" />
                </section>
            </section>
            <section class="attachment-viewer--body--content--interactive" ref="containerAttachment">
                <VuePDF :id="pdfFIle" :pdf="fileBlob" :page="page" :scale="(scale / 100)" v-if="fileType.isPfd"
                    intent="display" @loaded="pdfLoaded" ref="pdfAttachment">
                </VuePDF>

                <img :src="imageData" v-else-if="fileType.isImage" ref="imageAttachment"
                    style="max-width: 95vw; max-height: 95vh" />

                <textarea v-else-if="fileType.isText" v-model="textData" style="height:80vh; width: 95vw;"
                    ref="textAttachment">
                </textarea>
            </section>
            <section class="attachment-viewer--footer">
                <span v-if="fileType.isPfd">
                    Strona {{ page }} z {{ maxPages }}
                </span>
            </section>
        </section>
        <section class="attachment-viewer--body--next">
            <button class="attachment-viewer--button attachment-viewer--button--small" v-if="fileType.isPfd"
                @click="nextPage" :disabled="isBusy">
                <img src="@/assets/icons/panel/editSession/arrow-left.svg" style="transform: rotate(180deg);" />
            </button>
        </section>
    </section>
</template>
<script charset="utf-8">
import { VuePDF, usePDF } from '@tato30/vue-pdf'
import attachmentsService from '../../../../services/attachments.service';
    import userService from "../../../../services/user.service";
    import eSesjaInteractiveService from "../../../../services/esesja.interactive.service";

export default {
    name: "AttachmentViewer",
    components: {
        VuePDF,
    },
    data() {
        return {
            viewer: {
                attachmentId: -1,
                sessionId: -1,
                pointId: -1,
                isInteractive: false,
            },
            fileType: {
                isPfd: false,
                isImage: false,
                isText: false,
            },
            range: 100,
            scaleStep: 10,
            page: 1,
            maxPages: 1,
            scale: 100,
            isFromRange: false,
            fileBlob: undefined,
            imageData: undefined,
            textData: undefined,
            lastPdfPageSize: {
                height: -1,
                width: -1
            },
            isBusy: false,
            isFirstRun: true,
            currentWindowSize: {
                width: -1,
                height: -1,
            }
        }
    },
    watch:{
        scale:{
            handler(val){
                this.range = val
            },
            immediate: true
        }
    },
    computed: {
        attachmentId() {
            return parseInt(this.$route.params.attachmentId);
        }
    },
    async mounted() {
        try {
            this.getCurrentSize();
            //window.addEventListener("resize", this.windowResize);
            this.parseRequest();
            await this.internalFetchAttachment();
            this.notifyParentWindow();
            this.$refs.attachInternaModal.focus();
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.$store.commit("hideLoader");
        }
    },
    unmounted() {
        //window.removeEventListener("resize", this.windowResize);
    },
    methods: {
        parseRequest() {
            this.viewer.attachmentId = this.attachmentId;
            this.viewer.sessionId = parseInt(this.$route.query.sessionId);
            this.viewer.pointId = parseInt(this.$route.query.pointId);
            this.viewer.isInteractive = (this.$route.query.isInteractive.toLowerCase() === "true");
        },
        async internalConnectToInteractive() {
            if (this.viewer.isInteractive === false) {
                return;
            }

            let responseRid = await userService.getCurrentRid();
            if (responseRid.status !== 200) {
                throw await Promise.reject(responseRid);
            }

            await eSesjaInteractiveService.start(responseRid.data);
            await eSesjaInteractiveService.joinRoom(this.viewer.sessionId);
        },
        async notifyParentWindow() {
            try {
                if (this.fileType.isImage) {
                    const img = new Image();
                    img.src = this.imageData;
                    img.onload = () => {
                        this.sentDataToParent({ width: img.width, height: img.height });
                    }
                }
                else if (this.fileType.isText) {
                    this.sentDataToParent({ width: window.parent.innerWidth * 0.45, height: window.parent.innerHeight * 0.80 });
                }

            }
            catch (e) {
                console.log(e);
            }
        },
        async sentDataToParent(data) {
            if (window.parent !== undefined && window.parent !== null) {
                window.parent.postMessage(data);
            }
        },
        async internalFetchAttachment() {
            let response = await attachmentsService.downloadAttachment(this.viewer.pointId, this.viewer.attachmentId);
            this.resolveAttachmentType(response);
            await this.resolveFile(response);

        },
        resolveAttachmentType(response) {
            if (response.headers['content-type'] === 'application/pdf') {
                this.fileType.isPfd = true;
                this.fileType.isImage = false;
                this.fileType.isText = false;
            }
            else if (response.headers['content-type'].startsWith("image/")) {
                this.fileType.isPfd = false;
                this.fileType.isImage = true;
                this.fileType.isText = false;
            }
            else if (response.headers['content-type'].startsWith("text/plain")) {
                this.fileType.isPfd = false;
                this.fileType.isImage = false;
                this.fileType.isText = true;
            }
        },
        async resolveFile(response) {
            if (this.fileType.isPfd) {
                const { pdf, pages } = await usePDF(response.data);
                this.fileBlob = pdf;
                this.maxPages = pages;
            }
            else if (this.fileType.isImage) {
                let blob = new Blob([response.data], { type: response.headers['content-type'] });
                let urlCreator = window.URL || window.webkitURL;
                this.imageData = await urlCreator.createObjectURL(blob);
            }
            else if (this.fileType.isText) {
                let blob = new Blob([response.data], { type: response.headers['content-type'] });
                this.textData = await blob.text()
            }
        },
        getCurrentSize() {
            this.currentWindowSize.width = window.parent.innerWidth * 0.85;
            this.currentWindowSize.height = window.parent.innerHeight * 0.85;
        },
        windowResize(e) {
            this.currentWindowSize.width = e.target.parent.innerWidth * 0.85;
            this.currentWindowSize.height = e.target.parent.innerHeight * 0.85;
            if (this.fileType.isPfd) {
                this.scale = 100;
                this.isFirstRun = true;
            }
        },
        reScalePdf() {
            let scaleButton = this.$refs.scaleButton;
            let scaleButtonHeight = 0;
            if (scaleButton !== undefined) {
                scaleButtonHeight = scaleButton.clientHeight;
            }

            let maxHeight = this.currentWindowSize.height - scaleButtonHeight - 30;

            this.scale = Math.round((maxHeight / this.lastPdfPageSize.height) * 100)
        },
        pdfLoaded(e) {
            this.isBusy = false;
            this.lastPdfPageSize = e;
            if (this.isFirstRun) {

                this.reScalePdf();
                this.isFirstRun = false;
            }
            if (this.isFromRange === false || this.isFirstRun) {
                this.sentDataToParent({ width: (parseInt(e.width + 150)), height: (parseInt(e.height + 100)), force: false })
            }
            this.isFirstRun = false;
            this.isFromRange = false;
        },
        prevPage() {
            if (this.isBusy === true) {
                return;
            }

            if (this.page <= 1) {
                return;
            }
            this.scale = 100;
            this.isBusy = true;
            this.isFirstRun = true;
            this.page--;

            this.sentDataToParent({ width: undefined, height: undefined, prevPage: true, nextPage: undefined, page: this.page, scale: this.scale });
        },

        nextPage() {
            if (this.isBusy === true) {
                return;
            }

            if (this.page >= this.maxPages) {
                return;
            }

            this.scale = 100;
            this.isBusy = true;
            this.isFirstRun = true;
            this.page++;

            this.sentDataToParent({ width: undefined, height: undefined, prevPage: undefined, nextPage: true, page: this.page, scale: this.scale });
        },

        scaleUp() {
            if (this.isBusy === true) {
                return;
            }
            this.isBusy = true;
            this.isFromRange = true;
            this.scale += this.scaleStep;
            if(this.scale >= 250){
                this.scale = 250;
                this.isBusy = false;
            }

            let internalScale = this.scale / 100;
            this.sentDataToParent({ width: (parseInt(this.lastPdfPageSize.width * internalScale + 150)), height: (parseInt(this.lastPdfPageSize.height + 100)), force: true })
        },

        scaleDown() {
            if (this.isBusy === true) {
                return;
            }

            this.isBusy = true;
            this.isFromRange = true;
            this.scale -= this.scaleStep;
            if (this.scale <= 10) {
                this.scale = 10;
                this.isBusy = false;
            }

            let internalScale = this.scale / 100;
            this.sentDataToParent({ width: (parseInt(this.lastPdfPageSize.width * internalScale + 150)), height: (parseInt(this.lastPdfPageSize.height + 100)), force: true })
        },
        keyPressed(e) {

            if (!e.isTrusted) {
                return;
            }

            if (e.key === undefined) {
                return;
            }

            if (e.keyCode === undefined) {
                return;
            }

            if (this.isBusy === true) {
                return;
            }

            e.preventDefault();

            switch (e.keyCode) {
                case 33: //page up
                case 37: this.prevPage(); break; //arrow left;
                case 34: //page down
                case 39: this.nextPage(); break; //arrow right
                case 36: this.page = 1; this.isBusy = true; break; // home
                case 27: this.closeModal(); // escape
            }
        },

        wheeled(e) {
            if (!this.fileType.isPfd) {
                return;
            }

            if (this.isBusy === true) {
                return;
            }

            if (e.deltaY > 0) {
                this.nextPage();
            }
            else {
                this.prevPage();
            }
        },
        rangeChange(e) {
            this.isFromRange = true;
            this.scale = parseInt(e.target.value);
            let internalScale = this.scale / 100;

            this.sentDataToParent({ width: (parseInt(this.lastPdfPageSize.width * internalScale + 150)), height: (parseInt(this.lastPdfPageSize.height + 100)), force: true })
        }
    }
}
</script>
<style lang="scss">
.attachment-viewer {
    background: transparent;
    // height: 100vh;
    // width: 100vw;
    display: flex;
    flex-direction: row;
    column-gap: 10px;

    &--body {
        background: transparent;

        &--content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .scale-buttons {
                padding-top: 10px;
                z-index: 999;
                display: flex;
                align-items: center;
                justify-items: center;
                justify-content: center;
                flex-direction: column;
            }

            &--interactive {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                // width: calc(100vw - 100px);
                // height: calc(100vh - 50px);
            }

        }

        &--prev,
        &--next {
            z-index: 999;
            background: transparent;
            display: flex;
            align-items: center;
        }

        &--next {
            margin-right: 10px;
        }
    }

    &--footer{
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }
}
</style>