<template>
    <section class="contacts">
        <section class="contacts__head">
            <h1 class="contacts__head__title">{{registerName}}</h1>
        </section>
        <section class="contact-top">
            <section class="contact-top__search">
                <form class="contact-top__form">
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Wyszukaj uchwałę'" />
                        <SearchInputTextComponent v-model="internalRequestQuery.searchText"
                                                  :width="'250'"
                                                  :placeholder="'Wyszukaj uchwałę...'" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Wybierz rejestr'" />
                        <SearchSelectComponent v-model="groups.selected"
                                               :options="groups.available"
                                               :disabled="groups.disabled"
                                               :placeholder="'Wyszukaj rejestr...'" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data obowiązywania od'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateFrom" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data obowiązywania do'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateTo" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Posiedzenie'" />
                        <SearchSelectComponent v-model="session.selected"
                                               :options="session.available"
                                               :disabled="session.disabled"
                                               :placeholder="'Wybierz posiedzenie...'" />
                    </section>
                </form>
            </section>
        </section>
        <Pagination :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />
        <section class="contacts__list" v-if="anno !== undefined && anno.length > 0">
            <section class="contact-list">
                <section v-for="s in anno" :key="s.id">
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section class="d-flex flex-row align-items-center">
                                <span class="contact-box__title"><b>Sesja {{numberConversion(s[0].session.number) }}, z dnia {{formatDateToDisplay(s[0].session.meeting_date)}}</b></span>
                                <ButtonOnlyWithIcon v-if="s[0].session.groups.length > 0" icon="share-from-square" v-tooltip="'Przejdź do posiedzenia'" @click="goToSession(s)" />
                            </section>
                        </section>
                        <section style="padding: 10px" class="contact-box__people contact-box__people--open">
                            <section v-for="point in s" :key="point.id">
                                <p class="point-name" ><b>{{ point.pointName }}</b></p>
                                <section v-if="point.pointName.length > 0">
                                    <section class="contact-box__people-container">
                                        <div v-if="point.registry.length > 0">
                                            <section>
                                                <Attachemnt v-for="(attachment, index) in point.registry"
                                                            :key="index"
                                                            :attachment="attachment"
                                                            :elemID="attachment.agenda_item_id"
                                                            :schema="registerSchema.find(x => x.id == groups.selected.value)"
                                                            :sessionNumber="numberConversion(point.session.number)"
                                                            @showRegisterEdit="editRegisterEvent" />
                                            </section>
                                        </div>
                                        <div class="attachement-files" v-if="point.rest.length > 0">
                                            <b>Załączniki: </b>
                                            <section>
                                                <Attachemnt v-for="(attachment, index) in point.rest"
                                                            :key="index"
                                                            :attachment="attachment"
                                                            :elemID="attachment.agenda_item_id"
                                                            :schema="registerSchema.find(x => x.id == groups.selected.value)"
                                                            :sessionNumber="numberConversion(point.session.number)"
                                                            @showRegisterEdit="editRegisterEvent" />
                                            </section>
                                        </div>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>

            </section>
        </section>
        <section class="contacts__list" v-else>
            <section class="contact-list">
                <section>
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section>
                                <span class="contact-box__title">Brak danych spełniających podane kryteria</span>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <Pagination :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />

        <Transition>
            <EditRegister v-if="showRegisterEditModal"
                @closeRegisterModal="closeRegisterEditModal"
                :registerElement="registerElement"
                :registerDocName="registerDocName"
                :tempPidId="tempPidId"
                @updateData="updateRegisterElem" />
        </Transition>
    </section>
</template>

<script charset="utf-8">
    //services
    import RegisterService from "@/services/register.service";
    import ContactService from "@/services/contact.service";
    import CommonHelper from "@/helpers/common.helper";
    import {
        switchToRoman,
        switchIndexToLetter,
    } from "@/helpers/index.conversion";

    //components
    import SearchLabelComponent from "@/components/BasicControls/SearchLabelComponent.vue";
    import SearchInputTextComponent from "@/components/BasicControls/SearchInputTextComponent.vue";
    import SearchSelectComponent from "@/components/BasicControls/SearchSelectComponent.vue";
    import SearchInputDateComponent from "@/components/BasicControls/SearchInputDateComponent.vue";
    import Pagination from "@/components/BasicControls/Pagination.vue";
    import Attachemnt from "./Attachment.vue";
    import EditRegister from "./EditRegister.vue";
    import ButtonOnlyWithIcon from "../../../../BasicControls/ButtonOnlyWithIcon.vue";

    export default {
        name: "rejestr",
        components: {
            Attachemnt,
            EditRegister,
            SearchLabelComponent,
            SearchInputTextComponent,
            SearchSelectComponent,
            SearchInputDateComponent,
            Pagination,
            ButtonOnlyWithIcon
        },
        data() {
            return {
                registerName: "Rejestr",
                searchBar: "",
                anno: [],
                selected: '',
                showRegisterEditModal: false,
                registerElement: {},
                registerDocName: '',
                tempGroupId: 0,
                //registerName: '',
                tempPidId: 0,

                paginatedData: {
                    currentPage: -1,
                    itemsPerPage: 20,
                    totalItems: -1,
                },

                internalRequestQuery: {
                    searchText: null,
                    searchStatus: null,
                    dateFrom: null,
                    dateTo: null,
                    group: null,
                    session: null,
                    page: 1,
                    itemsPerPage: 20,
                },

                groups: {
                    disabled: false,
                    available: [],
                    selected: {},
                },

                session: {
                    disabled: false,
                    available: [],
                    selected: {},
                },

                registerSchema: [],
            };
        },
        computed: {
            requestQuery() {
                return this.$route.query;
            },
            registerId() {
                let requestParams = this.$route.params;
                if (CommonHelper.isObjectHasProperty(requestParams, "registerId")) {
                    return parseInt(requestParams.registerId)
                }
                else {
                    return null;
                }
            },
            isAnyModalOpen(){
                return this.showRegisterEditModal
            }
        },
        watch: {

            internalRequestQuery: {
                deep: true,
                handler: function (newValue) {
                    let queryParam = Object.fromEntries(Object.entries(newValue).filter(([v]) => newValue[v] !== null && newValue[v] !== '' ));
                    this.$router.replace({ name: "rejestr", query: queryParam });
                }
            },
            'groups.selected': {
                handler(newValue) {
                    if(newValue === undefined || newValue === null || newValue.label === undefined || newValue.label === '')
                        return;
                    this.internalRequestQuery.group = newValue.value;
                    this.internalRequestQuery.page = 1;
                    this.registerName = newValue.label;
                }

            },

            'session.selected': {
                handler(newValue) {
                    this.internalRequestQuery.page = 1;
                    if (newValue !== undefined && newValue !== null) {
                        this.internalRequestQuery.session = newValue.value;
                    }
                    else {
                        this.internalRequestQuery.session = null;
                    }

                }
            },

            'session.available': {
                handler() {
                    this.requestSearchToSelectedSession();
                }
            },
            $route: {
                immediate: true,
                handler: function(val){
                    if (val.name === "rejestr" && Object.keys(val.query).length > 0 && val.query.page !== undefined) {
                        this.queryParamsToRequestSearch();
                        this.requestSearchToSelecteGroups();
                        this.fetchRegisters();
                        this.requestSearchToSelectedSession();
                    }

                }
                
            },

            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },
        async mounted() {
            await this.fetchGroups();
            this.queryParamsToRequestSearch();
            this.requestSearchToSelecteGroups();
            this.requestSearchToSelectedSession();
            this.registerName = this.groups.selected.label;
        },
        methods: {
            numberConversion(index) {
                if (index == null)
                    return "";

                var format = 0;
                var z = this.registerSchema.find(x => x.id == this.groups.selected.value);
                if (z == undefined || z == null)
                    format = 5;
                else
                    format = z.session_number_format_id;

                switch (format) {
                    case 1:
                        return `${switchToRoman(index)}`;
                    case 2:
                        return `${index}.`;
                    case 3:
                        return `${index})`;
                    case 4:
                        return `${switchIndexToLetter(index)})`;
                    default:
                        return `${index}.`;
                }
            },

            async fetchGroups() {
                try {
                    this.groups.disabled = true;
                    let internalGroups = this.$store.state.appStore.groups;
                    if (internalGroups.length === 0) {
                        let response = await ContactService.index(false);
                        if (response.status !== 200) {
                            throw await Promise.reject(response)
                        }
                        internalGroups = response.data;
                    }

                    this.groups.available = internalGroups
                        .filter(item => {
                            return item.can_registry
                        })
                        .map(item => {
                            return {
                                value: parseInt(item.id),
                                label: item.registry_name
                            }
                        });

                    this.registerSchema = internalGroups
                        .filter(item => {
                            return item.can_registry
                        })
                        .map(item => {
                            return {
                                id: parseInt(item.id),
                                regName: item.registry_name,
                                schema: item.resolution_number_format_id,
                                docName: item.registry_document_name,
                                session_number_format_id: item.session_number_format_id
                            }
                        });

                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.groups.disabled = false;
                }
            },
            async fetchRegisters() {
                try {
                    this.$store.commit("showLoader");
                    let requestData = this.internalRequestDataToAsyncRequestData();
                    let response = await RegisterService.searchRegister(requestData);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.paginatedData = response.data.paginated;

                    this.session.available = response.data.availableSessions.map(
                        item => {
                            return {
                                value: item.id,
                                label: "Posiedzenie " + item.number + ", z dnia " + this.formatDateToDisplay(item.meeting_date)

                            }
                        }
                    );

                    if (response.data.items != null && response.data.items.length > 0) {
                        var anno = response.data.items;
                        var temp_ses_id_array = [];
                        for (var p = 0; p < anno.length; p++) {
                            anno[p].registry = anno[p].attachments.filter(x => x.type_id == 1);
                            anno[p].rest = anno[p].attachments.filter(x => x.type_id != 1);
                            anno[p].sessionId = anno[p].session.id;

                            if (temp_ses_id_array.includes(anno[p].session.id) == false) {
                                temp_ses_id_array.push(anno[p].session.id);
                            }
                        }

                        var main_array = [];

                        for (var q = 0; q < temp_ses_id_array.length; q++) {
                            main_array.push(anno.filter(x => x.sessionId == temp_ses_id_array[q]));
                        }

                        this.anno = main_array;
                    }
                    else {
                        this.anno = [];
                    }
                    
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            async goToSession(s){
                if(s[0] === undefined || s[0] == null)
                    return

                if(s[0].session.groups.length === 0)
                    return 
                
                await this.$router.push({name: 'sessionEdit', params:{sessionid: s[0].session.id, groupid: s[0].session.groups[0].id }})
            },

            requestSearchToSelecteGroups() {
                if (this.internalRequestQuery.group !== null) {
                    let selectedModels = this.groups.available
                        .filter(item => {
                            return item.value === this.internalRequestQuery.group
                        });

                    this.groups.selected = selectedModels[0];

                }
            },
            requestSearchToSelectedSession() {
                if (this.internalRequestQuery.session !== null) {
                    let selectedModels = this.session.available
                        .filter(item => {
                            return item.value === this.internalRequestQuery.session
                        });

                    this.session.selected = selectedModels[0];
                }
            },

            queryParamsToRequestSearch() {
                if (CommonHelper.isObjectHasProperty(this.requestQuery, "group")) {
                    this.internalRequestQuery.group = parseInt(this.requestQuery.group);
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "searchText")) {
                    this.internalRequestQuery.searchText = this.requestQuery.searchText;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateFrom")) {
                    this.internalRequestQuery.dateFrom = this.requestQuery.dateFrom;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateTo")) {
                    this.internalRequestQuery.dateTo = this.requestQuery.dateTo;
                }
                if (CommonHelper.isObjectHasProperty(this.requestQuery, "session")) {
                    this.internalRequestQuery.session = parseInt(this.requestQuery.session);
                }
                
                if (CommonHelper.isObjectHasProperty(this.requestQuery, "_r")) {
                    this.clearRequestData();
                }
            },

            internalRequestDataToAsyncRequestData() {
                return {
                    data: {
                        searchText: this.internalRequestQuery.searchText,
                        searchStatus: this.internalRequestQuery.searchStatu,
                        dateFrom: this.internalRequestQuery.dateFrom,
                        dateTo: this.internalRequestQuery.dateTo,
                        group: this.internalRequestQuery.group,
                        session: this.internalRequestQuery.session
                    },
                    paginated: {
                        page: this.internalRequestQuery.page,
                        itemsPerPage: this.internalRequestQuery.itemsPerPage,
                    }
                }
            },

            changePage(page) {
                this.internalRequestQuery.page = page;
            },

            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric'
                });
                return d;
            },
            async editRegisterEvent(item, pid) {
                try {
                    var result = (await RegisterService.getRegister(item.id)).data;
                    if (Object.keys(result).length > 0) {

                        this.tempPidId = pid;

                        if (result.registerName != null && result.registerName.length > 0)
                            this.registerDocName = result.registerName;
                        else
                            this.registerDocName = item.name;

                        this.registerElement = {
                            id: item.id,
                            dateFrom: result.from != null ? result.from : '',
                            dateTo: result.to != null ? result.to : '',
                            legalBasis: result.legalBasis != null ? result.legalBasis : '',
                            desc: result.desc != null ? result.desc : '',
                            number: result.number,
                            isAnnounced: result.announced != null ? result.announced : false,
                            announced: result.registerTime != null ? result.registerTime : '',
                            isValid: result.valid != null ? result.valid : false,
                        };

                        this.showRegisterEditModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            closeRegisterEditModal() {
                this.showRegisterEditModal = false;
            },
            async updateRegisterElem() {
                await this.fetchRegisters();
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/search-component.scss";

    .contact-box {

        &__top {
            padding: 16px;
            cursor: default;
        }

        &__people-container {
            padding: 12px;
            margin-left: 30px;
        }
    }

    .point-name {
        font-size: 12px;
        margin-left: 10px;
        padding-top: 12px;
        padding-bottom: 5px;
    }

    .attachement-files {
        margin-top: 10px;
        margin-left: 16px;
    }

</style>
