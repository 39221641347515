<template>
    <section class="voting" :class="{'voting-public': isPublicView }">
        <div class="voting-form">

           
            <section class="voting-form__form-wrapper">
                <section class="voting-form__title">
                    <span v-if="internalBasicData.isCheckPresence == true">
                        Wyniki z dnia {{formatDateToDisplay(internalBasicData.sessionDateTime)}}: <b>{{internalBasicData.name}}</b>
                    </span>
                    <span v-else>
                        Wyniki głosowania <span style="text-decoration:underline">{{internalBasicData.isPublic ? 'jawnego' : 'tajnego'}}</span> z dnia {{formatDateToDisplay(internalBasicData.sessionDateTime)}}: <b>{{internalBasicData.name}}</b>
                    </span>
                    <section class="d-flex flex-row" v-if="!isPublicView">
                        <ButtonWithIcon icon="download" class="download-button"
                                        text=".PDF"
                                        :classModified="true"
                                        @click="getPdfReport()" />

                        <ButtonWithIcon icon="download" class="download-button"
                                        text=".DOC"
                                        :classModified="true"
                                        @click="getDocReport()" />

                        <ButtonOnlyWithIcon v-if="!votingIsRunning && !showResultsInVisualizationWindow && isInteractive()" @click="showResultsVisualization()" class="download-button-eye" icon="eye" v-tooltip.bottom="'Wyświetl wyniki w oknie wizualizacji'" />
                        <ButtonOnlyWithIcon v-if="!votingIsRunning && showResultsInVisualizationWindow && isInteractive()" @click="hideResultsVisualization()" class="download-button-eye" icon="eye2" v-tooltip.bottom="'Ukryj wyniki w oknie wizualizacji'" />

                        <button class="voting-form__exit-btn"
                                v-tooltip="'Zamknij okno'"
                                v-if="!votingIsRunning || canClose"
                                @click="closeModale">
                            <img src="@/assets/icons/components/exit-btn.svg" />
                        </button>
                    </section>

                </section>

                <!-- normal voting result-->
                <div v-if="internalBasicData.gType != 4">
                    <div class="summary">
                        <div :class="[internalBasicData.isPublic ? 'summary__row-double' : '']">
                            <section :class="[internalBasicData.isPublic ? 'summary__col-double' : '']" v-if="$store.state.settings.client.chartType < 3">
                                <Chart :chartData="chartData" :plugins="plugins" :options="options" :height="250" :type="$store.state.settings.client.chartType" />
                            </section>
                            <section class="summary__col-double" :class="{'result-without-chart': $store.state.settings.client.chartType >= 3}">
                                <div class="results">
                                    <div class="upr-res">
                                        <b>PODSUMOWANIE: </b>
                                        <div class="uprawniony" :class="optionToClassName(s.option)" v-for="s in votingPossibleAnswers.options" :value="s.id" :key="s.id">{{s.option}}: <b>{{s.count}}</b></div>
                                    </div>
                                    <div class="msg-result" v-if="votingPossibleAnswers.msg_result != null"><b>{{votingPossibleAnswers.msg_result}}</b></div>
                                </div>
                                <perfect-scrollbar class="scroll-user-answers" >
                                    <section v-if="internalBasicData.isPublic">
                                        <h2 class="msg-result">LISTA IMIENNA</h2>
                                        <div class="available-users">
                                            <div class="user" v-for="(item, key) in [...new Map(votingPossibleAnswers.users.map((item) => [item['userId'], item])).values()]" :key="key" :class="getVoteUserClass(item)">
                                                <div class="user-data">
                                                    <span class="user-name">{{item.firstName}} {{item.lastName}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section v-if="votingPossibleAnswers.excluded_users != null && votingPossibleAnswers.excluded_users.length > 0">
                                        <p class="msg-result-excluted">WYŁĄCZENIA Z GŁOSOWANIA</p>
                                        <div class="available-users" style="justify-content: center;">
                                            <div class="user excluded" v-for="(item, key) in [...new Map(votingPossibleAnswers.excluded_users.map((item) => [item['userId'], item])).values()]" :key="key">
                                                <div class="user-data">
                                                    <span class="user-name">{{item.firstName}} {{item.lastName}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </perfect-scrollbar>
                            </section>
                        </div>
                    </div>
                </div>

                <!-- special voting result-->
                <div v-if="internalBasicData.gType == 4" class="voting-special-wrapper">
                    <div class="summary">
                        <div :class="[internalBasicData.isPublic ? 'summary__row-double' : '']">
                            <section :class="[internalBasicData.isPublic ? 'summary__col-double' : 'summary__col-single']">
                                <div class="upr-res-special">
                                    <b>PODSUMOWANIE: </b>
                                    <div v-for="(s, index) in orderSpeciaVotingSummaryResults(votingPossibleAnswers.options)" class="option_summary" :value="s.id" :key="s.id">
                                        <div class="special_result_option">
                                            <span class="special_result__query">{{index+1}}. {{s.option}}:</span>
                                            <span class="special_result_option__row vote-yes-background">TAK: {{s.for}}</span>
                                            <span class="special_result_option__row vote-no-background">NIE: {{s.against}}</span>
                                            <span class="special_result_option__row vote-abstain-background">WSTRZYMUJĘ SIĘ: {{s.abstain}}</span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="summary__col-double" style="position: sticky; top: 0px;">
                                <perfect-scrollbar class="scroll-user-answers">
                                    <section v-if="internalBasicData.isPublic">
                                        <h2 class="msg-result">LISTA IMIENNA</h2>
                                        <div class="available-users">
                                            <div class="user" v-for="(item, key) in [...new Map(votingPossibleAnswers.users.map((item) => [item['userId'], item])).values()]" :key="key" :class="getVoteUserClass(item)">
                                                <div class="user-data">
                                                    <span class="user-name">{{item.firstName}} {{item.lastName}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section v-if="votingPossibleAnswers.excluded_users != null && votingPossibleAnswers.excluded_users.length > 0">
                                        <p class="msg-result-excluted">WYŁĄCZENIA Z GŁOSOWANIA</p>
                                        <div class="available-users" style="justify-content: center;">
                                            <div class="user excluded" v-for="(item, key) in [...new Map(votingPossibleAnswers.excluded_users.map((item) => [item['userId'], item])).values()]" :key="key">
                                                <div class="user-data">
                                                    <span class="user-name">{{item.firstName}} {{item.lastName}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </perfect-scrollbar>
                            </section>
                        </div>
                    </div>
                </div>

            </section>
        </div>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
    import pdfMake from 'pdfmake';
    import pdfFonts from 'pdfmake/build/vfs_fonts';
    import Chart from '../../../../BasicControls/Charts/Chart.vue';
    import LoaderInside from "../../../../BasicControls/LoaderInside.vue"
    import ButtonWithIcon from "../../../../BasicControls/ButtonWithIcon.vue";
    import ButtonOnlyWithIcon from "../../../../BasicControls/ButtonOnlyWithIcon.vue";
    import VotingService from "../../../../../services/voting.service";
    import SettingsService from "../../../../../services/settings.service";
    export default {
        name: "InteractiveVotingResults",
        data() {
            return {
                plugins: [{
                    options: {
                        legend: {
                            display: false
                        }
                    },
                }],
                options: {
                    legend: {
                        display: false
                    }
                },
                tempdata: [],
                isLoading: false,
                errors: [],
                votingIsRunning: false,
                votingPossibleAnswers: this.votingResults,
                internalBasicData: this.basicData,
                hiddenVoteResult: 0,
                showResultsInVisualizationWindow: this.fromEndVoting,
                //logo: require('@/assets/imgs/esesjalogo.png'),
            };
        },
        components: {
            Chart,
            LoaderInside,
            ButtonWithIcon,
            ButtonOnlyWithIcon
        },
        props: {
            basicData: Object,
            votingResults: Object,
            chartData: Object,
            isPublicView: Boolean,
            canClose: Boolean,
            fromEndVoting: Boolean
        },
        
        methods: {
            orderSpeciaVotingSummaryResults(options) {
                var sortedSpecialResult = [];
                sortedSpecialResult = options.filter(x => x.option !== "BRAK GŁOSU" && x.option !== "NIEOBECNI" && x.option !== "NIEOBECNY").sort((a, b) => (a.for < b.for) ? 1 : ((b.for < a.for) ? -1 : 0));
                
                return sortedSpecialResult;
            },

            optionToClassName(option){
                switch (option) {
                    case 'ZA':
                    case 'TAK':
                        return "vote-for";
                    case 'PRZECIW':
                    case 'NIE':
                        return "vote-no";
                    case 'WSTRZYMUJĘ SIĘ':
                    case 'NIE WIEM':
                        return "vote-abstain";
                    case 'BRAK GŁOSU':
                        return "vote-no-vote";
                    case 'NIEOBECNI':
                    case 'NIEOBECNY':
                        return "vote-absent";
                    case 'WYKLUCZENI':
                    case 'WYŁĄCZENI':
                        return "vote-exclude";
                    default:
                        return "vote-default";

                }
            },

            isInteractive() {
                return this.$store.state.appStore.isInInteractiveMode;
            },
            showResultsVisualization() {
                this.showResultsInVisualizationWindow = true;
                this.$emit('showResultsInVisualization');
            },
            hideResultsVisualization() {
                this.showResultsInVisualizationWindow = false;
                this.$emit('closeResultsInVisualization');
            },
            getBase64ImageFromURL(url) {
                return new Promise((resolve, reject) => {
                    var img = new Image();
                    img.setAttribute("crossOrigin", "anonymous");

                    img.onload = () => {
                        var canvas = document.createElement("canvas");
                        canvas.width = img.width;
                        canvas.height = img.height;

                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);

                        var dataURL = canvas.toDataURL("image/png");

                        resolve(dataURL);
                    };

                    img.onerror = error => {
                        reject(error);
                    };

                    img.src = url;
                });
            },
            countAll() {
                var count = 0;
                if (this.votingPossibleAnswers.options.length > 0) {
                    for (var i = 0; i < this.votingPossibleAnswers.options.length; i++) {

                        count += this.votingPossibleAnswers.options[i].count;
                    }
                }
                return count;
            },
            getSpecialVotingAnswer(elem) {
                switch (elem) {
                    case 1:
                        return "TAK";
                    case 2:
                        return "NIE";
                    case 3:
                        return "WSTRZYMUJĘ SIĘ";
                    default:
                        return "";
                }
            },
            async getClientFooter() {
                try {

                    var footer = await SettingsService.getFooterClientImage();

                    if (footer.status !== 200) {
                        return '';
                    }

                    return footer.data.image;

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async getClientHeaderLogo() {
                try {

                    var header = await SettingsService.getLogoClientImage();

                    if (header.status !== 200) {
                        return '';
                    }

                    return header.data.image;

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async calculateLogo(logo) {

                if (logo.length > 0) {
                    let img = new Image();
                    img.src = logo;
                    await img.decode();

                    return img;
                }
                else {
                    return '';
                }
            },
            async getPdfReport() {

                var summaryResults = '';
                var exclutedList = '';
                var isProperResults = true;
                if (this.votingPossibleAnswers.options.length > 0 && this.countAll() > 0) {
                    if (this.internalBasicData.gType == 4) {
                        for (var i = 0; i < this.votingPossibleAnswers.options.length; i++) {
                            summaryResults += " " + this.votingPossibleAnswers.options[i].option + ": " + this.votingPossibleAnswers.options[i].count + "\n";

                            if (this.votingPossibleAnswers.options[i].option !== "NIEOBECNI" && this.votingPossibleAnswers.options[i].option !== "NIEOBECNY" && this.votingPossibleAnswers.options[i].option !== "BRAK GŁOSU") {
                                summaryResults += this.getSpecialVotingAnswer(1) + ": " + this.votingPossibleAnswers.options[i].for + ", "
                                    + this.getSpecialVotingAnswer(2) + ": " + this.votingPossibleAnswers.options[i].against + ", "
                                    + this.getSpecialVotingAnswer(3) + ": " + this.votingPossibleAnswers.options[i].abstain + "\n\n";
                            }
                            else {
                                summaryResults += "\n"
                            }
                               
                        }
                    }
                    else
                    {
                        for (var m = 0; m < this.votingPossibleAnswers.options.length; m++) {
                            summaryResults += " " + this.votingPossibleAnswers.options[m].option + ": " + this.votingPossibleAnswers.options[m].count + ","
                        }
                    }

                    // excluted_users
                    var ex = this.votingPossibleAnswers.excluded_users;
                    if (ex != null && ex.length > 0) {
                        var excluted = "Wyłączenia z głosowania: " + "\n";

                        for (var r = 0; r < ex.length; r++) {
                            excluted += ex[r].firstName + " " + ex[r].lastName + ", ";
                        }

                        exclutedList = excluted.replace(/, $/, "");
                    }
                }
                else {
                    summaryResults = "Brak wyników głosowania";
                    isProperResults = false;
                }

                var x = summaryResults.replace(/,$/, "");

                var publicVotesTitle = '';
                var publicResults = {};
                if (this.internalBasicData.isPublic && isProperResults) {
                    publicVotesTitle = 'Wyniki imienne:';
                    var text = '';
                    for (var j = 0; j < this.votingPossibleAnswers.options.length; j++) {

                        var users = this.votingPossibleAnswers.users.filter(x => x.responseId == this.votingPossibleAnswers.options[j].id);
                        var usersList = '';
                        for (var k = 0; k < users.length; k++) {
                            var elem = users[k].firstName + " " + users[k].lastName;
                            if (this.internalBasicData.gType == 4 && users[k].response > 0 && users[k].response != 4) {
                                elem += " (" + this.getSpecialVotingAnswer(users[k].option) + ")";
                            }
                            elem += ", ";
                            usersList += elem;
                        }

                        text += this.votingPossibleAnswers.options[j].option + " (" + this.votingPossibleAnswers.options[j].count + ")" + "\n" +
                            usersList.replace(/, $/, "") + "\n\n";
                    }

                    publicResults = {
                        text: text,
                        style: 'normal'
                    };

                }

                var logo = await this.getClientHeaderLogo();
                var date = new Date().toLocaleDateString();
                var docWithLogo = await this.getClientFooter();

                var imageData = await this.calculateLogo(logo);

                const documentDefinition = {
                    content: [
                        {
                            text: 'Wyniki głosowania',
                            style: 'header'
                        },
                        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
                        {
                            text: 'Głosowano w sprawie: ' + this.internalBasicData.name,
                            style: 'subheader'
                        },
                        {
                            text: x,
                            style: 'normal'
                        },
                        {
                            text: exclutedList,
                            style: 'normal'
                        },
                        {
                            text: publicVotesTitle,
                            style: 'normalTitlePublic'
                        },
                        publicResults,
                        {
                            text: "Głosowanie z dnia: " + this.formatDateToDisplayLong(this.internalBasicData.sessionDateTime),
                            style: 'normal2'
                        },
                        
                    ],
                    footer: function (currentPage, pageCount) {
                        if (docWithLogo.length > 0) {
                            return [
                                {
                                    columns:
                                        [
                                            { image: docWithLogo, margin: [30, -50, 0, 0], maxWidth: 250 },
                                            { text: date + " | Wygenerowano za pomocą app.esesja.pl, Strona " + currentPage.toString() + ' z ' + pageCount, alignment: 'right', style: ['quote', 'small'], margin: [0, 20, 50, 0] }
                                        ]
                                }
                            ]
                        }
                        else {
                            return [{ text: date + " | Wygenerowano za pomocą app.esesja.pl, Strona " + currentPage.toString() + ' z ' + pageCount, alignment: 'right', style: ['quote', 'small'], margin: [0, 20, 50, 0] }];
                        }
                    },
                    header: function () {
                        if (logo.length > 0) {

                            var width = 0;
                            if (imageData.width >= imageData.height) {
                                width = 80;
                            }
                            else {
                                width = 60;
                            }

                            return [{ image: logo, alignment: 'right', margin: [0, 20, 20, 0], maxWidth: width }]
                        }
                    },
                    styles: {
                        header: {
                            fontSize: 18,
                            bold: true,
                            marginBottom: 10,
                            marginTop: 40
                        },
                        subheader: {
                            fontSize: 15,
                            bold: true,
                            margin: 10
                        },
                        normal: {
                            fontSize: 10,
                            margin: 10
                        },
                        normal2: {
                            fontSize: 10,
                            margin: [10, 30, 0, 0]
                        },
                        normalTitlePublic: {
                            fontSize: 10,
                            margin: [10, 10, 0, 0],
                            decoration: 'underline'
                        },
                        quote: {
                            italics: true
                        },
                        small: {
                            fontSize: 8
                        }
                    }
                };

                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(documentDefinition).download("wyniki_glosowania_" + this.internalBasicData.votingId +".pdf");
            },
            async getDocReport() {
                try {
                    this.$store.commit("showLoader");

                    var data = {
                        gid: this.internalBasicData.votingId,
                        isPublic: this.internalBasicData.isPublic,
                        mode: 1,
                        gtype: this.internalBasicData.gType,
                        date: this.formatDateToDisplayLong(this.internalBasicData.sessionDateTime),
                        name: this.internalBasicData.name
                    };

                    let result = await VotingService.getVotingSummaryToWordFile(data);
                    if (result.status == 200) {

                        const contentType = result.headers['content-type'];
                        let a = document.createElement('a');
                        var blob = new Blob([result.data], { 'type': contentType });
                        a.href = window.URL.createObjectURL(blob);
                        var filename = result.headers['content-disposition'].split("filename=")[1];

                        filename = filename.substr(0, filename.indexOf(';'));
                        filename = filename.replaceAll('"', '');
                        a.download = filename
                        a.click();
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            getVoteUserClass(item) {
                if (item === null)
                    return "";

                let userVoice = parseInt(item.response);
                switch (userVoice) {
                    case 0: return "vote-lack";
                    case 1: return "vote-for";
                    case 2: return "vote-against";
                    case 3: return "vote-abstain";
                    case 4: return "absent";
                    case 20: return "excluded";
                    case 10:
                    case 99:
                        return "vote-hidden";
                }
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });

                return d;
            },
            formatDateToDisplayLong(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                });

                return d;
            },
            formatAnswersColorText(result) {
                if (result == "NIEOBECNI" || result == "NIEOBECNY" || result == "WYŁĄCZENI" || result == 'PRZECIW' || result == 'NIE')
                    return 'white';
                else
                    return 'black';
            },
            formatAnswersDecorationText(result) {
                if (result == "WYŁĄCZENI")
                    return 'line-through';
                else
                    return 'none';
            },
            formatAnswersColor(result) {
                if (result != null) {
                    switch (result.option) {
                        case 'ZA':
                        case 'TAK':
                            result.color = '#79e563';
                            break;
                        case 'PRZECIW':
                        case 'NIE':
                            result.color = '#e40030';
                            break;
                        case 'WSTRZYMUJĘ SIĘ':
                        case 'NIE WIEM':
                            result.color = '#ffce56';
                            break;
                        case 'BRAK GŁOSU':
                            result.color = '#c0c0c0';
                            break;
                        case 'NIEOBECNI':
                        case 'NIEOBECNY':
                            result.color = '#000';
                            break;
                        case 'WYKLUCZENI':
                        case 'WYŁĄCZENI':
                            result.color = '#363532';
                            break;
                        default:
                            result.color = '#7cd1eb';
                            break;
                    }
                    return result.color;
                }
            },
            async closeModale(e) {
                try {
                    e.preventDefault();
                    this.$emit('closeResultVotingt');
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
        },
    };
</script>

<style lang="scss" scoped>

    .scroll-user-answers{
        max-height: calc(90vh - 300px);
    }
    .special_result_option {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 5px;

        .special_result__query {
            flex-grow: 1;
        }

        &__row {
            padding: 5px;
        }
    }

    .summary {
        &__col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &-double {
                display: flex;
                flex-direction: column;
            }

            &-single {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__row {
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            &-double {
                display: flex;
                justify-content: space-between;
                margin-bottom: 24px;
                align-items: center;
                column-gap: 12px;
            }
        }

        .vote-yes-background {
            background-color: #79e563;
            font-weight: bold;
        }

        .vote-no-background {
            background-color: #e40030;
            font-weight: bold;
        }

        .vote-abstain-background {
            background-color: #ffce56;
            font-weight: bold;
        }
    }

    .upr-res {
        border: solid 1px;
        padding: 10px;
        border-radius: 10px;
        border-color: silver;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: center;
        align-items: center;

        .vote-for {
            background-color: rgb(121, 229, 99);
            color: black;
            text-decoration: none;
        }

        .vote-no {
            background-color: rgb(228, 0, 48);
            color: white;
            text-decoration: none;
        }

        .vote-abstain {
            background-color: rgb(255, 206, 86);
            color: black;
            text-decoration: none;
        }

        .vote-no-vote {
            background-color: rgb(192, 192, 192);
            color: black;
            text-decoration: none;
        }

        .vote-absent {
            background-color: rgb(0, 0, 0);
            color: white;
            text-decoration: none;
        }
    }

    .upr-res-special {
        border: solid 1px;
        //text-align: center;
        //padding: 15px;
        border-radius: 10px;
        border-color: silver;
        //text-align: left;
        width: max-content;
        display: flex;
        flex-direction: column;
/*        row-gap: 15px;*/

        b {
            padding: 15px 15px 10px 15px;
        }

        .option_summary {
            padding: 10px 15px;
        }

        :nth-child(even) {
            background: #e5e5e5;
        }
    }

    .download-button {
        float: right;
        margin: 5px;
        padding: 10px;
        //width: 100px;
        height: 40px;
    }

    .download-button-eye {
        float: right;
        margin: 5px;
        padding: 10px;
        width: 40px;
        height: 40px;
    }

    .msg-result {
        text-align: center;
        padding: 15px;
        font-size: 16px;
    }

    .msg-result-excluted {
        text-align: center;
        padding: 5px;
        margin-top: 10px;
        font-size: 14px;
    }

    .results {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
        justify-content: center;
    }

    .uprawniony {
        display: inline-block;
        padding: 8px;
        font-size: 1.1em;
        font-weight: 250;
    }


    .voting {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 9;
    }

    .voting-form {
        padding: 32px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-height: 100vh;
        max-width: 95vw;
        
        &__form-wrapper {
            max-height: 90vh;
            overflow-y: auto;
            max-width: 100vw;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }

            .available-users {
                display: flex;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 10px;
                //justify-content: center;


                .user {
                    //width: 180px;
                    display: flex;
                    column-gap: 10px;
                    //cursor: pointer;
                    //align-items: center;
                    background-color: #e5e5e5;
                    padding: 5px;
                    //text-align: center;

                    .user-avatar img {
                        width: 50px;
                        vertical-align: middle;
                    }

                    .user-data .user-name {
                        font-size: 1.1em;
                        font-weight: 300;
                    }
                }

                .user.absent {
                    background: #000 !important;
                    color: white;
                }

                .user.excluded {
                    background: #363532 !important;
                    color: white;
                    text-decoration: line-through;
                    font-size: 14px;
                }

                .user.vote-for {
                    background: #79e563 !important;
                }

                .user.vote-against {
                    background: #e40030 !important;
                    color: white !important;
                }

                .user.vote-abstain {
                    background: #ffce56 !important;
                }

                .user.vote-hidden {
                    background: #7cd1eb !important;
                }
            }
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
        }

        &__title {
            align-items: start;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            span {
                font-weight: 400;
                font-size: 17px;
                line-height: 128%;
                color: #00082c;
            }

            &--small {
                font-size: 17px;
                border-bottom: 0px;
                padding-bottom: 16px;
                margin: 0px;
            }

            &--no-padding {
                padding: 0px;
            }
        }
    }

    .voting-special-wrapper
    {
        .summary__row-double{
            align-items: start;
        }
    }
    .voting-public {
        .voting-form {
            max-width: 95vw;
            z-index: 21;
        }

        .upr-res {
            font-size: 1.3em;
        }

        .voting-form__form-wrapper {
            .available-users {
                .user {
                    padding: 10px 10px;

                    .user-data {
                        .user-name {
                            font-size: 1.3em;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .result-without-chart {
        .user {
            width: 180px;
            align-items: center;
            min-height: 64px;
            padding: 5px 10px !important;

            .user-data {
                .user-name {
                    font-size: 1.5em !important;
                    font-weight: 450 !important;
                }
            }
        }
    }
</style>
