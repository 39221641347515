<template>
    <section class="session-metadata-modal">
        <section class="session-metadata-modal__wrapper">
            <section class="session-metadata-modal__wrapper__header">
                <section class="session-metadata-modal__wrapper__header__title">
                    <h2 class="esesja-modal__wraper__header__title">Metryczka posiedzenia</h2>
                </section>
                <section class="session-metadata-modal__wrapper__header__action">
                    <button class="session-metadata-modal__wrapper__header__action__exit-btn"
                            @click="(e)=> { e.preventDefault();this.$store.commit('hideSessionMetadata');}">
                        <img src="../../../../../assets/icons/components/exit-btn.svg" />
                    </button>
                </section>
            </section>
            <section class="session-metadata-modal__wrapper__body">
                <section class="session-metadata-modal__wrapper__body__session-data">
                    <button class="btn-without-text" v-tooltip="'Pobierz dane szczegółowe'" @click="getSessionMetadataToWordClick()">
                        <img src="../../../../../assets/icons/components/download.svg" />
                    </button>
                    <h3>{{sessionMetadata.cityCouncil.name}} - {{getOrganization()}}</h3>

                    <p>
                        {{getMeetingName()}}
                        <span v-if="sessionMetadata.end_meeting_date !== null && sessionMetadata.end_meeting_date !== undefined && sessionMetadata.end_meeting_date.length > 0">
                            w dniach {{ formatDate(sessionMetadata.session.meeting_date,'yyyy-MM-DD')}} - {{ formatDate(sessionMetadata.session.end_meeting_date,'yyyy-MM-DD')}}
                        </span>
                        <span v-else>
                            w dniu {{ formatDate(sessionMetadata.session.meeting_date,'yyyy-MM-DD')}}
                        </span>
                    </p>
                </section>
               <section class="session-metadata-modal__wrapper__body__metadata">
                   <div v-if="sessionMetadata.session.id > 7720">
                       <span class="session-metadata-modal__wrapper__body__metadata__label">
                           Czas dodania:
                       </span>
                       <span class="session-metadata-modal__wrapper__body__metadata__value">
                           {{ formatDate(sessionMetadata.session.added_time, 'yyyy-MM-DD HH:mm') }}
                       </span>
                   </div>
                   <div>
                       <span class="session-metadata-modal__wrapper__body__metadata__label">
                           Czas ostatniej edycji:
                       </span>
                       <span class="session-metadata-modal__wrapper__body__metadata__value">
                           {{ formatDate(sessionMetadata.session.last_edit_time, 'yyyy-MM-DD HH:mm') }}
                       </span>
                   </div>
                   <div>
                       <span class="session-metadata-modal__wrapper__body__metadata__label">
                           Czas pierwszej publikacji:
                       </span>
                       <span v-if="sessionMetadata.session.publication_date !== null" class="session-metadata-modal__wrapper__body__metadata__value">
                           {{ formatDate(sessionMetadata.session.publication_date, 'yyyy-MM-DD HH:mm') }}
                       </span>
                       <span v-else class="session-metadata-modal__wrapper__body__metadata__value">
                           Nie opublikowano
                       </span>
                   </div>
                   <div>
                       <p class="session-metadata-modal__wrapper__body__metadata__label">
                           Potwierdzeni odbiorcy
                       </p>
                       <table v-if="sessionMetadata.userSessionReads.length > 0" class="table-bootstrap table-bootstrap-hover table-bootstrap-bordered table-bootstrap-striped align-middle">
                           <thead>
                               <tr class="align-middle">
                                   <th>#</th>
                                   <th>Nazwisko</th>
                                   <th>Imię</th>
                                   <th>Pierwszy odczyt</th>
                                   <th>Ostatni odczyt</th>
                               </tr>
                           </thead>
                           <tbody>
                               <tr v-for="(item, index) in sessionMetadata.userSessionReads" :key="index" class="align-middle">
                                   <td>{{index + 1}}</td>
                                   <td>{{item.last_name}}</td>
                                   <td>{{item.name}}</td>
                                   <td>{{ formatDate(item.first_read, 'yyyy-MM-DD HH:mm') }}</td>
                                   <td>{{ formatDate(item.last_read, 'yyyy-MM-DD HH:mm') }}</td>
                               </tr>
                           </tbody>
                       </table>
                       <p v-else>
                           Nikt jeszcze nie zapoznał się treścią sesji
                       </p>
                   </div>
               </section>
            </section>
        </section>
    </section>
</template>

<script charset="utf-8">
    import moment from "moment";
    import SessionService from "../../../../../services/session.service";
    import sessionService from "../../../../../services/session.service";

    export default {
        name: "SessionMetadataModal",
        components: {

        },
        computed: {
            getSessionId() {
                return this.$store.state.appStore.sessionMetadataId;
            },
        },
        data() {
            return {
                sessionMetadata: {
                    session: {},
                    cityCouncil: {},
                    userTypes: [],
                    userSessionReads: [],
                }
            }
        },
        mounted() {
            this.fetchSessionMetadata();
        },
        methods: {
            async fetchSessionMetadata() {
                try {
                    this.$store.commit("showLoader");
                    let result = await SessionService.getSessionMetadata(this.getSessionId);

                    if (result.status != 200)
                        throw new Error();

                    this.sessionMetadata = result.data;

                }
                catch (e) {
                    this.$store.commit("showError");
                }
                finally {

                    this.$store.commit("hideLoader");
                }
            },

            getOrganization() {
                let local = [ ...this.sessionMetadata.userTypes ];

                if(local.length <= 0)
                    return "";                    

                return local.map(obj => { return obj.name }).join(", ");
            },

            getMeetingName() {
                let local = [...this.sessionMetadata.userTypes];

                local = local.filter(obj => {
                    return obj.meeting_name !== ""
                })

                if (local.length <= 0)
                    return;

                return local[0].meeting_name
            },

            formatDate(date, format) {
                return moment(date).format(format);
            },
            async getSessionMetadataToWordClick() {
                this.$store.commit("showLoader");
                let response = await sessionService.getSessionMetadataToWord(this.getSessionId);
                if (response.status !== 200) {
                    this.$store.commit("hideLoader");
                    throw await Promise.reject(response)
                }

                const contentType = response.headers['content-type'];
                let a = document.createElement('a');
                var blob = new Blob([response.data], { 'type': contentType });
                a.href = window.URL.createObjectURL(blob);
                var filename = response.headers['content-disposition'].split("filename=")[1];

                filename = filename.substr(0, filename.indexOf(';'));
                filename = filename.replaceAll('"', '');
                a.download = filename
                a.click();
                this.$store.commit("hideLoader");
            },

        }
    }
</script>

<style lang="scss" scoped>

    .btn-without-text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        border-radius: 4px;
        padding: 12px 12px;
        cursor: pointer;
        height: 46px;
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        background-color: #ffffff;
        border: 1px solid #bbc2ce;
        background-color: #d80418;
        color: #ffffff;
        float: right;

        &:hover {
            background-color: #ad0313;
        }

        &__icon {
            width: 25px;
            height: 24px;
        }
    }

    .session-metadata-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        overflow: hidden;
        z-index: 9;

        &__wrapper {
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-height: 100vh;
            padding-bottom: 0px;
            padding-right: 0px;
            width: 60vw;
            overflow: hidden;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__title {
                }

                &__action {
                    &__exit-btn {
                        border: 0px;
                        background-color: transparent;
                        position: relative;
                        right: calc(-100% + 48px);
                        padding: 15px 0px;
                    }
                }
            }

            &__body {
                max-height: 65vh;
                overflow-y: auto;
                padding: 15px;
                display: flex;
                flex-direction: column;
                row-gap: 30px;

                &::-webkit-scrollbar {
                    width: 12px;
                    height: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #b5b8bd;
                    border-radius: 30px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #b5b8bd;
                }

                &::-webkit-scrollbar-track {
                    background: #ffffff;
                    border: 0.8px solid #dce4ee;
                    border-radius: 30px;
                    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
                }

                &__metadata {
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;

                    &__label {
                        font-weight: normal;
                    }

                    &__value {
                        font-weight: bolder;
                    }
                }
            }
        }
    }
</style>