<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">{{ isEdit ? 'Edytuj':'Dodaj'}} komunikat</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeAnnoucement');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section>
                    <label :class="[isGroups ? 'esesja-modal__wraper__body__label tabs selected' : 'esesja-modal__wraper__body__label tabs']" @click="changeGroup(true)">Grupy</label>
                    <label :class="[!isGroups ? 'esesja-modal__wraper__body__label tabs selected' : 'esesja-modal__wraper__body__label tabs']" @click="changeGroup(false)">Użytkownicy</label>
                    <section v-if="isGroups" class="selectedSection">
                        <Multiselect v-model="value"
                                     mode="tags"
                                     placeholder="Wyszukaj grupę..."
                                     :close-on-select="false"
                                     :searchable="true"
                                     class="multiselect-green"
                                     noResultsText="brak wyników"
                                     noOptionsText="brak opcji"
                                     :options="options" />

                    </section>
                    <section v-if="!isGroups" class="selectedSection">
                        <Multiselect v-model="value2"
                                     mode="tags"
                                     placeholder="Wyszukaj użytkownika..."
                                     :close-on-select="false"
                                     :searchable="true"
                                     class="multiselect-green"
                                     noResultsText="brak wyników"
                                     noOptionsText="brak opcji"
                                     :options="options2" />
                    </section>
                </section>
                <label class="esesja-modal__wraper__body__label">Tytuł</label>
                <section class="esesja-modal__row">
                    <!--<input class="esesja-modal__wraper__body__input" v-model="title" placecholder="temat" v-error="{errors, input_key: 'title'}" />-->
                    <textarea maxlength="500"
                              rows="2"
                              placeholder="Wpisz tytuł komunikatu..."
                              class="esesja-modal__wraper__body__input"
                              v-model="title"
                              v-error="{errors, input_key: 'title'}">
                    </textarea>
                    <CharactersCounter :text="title" maxLenght=500 />
                    <ErrorMessage v-if="isError('title', this.errors)" :errorMsgs="passErrorMsg('title',this.errors)" />
                </section>
                <label class="esesja-modal__wraper__body__label">Treść</label>
                <section class="esesja-modal__row">
                    <textarea class="esesja-modal__wraper__body__wyswig" v-model="description" v-error="{errors, input_key: 'description'}"></textarea>
                    <ErrorMessage v-if="isError('description', this.errors)" :errorMsgs="passErrorMsg('description',this.errors)" />
                </section>

                <label class="esesja-modal__wraper__body__label">Czas dodania komunikatu</label>
                <section class="esesja-modal__row-double">
                    <section class="edit-meeting__col-double">
                        <section class="esesja-modal__wraper__body__picker-wrapper">
                            <input type="date"
                                   class="esesja-modal__wraper__body__picker"
                                   v-model="communicat_add_date"
                                   v-error="{ errors, input_key: 'communicat_add_date', }" />
                            <ErrorMessage v-if="isError('communicat_add_date', this.errors)" :errorMsgs="passErrorMsg( 'communicat_add_date', this.errors ) " />
                        </section>
                    </section>
                    <section class="edit-meeting__col-double">
                        <section class="esesja-modal__wraper__body__picker-wrapper">
                            <input type="time"
                                   class="esesja-modal__wraper__body__picker"
                                   v-model="communicat_add_time"
                                   v-error="{ errors, input_key: 'communicat_add_time', }" />
                        </section>
                    </section>
                </section>


                <label class="esesja-modal__wraper__body__label">Termin komunikatu</label>
                <section class="esesja-modal__row-double">
                    <section class="esesja-modal__col-double">
                        <section class="esesja-modal__wraper__body__picker-wrapper">
                            <input type="date"
                                   class="esesja-modal__wraper__body__picker"
                                   v-model="meeting_date"
                                   :disabled="!isInCalendar"
                                   v-error="{errors, input_key: 'meeting_date'}" />
                            <ErrorMessage v-if="isError('meeting_date', this.errors)" :errorMsgs="passErrorMsg('meeting_date',this.errors)" />
                        </section>
                    </section>
                    <section class="switch-section">
                        <SwitchButton label="Pokaż w kalendarzu użytkownika" :isPublic="isInCalendar" @setIsPublic="(value) => (isInCalendar = value)" />
                    </section>
                </section>

                <label class="esesja-modal__wraper__body__label">Status komunikatu</label>
                <section class="checkbox-elements__checkbox-top" style="margin-bottom:10px;">
                    <section class="checkbox-elements__checkbox-wrapper">
                        <input type="checkbox" class="checkbox-elements__checkbox" :checked="notactive" />
                        <section class="checkbox-elements__checkbox-mark">
                            <img src="../../../../../assets/icons/components/accept-mark.svg" />
                        </section>
                    </section>
                    <label class="checkbox-elements__label checkbox-elements__label--checkbox" @click="changeSelectedValue(2);">nieaktywny</label>

                    <section class="checkbox-elements__checkbox-wrapper">
                        <input type="checkbox" class="checkbox-elements__checkbox" :checked="active" />
                        <section class="checkbox-elements__checkbox-mark">
                            <img src="../../../../../assets/icons/components/accept-mark.svg" />
                        </section>
                    </section>
                    <label class="checkbox-elements__label checkbox-elements__label--checkbox" @click="changeSelectedValue(3); ">aktywny</label>

                    <section class="checkbox-elements__checkbox-wrapper">
                        <input type="checkbox" class="checkbox-elements__checkbox" :checked="important" />
                        <section class="checkbox-elements__checkbox-mark">
                            <img src="../../../../../assets/icons/components/accept-mark.svg" />
                        </section>
                    </section>
                    <label class="checkbox-elements__label checkbox-elements__label--checkbox" @click="changeSelectedValue(4); ">ważne</label>
                </section>

                <label class="esesja-modal__wraper__body__label">Wyślij powiadomienie SMS/e-Mail</label>
                <section class="checkbox-elements__checkbox-top" style="margin-bottom:20px;">
                    <section class="checkbox-elements__checkbox-wrapper">
                        <input type="checkbox" class="checkbox-elements__checkbox" :checked="sendSms" />
                        <section class="checkbox-elements__checkbox-mark">
                            <img src="../../../../../assets/icons/components/accept-mark.svg" />
                        </section>
                    </section>
                    <label class="checkbox-elements__label checkbox-elements__label--checkbox" @click="sendSms = !sendSms;">sms</label>

                    <section class="checkbox-elements__checkbox-wrapper">
                        <input type="checkbox" class="checkbox-elements__checkbox" :checked="sendEmail" />
                        <section class="checkbox-elements__checkbox-mark">
                            <img src="../../../../../assets/icons/components/accept-mark.svg" />
                        </section>
                    </section>
                    <label class="checkbox-elements__label checkbox-elements__label--checkbox" @click="sendEmail = !sendEmail; ">e-mail</label>
                </section>

                
                <section class="d-flex flex-row justify-content-between pb-3" v-if="sendSms == true || sendEmail == true">
                    <section class="esesja-modal__wraper__body__label information">
                        <p>Wiadomość SMS/e-Mail zostanie wysłana do wszystkich wybranych grup i użytkowników.</p>
                        <p>Jeżeli użytkownik nie posiada numeru telefonu/adresu e-mail zostanie on pominięty w wysyłce.</p>
                        <p>W przypadku wysyłki SMS w treści wiadomości znajdzie się tytuł komunikatu.</p>
                        <p>W przypadku wysyłki e-Mail w treści wiadomości znajdzie cała treść komunikatu.</p>
                        <UserAccountEmailSettingsInfo v-if="sendEmail == true" style="margin-top: 10px;" /> 
                    </section>
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$emit('closeAnnoucement')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="addAnnouncement()" :disabled="(value.length == 0 && value2.length ==0) || title.length == 0">Wyślij</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script charset="utf-8">
import moment from "moment";
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import UserAccountEmailSettingsInfo from "../../../../Shared/Users/UserAccountEmailSettingsInfo.vue";
import Multiselect from '@vueform/multiselect'
import CommunicationService from "../../../../../services/communication.service";
import SwitchButton from "../../../../BasicControls/SwitchButton.vue";
import ErrorMessage from "../../../../BasicControls/ErrorMessage.vue";
import CharactersCounter from "../../../../../components/BasicControls/CharactersCounter.vue"
import {
    passErrorMsg,
    isError,
    setErrors,
    } from "../../../../../formValidation/main";
import AlertHelper from "../../../../../helpers/alerts.helper";

export default {
    name: "AddAnnouncement",
    data() {
        return {
            name: "",
            isLoading: false,
            description: this.elemToUpdate.description,
            value: [...this.selected],
            value2: [...this.selected2],
            isEdit: this.elemToUpdate.isEdit,
            isGroups: true,
            meeting_date: moment(this.elemToUpdate.meetingDate).format("YYYY-MM-DD"),
            communicat_add_time: this.setTimeForCommunicat(),
            communicat_add_date: this.setAddDateForCommunicat(),
            title: this.elemToUpdate.title,
            isInCalendar: this.elemToUpdate.isInCalendar,
            id: this.elemToUpdate.id,
            errors: [],
            sendSms: false,
            sendEmail: false,
            notactive: false,
            active: true,
            importatn: false,
            selected_status: 3,
        };
    },
    props: {
        options: Array,
        options2: Array,
        selected: Array,
        selected2: Array,
        datem: String,
        elemToUpdate: Object
    },
    components: {
        LoaderInside,
        Multiselect,
        ErrorMessage,
        SwitchButton,
        UserAccountEmailSettingsInfo,
        CharactersCounter
    },
    mounted() {
        this.changeSelectedValue(this.elemToUpdate.status);
    },
    methods: {
        changeGroup(value) {
            this.isGroups = value;
        },
        setAddDateForCommunicat() {
            if (this.elemToUpdate.addTime == null) {
                var dateTime = new Date();
                return moment(dateTime).format("YYYY-MM-DD");
            }
            else {
                return moment(this.elemToUpdate.addTime).format("YYYY-MM-DD");
            }
        },
        setTimeForCommunicat() {
            if (this.elemToUpdate.addTime == null) {
                var time = new Date();
                return moment(time).format("HH:mm");
            }
            else {
                return moment(this.elemToUpdate.addTime).format("HH:mm");
            }
        },
        validate() {
            var bo = true;
            var err = {};
            if (this.title.length <= 0) {
                err.title = ['Pole wymagane']
                bo = false
            }

            if (this.title.length > 0) {
                if (!this.title?.trim()) {
                    err.title = ['Pole nie może zawierać samych spacji']
                    bo = false
                }
            }

            if (this.isInCalendar) {
                if (moment(this.meeting_date, 'yyyy-MM-DD', true).isValid() == false) {
                    err.meeting_date = ['Zły format daty']
                    bo = false
                }
            }

            if (moment(this.communicat_add_date, 'yyyy-MM-DD', true).isValid() == false) {
                err.communicat_add_date = ['Zły format daty']
                bo = false
            }

            if (!bo) {
                this.isLoading = false;
                this.errors = setErrors(err)
            }
            return bo
        },
        changeSelectedValue(value) {
            if (value != null && value >= 0) {
                this.selected_status = value;
                switch (value) {
                    case 2:
                        // not active
                        this.active = false;
                        this.notactive = true;
                        this.important = false;
                        break;
                    case 3:
                        // active
                        this.active = true;
                        this.notactive = false;
                        this.important = false;
                        break;
                    case 4:
                        // important
                        this.active = false;
                        this.notactive = false;
                        this.important = true;
                        break;
                    default:
                        // default active
                        this.active = true;
                        this.notactive = false;
                        this.important = false;
                        break;
                }
            }
        },
        async addAnnouncement() {
            try {
                if (this.validate() == false)
                    return

                this.isLoading = true;

                var data = {
                    id: 0,
                    title: this.title.trim(),
                    description: this.description,
                    isInCalendar: this.isInCalendar,
                    users: this.value2,
                    groups: this.value,
                    status: this.selected_status,
                    meetingDate: null,
                    addTime: moment(this.communicat_add_date).format("YYYY-MM-DD"),
                    addTimeHours: this.communicat_add_time,
                    sendSms: this.sendSms,
                    sendEmail: this.sendEmail
                }

                if (this.isInCalendar) {
                    data.meetingDate = moment(this.meeting_date).format("YYYY-MM-DD");
                }

                if (this.isEdit) {

                    data.id = this.id;

                    var resultEdit = (await CommunicationService.updateAnnoucementForUsers(data)).data;
                    if (resultEdit > 0) {
                        this.value = [];
                        this.value2 = [];
                        this.title = "";
                        this.description = "";
                        this.isInCalendar = false;
                        this.communicat_add_date = null;
                        this.communicat_add_time = null;

                        data.lastUpdateTime = new Date();
                        data.shows = this.elemToUpdate.shows;
                        data.author = this.elemToUpdate.author;
                        data.files = this.elemToUpdate.files;
                        data.id = resultEdit;
                        data.show = false;
                        this.$emit("closeAnnoucement");
                        this.$emit("updateCalendar", data);
                        this.$swal(AlertHelper.successAlert("Komunikat został utworzony"));
                        this.isLoading = false;
                    }
                } else {
                    var result = (await CommunicationService.addAnnoucementForUsers(data)).data;
                    if (result > 0) {
                        this.value = [];
                        this.value2 = [];
                        this.title = "";
                        this.description = "";
                        this.isInCalendar = false;
                        this.communicat_add_date = null;
                        this.communicat_add_time = null;

                        data.lastUpdateTime = new Date();
                        data.shows = 0;
                        data.author = this.elemToUpdate.author;
                        data.files = [];
                        data.id = result;
                        data.show = false;
                        this.$emit("closeAnnoucement");
                        this.$emit("updateCalendar", data);
                        this.$swal(AlertHelper.successAlert("Komunikat został utworzony"));
                        this.isLoading = false;
                    }
                }
            } catch (e) {
                if (e.response !== undefined) {
                    if (e.response.status === 401) {
                        this.$router.push({ name: 'Login' })
                    }
                    else if (e.response.status === 422) {
                        var toast = this.$swal.mixin(AlertHelper.errorToastElement());
                        toast.fire(AlertHelper.errorToastAlert(`Nie wystarczająca liczba kredytów. Dostępne: ${e.response.data?.availableCredits}, wymagane: ${e.response.data?.requiredCredits}`));
                    }
                    else {
                        var toast2 = this.$swal.mixin(AlertHelper.errorToastElement());
                        toast2.fire(AlertHelper.errorToastAlert(`Wystąpił ogólny błąd`));
                    }
                }
            }
            finally {
                this.isLoading = false;
            }
        },
        passErrorMsg,
        isError,
        setErrors,
    },
};</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';

    .information {
        font-size: 11px;
        padding-bottom: 10px;
        cursor: default;
    }

    .label {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-bottom: 8px;

        &--checkbox {
            padding-left: 36px;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            z-index: 9;
            color: #333956;
            cursor: pointer;
        }
    }

    .multiselect-green {
        --ms-tag-bg: #D1FAE5;
        --ms-tag-color: #059669;
        margin-top: 10px;
    }

    .checkbox {
        font-weight: normal;
        font-size: 16px;
        line-height: 144%;
        color: #333956;
        margin-left: 12px;
        margin-bottom: 0px;
        padding-left: 24px;
        z-index: 0;
        cursor: pointer;
    }

    .checkbox-wrapper {
        display: flex;
        padding: 5px;
        float: left;
    }

    .multiSelectrow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;

        &__elem {
            width: 240px;
        }
    }

    .selectedSection {
        background-color: #dce4ee;
        padding: 5px;
        border-radius: 0px 5px 5px 5px;
    }

    .tabs {
        margin-left: 0px;
        cursor: pointer;
        padding: 5px;
        background-color: #D3D3D3;
        border-radius: 5px 5px 0px 0px;
    }

    .selected {
        background-color: #dce4ee;
        margin: 0px;
        cursor: pointer;
        padding: 5px;
        border-radius: 5px 5px 0px 0px;
    }


    .checkbox-elements {
       
        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;

            &--checkbox {
                padding-left: 32px;
                z-index: 10;
                cursor: pointer;
                font-weight: normal !important;
            }

            &--normal {
                font-weight: normal !important;
            }
        }

        &__checkbox-top {
            display: flex;
            align-items: center;
            margin-top: 12px;

            &--col {
                margin-bottom: 13px;
                align-items: flex-start;
            }
        }

        &__checkbox {
            width: 0px;
            height: 0px;
            display: none;

            &:checked ~ .checkbox-elements__checkbox-mark {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:checked ~ .add-contact-form__label--checkbox {
                padding-left: 0px;
            }
        }

        &__checkbox-wrapper {
            width: 0px;
            height: 24px;
            position: relative;
            margin-left: 20px;

            &:before {
                content: "";
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
                border-radius: 4px;
                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                top: 0px;
                left: 0px;
                opacity: 1;
            }
        }

        &__checkbox-mark {
            background: #00082c;
            border: 1px solid #00082c;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 24px;
            height: 24px;
            position: absolute;
            z-index: 5;
            display: none;
            top: 0px;
            left: 0px;
            opacity: 1;
        }
    }
</style>
