<template>
    <section>
        <section class="contact-top">
            <h3>{{inter.typeName}} {{ inter.number != null ? inter.number : inter.id }}</h3>
            <section class="contact-top__action-buttons">
                <ButtonWithIcon icon="upload-attachment -color" style=""
                                text="Odpowiedz w pliku"
                                :classModified="true"
                                @click="addFile(inter)" />

                <ButtonWithIcon icon="plus-small" style=""
                                text="Odpowiedz"
                                :classModified="true"
                                @click="addResponse()" />

                <ButtonWithIcon icon="go-back-arrow-small" style=""
                                text="Powrót"
                                :classModified="true"
                                @click="this.$emit('closeShowInterpellation')" />
            </section>
        </section>
        <section class="contacts__list">
            <section class="contact-list">
                <section>
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section>
                                <div style="text-align:right">
                                    <span>{{formatDateToDisplay(inter.time)}}</span>
                                    <button icon @click="downloadAsPdfFile(inter)" v-if="interAdminPrivilige && inter.body != null && inter.body.length > 0" style="cursor:pointer; margin-left: 15px;" v-tooltip="'Pobierz pdf'">
                                        <img src="../../../../../assets/icons/components/download-color.svg" />
                                    </button>
                                </div>
                                <div>
                                    <span v-if="inter.isPublic" class="dot">Publiczne</span>
                                </div>

                                <div v-if="inter.title != null && inter.title != ''">
                                    <span class="contact-box__title">Tytuł: <b>{{ inter.title }}</b></span>
                                </div>
                                <span ref="pdf" class="contact-box__title">Autor: <b>{{ inter.author }}</b></span>
                                <div class="contact-box__title">Treść:</div>
                                <div class="contact-box__text" v-html="inter.body.replace(/\n/g, '<br />')"></div>
                                <section style="margin-top:10px;" v-if="inter.files != null && inter.files.length > 0">
                                    <h5 class="add-note-form__label">Załączniki</h5>
                                    <div>
                                        <ul class="add-note-form__labelFile" v-for="item in inter.files" :key="item.id">
                                            <li style="margin-top: 5px;">
                                                <span style="cursor: pointer; margin-right: 5px;" @click="downloadAttachement(inter.id, item.id)">{{item.name}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>

        <section class="contacts__list" v-if="responsesToEdit.length > 0">
            <div style="margin:15px;">Odpowiedzi:</div>
            <section class="contact-list">
                <section v-for="s in responsesToEdit" :key="s.id">
                    <section class="contact-box">
                        <section class="contact-box__response">
                            <section>
                                <div style="text-align:right">
                                    <span>{{formatDateToDisplay(s.time)}}</span>
                                    <button icon @click="deleteInterpelation(s.id)" v-if="interAdminPrivilige" style="cursor:pointer; margin-left: 15px;" v-tooltip="'Usuń odpowiedź'">
                                        <img src="../../../../../assets/icons/components/bin.svg" />
                                    </button>
                                    <button icon @click="downloadAsPdfFile(s)" v-if="interAdminPrivilige && !s.isFile" style="cursor:pointer; margin-left: 15px;" v-tooltip="'Pobierz pdf'">
                                        <img src="../../../../../assets/icons/components/download-color.svg" />
                                    </button>
                                </div>
                                <div>
                                    <span v-if="s.isPublic" class="dot">Publiczne</span>
                                </div>

                                <div v-if="s.title != null && s.title != ''">
                                    <span class="contact-box__title">Tytuł: <b>{{ s.title }}</b></span>
                                </div>
                                <span class="contact-box__title">Autor: <b>{{ s.author }}</b></span>
                                <div class="contact-box__title" v-if="!s.isFile">Treść:</div>
                                <div class="contact-box__text" v-if="!s.isFile">{{s.body}}</div>

                                <section style="margin-top:10px;" v-if="s.isFile">
                                    <h5 class="add-note-form__label">Załączniki</h5>
                                    <div>
                                        <ul class="add-note-form__labelFile">
                                            <li style="margin-top: 5px;">
                                                <span style="cursor: pointer; margin-right: 5px;" @click="downloadAttachement(inter.id, s.id)">{{s.body}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </section>
                            </section>
                        </section>

                    </section>
                </section>
            </section>
        </section>
        <AddResponseModal v-if="showAddResponse" @closeShowResponse="closeShowResponseModal" @updateResponses="updResponse" :intId="intId" :isPublic="isPublic" />
    </section>
</template>

<script>
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import AddResponseModal from "../Modals/AddResponse.vue";
import InterpellationService from "../../../../../services/interpellation.service";
import ButtonWithIcon from "../../../../BasicControls/ButtonWithIcon.vue";
import { encryptStorage } from '../../../../../services/encrypt.service';
import AlertHelper from '@/helpers/alerts.helper.js';
import SettingsService from "../../../../../services/settings.service";

export default {
    name: "Announcement",
    data() {
        return {
            interAdminPrivilige: encryptStorage.getItem('priv').zarzadzanie_interpelacjami,
            interSendAdminPrivilige: encryptStorage.getItem('priv').wysylanie_interpelacji,
            files: [],
            responsesToEdit: [...this.responses],
            logo: require('@/assets/imgs/esesjalogo.png'),
            showAddResponse: false,
            intId: 0,
            isPublic: false
        };
        },
        props: {
            inter: Object,
            responses: Array
        },
        computed:{
            isAnyModalOpen(){
                return this.showAddResponse;
            }
        },
        watch: {
            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },
        methods: {
            addResponse() {
                this.intId = this.inter.id;
                this.isPublic = this.inter.isPublic;
                this.showAddResponse = true;
            },
            updResponse(response) {
                if (response != null) {
                    this.responsesToEdit.push(response.data);
                    var em = { id: this.inter.id, value: 1 };
                    this.$emit("updateInterpellationsResponseCount", em);
                }
            },
            closeShowResponseModal() {
                this.intId = 0;
                this.isPublic = false;
                this.showAddResponse = false;
            },
        addFile(value) {
            let input = document.createElement('input');
            input.type = 'file';
            input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg,.xls,.ods,.odt,.txt,.rtf,.xlsx';
            input.multiple = false;
            input.click();
            input.addEventListener('change', (event) => {
                if (event.target.files[0].size <= 30000000) {
                    if (this.checkMimeType(event.target.files[0].type)) {
                        if (this.files.length > 0) {
                            for (var i = 0; i < this.files.length; i++) {
                                if (this.files[i][0].name == event.target.files[0].name)
                                    return;
                            }
                            this.files.push(event.target.files);
                        }
                        else {
                            this.files.push(event.target.files);
                        }

                        this.sendFileToDb(value, this.files);
                    }
                    else {
                        this.$swal(AlertHelper.errorAlert("Nieobsługiwany typ pliku. Pliki obsługiwane to: PDF, DOC, DOCX, JPG, PNG, RTF, XLS, TXT, ODT, ODS"));
                    }
                } else {
                    this.$swal(AlertHelper.errorAlert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB"));
                }
            });
        },
        checkMimeType(type) {
            if (type != "application/pdf" && type != "image/jpeg" && type != "image/jpg" &&
                type != "image/png" && type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                type != "text/plain" && type != "application/vnd.oasis.opendocument.text" && type != "application/vnd.oasis.opendocument.spreadsheet" &&
                type != "application/vnd.ms-excel" && type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && type != "application/rtf" && type != "application/msword") {
                return false;
            } else {
                return true;
            }
        },
        deleteInterpelation(value) {
            const actionToFire = () => {
                InterpellationService.deleteInterpelationResponse(value).then((result) => {
                    if (result.status == 200) {
                        var index = this.responsesToEdit.findIndex(x => x.id == value);
                        if (index != -1) {
                            this.responsesToEdit.splice(index, 1);

                            var em = { id: this.inter.id, value: - 1 };

                            this.$emit("updateInterpellationsResponseCount", em);
                        }
                    }
                });
            };
            this.$store.commit("showConfirmationAlert", actionToFire);
            },
            getBase64ImageFromURL(url) {
                return new Promise((resolve, reject) => {
                    var img = new Image();
                    img.setAttribute("crossOrigin", "anonymous");

                    img.onload = () => {
                        var canvas = document.createElement("canvas");
                        canvas.width = img.width;
                        canvas.height = img.height;

                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);

                        var dataURL = canvas.toDataURL("image/png");

                        resolve(dataURL);
                    };

                    img.onerror = error => {
                        reject(error);
                    };

                    img.src = url;
                });
            },
            async getClientFooter() {
                try {

                    var footer = await SettingsService.getFooterClientImage();

                    if (footer.status !== 200) {
                        return '';
                    }

                    return footer.data.image;

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async getClientHeaderLogo() {
                try {

                    var header = await SettingsService.getLogoClientImage();

                    if (header.status !== 200) {
                        return '';
                    }

                    return header.data.image;

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async calculateLogo(logo) {

                if (logo.length > 0) {
                    let img = new Image();
                    img.src = logo;
                    await img.decode();

                    return img;
                }
                else {
                    return '';
                }

            },
            async downloadAsPdfFile(elem) {
                var logo = await this.getClientHeaderLogo();
                var s = this.inter.number != null ? this.inter.number : this.inter.id;
                var html = htmlToPdfmake("<p><b>" + this.inter.typeName + " " + s + "</b><hr></p><p>Interpelacja z dnia: <b>" + this.formatDateToDisplay(elem.time) +
                    "</b></p><p>Autor: <b>" + elem.author + "</b></p><p style='text-align: justify;'>" + elem.body.replace(/\n/g, '<br />') + "</p>");

                var date = new Date().toLocaleDateString();
                var docWithLogo = await this.getClientFooter();

                var imageData = await this.calculateLogo(logo);

                const documentDefinition = {
                    content: [{ text: '', margin: 10 }, html],
                    footer: function (currentPage, pageCount) {
                        if (docWithLogo.length > 0) {
                            return [
                                {
                                    columns:
                                        [
                                            { image: docWithLogo, margin: [30, -50, 0, 0], maxWidth: 250 },
                                            { text: date + " | Wygenerowano za pomocą app.esesja.pl, Strona " + currentPage.toString() + ' z ' + pageCount, alignment: 'right', style: ['quote', 'small'], margin: [0, 20, 50, 0] }
                                        ]
                                }
                            ]
                        }
                        else {
                            return [{ text: date + " | Wygenerowano za pomocą app.esesja.pl, Strona " + currentPage.toString() + ' z ' + pageCount, alignment: 'right', style: ['quote', 'small'], margin: [0, 20, 50, 0] }];
                        }
                    },
                    header: function () {
                        if (logo.length > 0) {

                            var width = 0;
                            if (imageData.width >= imageData.height) {
                                width = 80;
                            }
                            else {
                                width = 60;
                            }

                            return [{ image: logo, alignment: 'right', margin: [0, 20, 20, 0], maxWidth: width }]
                        }
                    },
                    styles: {
                        header: {
                            fontSize: 18,
                            bold: true,
                            margin: 10
                        },
                        subheader: {
                            fontSize: 15,
                            bold: true,
                            margin: 10
                        },
                        normal: {
                            fontSize: 10,
                            margin: 10
                        },
                        normal2: {
                            fontSize: 10,
                            margin: [10, 30, 0, 0]
                        },
                        normalTitlePublic: {
                            fontSize: 10,
                            margin: [10, 10, 0, 0],
                            decoration: 'underline'
                        },
                        quote: {
                            italics: true
                        },
                        small: {
                            fontSize: 8
                        }
                    }
                };
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(documentDefinition).open();
            },
        async downloadAttachement(intId, fileId) {
            try {
                var result = (await InterpellationService.downloadAttachment(intId, fileId));
                if (result.status == 200) {
                    const contentType = result.headers['content-type'];
                    var a = document.createElement('a');
                    var blob = new Blob([result.data], { 'type': contentType });
                    a.href = window.URL.createObjectURL(blob);
                    a.download = result.headers['content-disposition'].split('filename=')[1].split(';')[0];
                    a.click();
                }
            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        async sendFileToDb(int, files) {
            try {
                let formData = new FormData();
                for (var i = 0; i < files.length; i++) {
                    let file = files[i][0];
                    formData.append('files[' + i + ']', file);
                }

                formData.append("id", int.id);
                formData.append("lid", int.lid);
                formData.append("response", true);

                var result = (await InterpellationService.addInterpellationAttachement(formData)).data;
                if (Object.keys(result).length > 0) {
                    this.files = [];
                    this.responsesToEdit.push(result);
                    var em = { id: this.inter.id, value: 1 };
                    this.$emit("updateInterpellationsResponseCount", em);
                }
            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        formatDateToDisplay(value) {
            var date = new Date(value)
            var d = date.toLocaleString('default', {
                year: 'numeric',
                month: 'long',
                weekday: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            });

            return d;
        },
    },
    components: {
        ButtonWithIcon,
        AddResponseModal
    },
};</script>

<style lang="scss" scoped>

    .contacts__list button {
        background: transparent;
        border: none;
    }

    .dot {
        font-weight: 600;
        font-size: 13px;
        line-height: 132%;
        padding-left: 16px;
        position: relative;
        white-space: nowrap;

        &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: #60a800;
            box-shadow: 1px 2px 4px rgba(65, 114, 0, 0.16);
            border-radius: 100%;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .defoult_button_pagination {
        width: 10%;
        height: 32px;
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        /*&:hover {
            background-color: #e8ebef;
        }*/
    }
    .meetings-settings {
        margin-top: 20px;

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        &__wrapper {
            position: relative;

            /*&::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 24px;
                right: 40px;
                z-index: 99;
            }*/
        }

        &__select {
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            padding: 8px 10px 8px 16px;
            font-weight: normal;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("../../../../../assets/icons/components/select-arrow.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 14px);
            background-position-y: 50%;
            min-width: 300px;
            min-height: 48px;

            &:focus {
                outline: none;
            }
        }
    }

    .contacts {
        margin: 49px 48px;

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 20px;
        }

        &__add-user-btn {
            position: fixed;
            bottom: 40px;
            right: 48px;
            width: 56px;
            height: 56px;
            border: 0px;
            background: #d80418;
            box-shadow: 8px 8px 28px rgba(173, 3, 19, 0.16);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2 ease-in-out;

            &:hover {
                background: #ad0313 !important;
            }
        }

        &__add-user-btn-icon {
            width: 25px;
            height: 24px;
        }
    }

    .picker {
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        min-width: 238px;
        height: 56px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;

        &[type="date"]::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("../../../../../assets/icons/components/callendar.svg") no-repeat;
            background-position-x: 100%;
            width: 22px;
            height: 22px;
            border-width: thin;
        }

        &[type="time"]::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("../../../../../assets/icons/components/clock.svg") no-repeat;
            width: 22px;
            height: 22px;
            border-width: thin;
        }
    }

    .defoult_button {
        width: 50px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e8ebef;
        }
    }

    .picker-wrapper {
        position: relative;

        /*&::after {
            content: "";
            position: absolute;
            top: 8px;
            background: #e2e5eb;
            width: 1px;
            height: 40px;
            right: 56px;
            z-index: 99;
        }*/
    }

    .contact-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &__input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            width: 326px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__button-search {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 32px;
                background: #e2e5eb;
                top: 8px;
                left: 0px;
            }
        }

        &__form {
            display: flex;
            align-items: center;
        }

        &__search-icon {
            padding: 14px;
        }

        &__action-buttons {
            display: flex;
        }
    }

    .contact-list-no-result {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact-box {
        background: #ffffff;
        width: 100%;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        margin-bottom: 12px;
        transition: all 0.2s ease-in-out;

        &:hover .contact-box-actions {
            opacity: 1;
        }

        &:hover {
            border: 1px solid #bbc2ce;
        }

        &__title {
            margin-left: 16px;
            margin-right: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
        }

        &__text {
            margin-left: 36px;
            margin-right: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            text-align: justify;
        }

        &__top {
            align-items: center;
            padding: 29px;
            justify-content: space-between;
        }

        &__response {
            //display: flex;
            align-items: center;
            padding: 29px;
            justify-content: space-between;
            background-color: #EFFAFD;
        }

        &__people {
            transition: all 0.3s ease-in-out;

            &--close {
                max-height: 0px;
                display: none;
            }

        }

        &__people-container {
            padding: 16px;
            background: #f4f6fa;
            border-radius: 12px;
            margin: 0px 16px;

            &--close {
                max-height: 0px;
            }
        }

        &__arrow {
            transform: rotate(0deg);
            transition: all 0.3s ease-in-out;

            &--open {
                transform: rotate(180deg);
            }
        }
    }

    .contact-box-description {
        margin: 25px 34px 16px 34px;

        &__data {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #666b80;

            &:nth-child(1) {
                margin-right: 228px;

                @media only screen and (max-width: 1600px) {
                    margin-right: 138px;
                }
            }

            &:nth-child(2) {
                margin-right: 193px;
            }
        }
    }

    .add-contact-button {
        display: flex;
        align-items: center;
        margin: 20px 32px;
        cursor: pointer;

        &__text {
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;
            margin-left: 8px;
        }

        &--no-margin {
            margin: 37px 0px 0px 0px;
        }
    }

    .contacts-no-data {
        display: flex;
        margin: 27px;
        flex-direction: column;
    }

    .contact-box-actions {
        display: flex;
        align-items: center;
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }

</style>
