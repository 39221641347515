<template>
    <section class="addquest-autocomplete-list-item"
             :class="{'favorite': item.isFavorite && !item.isUserTemp, 'temporary': item.isUserTemp}">

        <section class="addquest-autocomplete-list-item-user-data">
            <section class="addquest-autocomplete-list-item-user-data-username">
                <section class="addquest-autocomplete-list-item-user-avatar"
                         @click="$emit('addParticipantToRequestSpeechList', item.uid, 2)"
                         v-tooltip="'Dodaj do listy dyskutantów'">
                    <img :src="getUserAvatar(item.uid)" class="addquest-autocomplete-list-item-user-avatar-img" />
                    <section class="addquest-autocomplete-list-item-user-name">
                        <span v-html=item.name></span> <span v-html=item.surname></span>
                    </section>
                    <section class="addquest-autocomplete-list-item-user-data-role">
                        {{item.role}}
                    </section>
                </section>

                <img src="@/assets/icons/components/star.svg"
                     alt="Ulubiony użytkownik"
                     class="user-favourite-icon"
                     @click="$emit('removeParticipantFromFavorite', item.uid)"
                     v-if="!item.isUserTemp && item.isFavorite" v-tooltip="'Usuń ulubionych'" />

                <img src="@/assets/icons/components/star-empty.svg"
                     alt="Zwykły użytkownik"
                     class="user-favourite-icon-empty"
                     @click="$emit('addParticipantToFavorite', item.uid)"
                     v-if="!item.isUserTemp && !item.isFavorite" v-tooltip="'Dodaj do ulubionych'" />
            </section>

            <section class="addquest-autocomplete-list-item-user-data-function">
                <button class="addquest-button addquest-button__white"
                        v-tooltip="'Dodaj do listy dyskutantów'"
                        @click="$emit('addParticipantToRequestSpeechList', item.uid, 2)">
                    <img src="@/assets/icons/components/add-list.svg" alt="Dodaj do listy" class="button-image" />
                </button>
                <button class="addquest-button addquest-button__white"
                        v-tooltip="'Dodaj jako AV'"
                        @click="$emit('addParticipantToRequestSpeechList', item.uid, 3)">
                    AV
                </button>
                <button class="addquest-button addquest-button__white"
                        v-tooltip="'Dodaj jako WF'"
                        @click="$emit('addParticipantToRequestSpeechList', item.uid, 4)">
                    WF
                </button>
                <button class="addquest-button addquest-button__white"
                        v-tooltip="'Udziel głosu'"
                        @click="$emit('giveParticipantSpeech', item.uid)">
                    <img src="@/assets/icons/components/microphone.svg" alt="Udziel głosu" class="button-image" />
                </button>
                <button class="addquest-button addquest-button__red"
                        v-if="item.isUserTemp"
                        v-tooltip="'Usuń'"
                        @click="$emit('removeParticipant', item.uid)">
                    <img src="@/assets/icons/components/delete-point.svg" class="button-image" alt="Usuń" />
                </button>

            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "DiscussionQuestItem",
        emits: [
            "addParticipantToRequestSpeechList",
            "removeParticipantFromFavorite",
            "addParticipantToFavorite",
            "giveParticipantSpeech",
            "removeParticipant"
        ],
        props: {
            item: {
                type: Object,
                required: true,
            }
        },
        methods: {
            getUserAvatar(uid) {
                return `https://ssl.esesja.pl/users/${uid}.jpg`;
            },
        }
    }
</script>
<style lang="scss" scoped>
    .addquest-autocomplete-list-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 2px 0px;
        cursor: pointer;
        background: #ffffff;
        width: 286px;
        height: 112px;
        align-items: center;

        &:nth-child(even) {
            background: #f2f2f2;
        }

        .user-favourite-icon-empty {
            height: 20px;
            float: right;
            display: none;
        }

        &:hover {
            background: #ddd;
        }

        &:hover .user-favourite-icon-empty {
            display: block;
        }

        .addquest-autocomplete-list-item-user-avatar {
            margin-left: 5px;
            flex-grow: 1;

            .addquest-autocomplete-list-item-user-avatar-img {
                width: 60px;
                float: left;
                padding: 0px 5px 5px 0px;
            }
        }

        .addquest-autocomplete-list-item-user-data {
            display: flex;
            flex-direction: column;
            row-gap: 0px;
            width: 100%;
            margin: 5px 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;
            overflow-wrap: break-word;

            .addquest-autocomplete-list-item-user-name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .addquest-autocomplete-list-item-user-data-username {
                display: flex;
            }

            .user-favourite-icon {
                height: 20px;
                float: right;
            }

            .addquest-autocomplete-list-item-user-data-role {
                font-size: 12px;
                font-weight: normal;
                color: #5c5c5c;
                margin-bottom: 10px;
                height: 37px;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .highlite {
                color: #000;
                background: #9eb8ff;
            }
        }
    }

    .addquest-autocomplete-list-item-user-data-function {
        display: flex;
        flex-direction: row;

        .addquest-button {
            padding: 5px 10px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: 0px;
            transition: all 0.2s ease-in-out;

            .button-image {
                height: 20px;
            }

            &__white {
                background: #fff;
                border: 1px solid #bbc2ce;

                &:hover {
                    background: #e8ebef;
                }
            }


            &__red {
                background: #d80418;
                color: #fff;

                .button-image {
                    filter: brightness(0) invert(1);
                }

                &:hover {
                    background: #ad0313;
                }
            }
        }
    }
</style>