<template>
    <section class="attachment-viewer-modal esesja-modal" ref="modal" tabindex="0">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__body">
                <section class="attachment-viewer--header">
                    <button class="esesja-modal__wraper__header__exit-btn" @click="closeModal" style="background:#fff">
                        <img src="@/assets/icons/components/exit-btn.svg" />
                    </button>
                </section>
                <section class="attachment-viewer--modal--body">
                    <iframe :src="resolveIframeUrl()" style="border: none;" ref="modalIframe"></iframe>
                </section>
                <section class="download-section">
                    <button class="file-download-button" @click="download">Pobierz</button>
                </section>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import esesjaInteractiveService from '../../../../../services/esesja.interactive.service';
    import alertsHelper from '../../../../../helpers/alerts.helper';
    
    export default {
        name: "AttachmentViewer",
        emits: ["close"],
        props: {
            title: {
                type: String,
                default: 'Przeglądarka załączników'
            },
            pointId: {
                type: Number,
                required: true
            },
            attachmentId: {
                type: Number,
                required: true
            },
            fileData: {
                type: Object,
                require: true
            },
            filename: {
                type: String,
                require: true,
            },
            sessionId: {
                type: Number,
                require: true,
            }

        },
        watch:{
            '$refs.modalIframe':{
                deep: true,
                handler: function(e){
                    console.log("watch iframe", e)
                }
            }
        },
        data() {
            return {
                fileBlob: undefined,
                imageData: undefined,
                textData: undefined,
                fileType: {
                    isPfd: false,
                    isImage: false,
                    isText: false,
                },
                pdfFIle: this.attachmentId,
                isBusy: false,
            }
        },

        computed: {
            isInteractive() {
                return this.$store.state.appStore.isInInteractiveMode;
            },
        },
        async mounted() {
            if (this.pointId === -1) {
                return;
            }

            if (this.attachmentId === -1) {
                return;
            }

            window.addEventListener('message', this.receiveMessage, false);

            this.$refs.modal.focus();
        },
        methods: {
            receiveMessage(event) {
                if (event.data.width !== undefined && event.data.height !== undefined) {
                    let maxWidth = window.document.documentElement.clientWidth * 0.9;
                    let maxHeight = window.document.documentElement.clientHeight * 0.9;

                    let isForce = false;
                    if (event.data.force !== undefined) {
                        isForce = event.data.force;
                    }
                    let newHeight = 0;
                    let newWidth = 0;
                    if (maxHeight >= event.data.height && maxWidth >= event.data.width) {
                        newHeight = event.data.height;
                        newWidth = event.data.width;
                    }
                    else {
                        let scaleX = maxWidth / event.data.width;
                        let scale = maxHeight / event.data.height;
                        if (scaleX < scale) {
                            scale = scaleX;
                        }
                        newHeight = event.data.height * scale;
                        newWidth = event.data.width * scale;
                    }
                    if (isForce) {
                        newWidth = event.data.width;
                        if (newWidth > maxWidth) {
                            newWidth = maxWidth
                        }
                    }
                    if (this.$refs.modalIframe !== null) {
                        this.$refs.modalIframe.style.width = `${newWidth}px`;
                        this.$refs.modalIframe.style.height = `${newHeight}px`;
                    }
                }
                else {
                    if (event.data.nextPage !== undefined && event.data.nextPage == true) {
                        this.nextPage(event.data.page, event.data.scale);
                    }

                    if (event.data.prevPage !== undefined && event.data.prevPage == true) {
                        this.prevPage(event.data.page, event.data.scale);
                    }
                }
            },
            resolveIframeUrl() {
                let routeResolve = this.$router.resolve({
                    name: 'AttachmentViewer',
                    params: { attachmentId: this.attachmentId },
                    query: {
                        sessionId: this.sessionId,
                        isInteractive: this.isInteractive,
                        pointId: this.pointId
                    }
                });
                return routeResolve.fullPath;
            },
            async closeModal() {
                window.removeEventListener("message", this.receiveMessage);
                let urlCreator = window.URL || window.webkitURL;
                if (this.fileType.isPfd) {
                    //this.fileBlob.destroy();
                    urlCreator.revokeObjectURL(this.fileBlob);
                    this.fileBlob = undefined;
                }
                else if (this.fileType.isImage) {
                    urlCreator.revokeObjectURL(this.imageData)
                }
                this.fileType.isPfd = false;
                this.fileType.isImage = false;
                this.fileType.isText = false;

                this.$emit('close')
            },

            prevPage(page, scale) {
                if (this.isInteractive) {
                    esesjaInteractiveService.changeAttachmentPage(this.sessionId, page, scale);
                }
            },

            nextPage(page, scale) {
                if (this.isInteractive) {
                    esesjaInteractiveService.changeAttachmentPage(this.sessionId, page, scale);
                }
            },

            scaleUp(page, scale) {
                if (this.isInteractive) {
                    esesjaInteractiveService.changeAttachmentPage(this.sessionId, page, scale);
                }
            },

            scaleDown(page, scale) {
                if (this.isInteractive) {
                    esesjaInteractiveService.changeAttachmentPage(this.sessionId, page, scale);
                }
            },

            async download() {
                try {
                    var a = document.createElement('a');
                    var blob = new Blob([this.fileData.data], { 'type': this.fileData.headers['content-type'] });

                    a.href = window.URL.createObjectURL(blob);
                    a.download = this.filename;
                    a.click();
                }
                catch {
                    var errorAlert = this.$swal.mixin(alertsHelper.errorToastElement());
                    errorAlert.fire(alertsHelper.errorToastAlert('Nie można pobrać pliku'));
                }
            },
        }
    }
</script>
<style lang="scss" scope>
    @import "../../../../../assets/scss/esesja-modal.scss";


    .attachment-viewer-modal .esesja-modal__wraper {
        width: auto !important;
        height: auto !important;
        max-width: none !important;
        max-height: none !important;
    }

    .attachment-viewer-modal .esesja-modal__wraper__body {
        width: auto !important;
        height: auto !important;
        max-width: none !important;
        max-height: none !important;
        padding: 0px;
    }

    .download-section {
        display: flex;
        align-items: center;
        justify-content: center;

        .file-download-button {
            height: 52px;
            margin-left: 8px;
            background-color: white;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;
            font-size: 1.2em;
            padding: 0px 15px;
            margin-bottom: 10px;

            &--small {
                width: 30px;
            }

            &:hover {
                background-color: #e8ebef;
            }
        }
    }
            /*.attachment-viewer {
        display: flex;
        flex-direction: column;

        &--header {
            display: flex;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        &--body {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &--footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &--button {
            
            height: 32px;
            margin-left: 8px;
            background-color: white;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;

            &--small{
                width: 30px;
            }

            img {
                width: 2.2em
            }

            &:hover {
                background-color: #e8ebef;
            }

        }
    }*/
</style>