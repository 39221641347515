<template>
    <section class="custom-break-overlay">
        <section class="custom-break-container">
            <header class="custom-break-container__header">
                <section class="custom-break-container__header__action">
                    <button class="custom-break-container__header__action__exit-btn"
                            @click="(e)=> { e.preventDefault();this.$store.commit('hideCustomBreakModal');}">
                        <img src="../../../../assets/icons/components/exit-btn.svg" />
                    </button>
                </section>
            </header>
            <section class="custom-break-container__title">
                <span class="modal-title">
                    Ustawienia przerwy
                </span>
            </section>
            <section class="custom-break-container__body">
                <div>
                    <label for="set-time-break" class="form-bootstrap-label">Długość przerwy w minutach</label>
                    <input type="number" min="1" class="form-bootstrap-control" id="set-time-break" v-model="breakModel.minutes" @input="changeMinutes" />
                </div>
                <div>
                    <label for="set-end-break-date" class="form-bootstrap-label">Lub ustal koniec przerwy</label>
                    <input type="datetime-local" class="form-bootstrap-control" id="set-end-break-date" v-model="breakModel.endDatetime" @input="changeEndDate" />
                </div>

            </section>
            <div class="custom-break-container__menu">
                <a class="menu-item" @click="$emit('startBreake', breakModel.minutes)">
                    <img src="../../../../assets/icons/components/break-time-icon.svg" class="menu-icon" alt="Rozpocznij przerwę" />
                    <span class="menu-text">Rozpocznij przerwę</span>
                </a>
            </div>
        </section>
    </section>
</template>
<script charset="utf-8">
export default {
        name: "SettingCustomBreak",
        components: {},
        props: {},
        data() {
            return {
                breakModel: {
                    minutes: 3,
                    endDatetime: {}
                }
            }
        },
        emits: ['startBreake'],

        mounted(){
            this.changeMinutes();
        },
        methods: {
            changeMinutes() {
                var d = new Date();
                var utcDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours() + 4, d.getUTCMinutes(), d.getUTCSeconds(), d.getUTCMilliseconds());
                this.breakModel.endDatetime = new Date(utcDate.getTime() + this.breakModel.minutes * 60000).toISOString().slice(0, 16);
            },
            changeEndDate() {
                var d = new Date();
                var utcDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours() + 2, d.getUTCMinutes(), d.getUTCSeconds(), d.getUTCMilliseconds());
                let date = new Date(this.breakModel.endDatetime);
                let diff = Math.abs(date - utcDate);
                this.breakModel.minutes = Math.floor((diff / 1000) / 60);
            }
        }
}
</script>
<style lang="scss" scoped>
    .custom-break-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        overflow: hidden;
        z-index: 9;

        .custom-break-container {
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-height: 100vh;
            padding-bottom: 0px;
            padding-right: 0px;
            min-width: 50vh;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            row-gap: 15px;

            &__menu {
                width: 250px;
                padding-bottom: 20px;
                margin: 0 auto;

                .menu-item {
                    display: flex;
                    column-gap: 10px;
                    align-items: center;
                    //float: left;
                    font-size: 16px;
                    color: #000;
                    //text-align: center;
                    padding: 19px 19px 19px 19px;
                    text-decoration: none;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    border-radius: 5px;
                    border: 1px solid #bbc2ce;
                    background-color: white;
                    justify-content: center;

                    .menu-icon {
                        height: 20px;
                    }

                    .menu-text {
                        font-weight: 500;
                        font-size: 16px;
                        //line-height: 132%;
                    }

                    &:hover {
                        background-color: #e8ebef;
                    }
                }
            }

            &__header {
                justify-content: space-between;
                align-items: center;
                padding: 10px;

                &__action {
                    &__exit-btn {
                        border: 0px;
                        position: relative;
                        right: calc(-100% + 48px);
                        background-color: transparent;
                    }
                }
            }

            &__title {
                display: flex;
                justify-content: center;
                font-size: 25px;
            }

            &__body {
                max-height: 65vh;
                overflow-y: auto;
                padding: 0px 50px 20px 25px;

                &[type="date"]::-webkit-calendar-picker-indicator {
                    color: rgba(0, 0, 0, 0);
                    opacity: 1;
                    display: block;
                    background: url("../../../../assets/icons/components/callendar.svg") no-repeat;
                    background-position-x: 100%;
                    width: 22px;
                    height: 22px;
                    border-width: thin;
                }

                &[type="time"]::-webkit-calendar-picker-indicator {
                    color: rgba(0, 0, 0, 0);
                    opacity: 1;
                    display: block;
                    background: url("../../../../assets/icons/components/clock.svg") no-repeat;
                    width: 22px;
                    height: 22px;
                    border-width: thin;
                }

                div {
                    margin-top: 10px;
                }
            }
        }
    }
</style>