<template>
    <section class="esesja-modal send-meeting-regarding-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">e-Mail Komunikator</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$store.commit('setSendMeetingRegardingModalStatus', false);}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">

                <UserAccountEmailSettingsInfo/>

                <label class="esesja-modal__wraper__body__label">Adresaci</label>
                <section class="esesja-modal__row esesja-modal__row__as-column">
                    <Multiselect ref="searchUsers"
                                 v-model="formModel.selectedUsers"
                                 mode="tags"
                                 placeholder="Wyszukaj..."
                                 :close-on-select="false"
                                 :searchable="true"
                                 class="esesja-multiselect"
                                 noResultsText="brak wyników"
                                 noOptionsText="brak opcji"
                                 :groups="true"
                                 :options="availableUsers"
                                 :searchFilter="customSearchFilter"
                                 @select="selectedOptions" />
                    <button class="esesja-modal__wraper__body__bottom-button" @click="()=> { showAddCustomRegardingUser = true }">Dodaj adresata</button>
                </section>

                <!--<label class="esesja-modal__wraper__body__label ">E-Mail adresata spoza listy</label>
            <section class="esesja-modal__row esesja-modal__row__as-column">
                <input type="email" class="esesja-modal__wraper__body__input" placecholder="email@email.pl" v-model="customUserRecipent" @keyup.enter="addCustomRecipent" v-error="{ errors, input_key: 'customUserRecipent' }" />

            </section>-->
                <ErrorMessage v-if="isError('customUserRecipent', this.errors)" :errorMsgs="passErrorMsg('customUserRecipent', this.errors)" />
                <label class="esesja-modal__wraper__body__label">Temat</label>
                <section class="esesja-modal__row">
                    <input class="esesja-modal__wraper__body__input" placecholder="Tenat" v-model="formModel.subject" />
                </section>
                <label class="esesja-modal__wraper__body__label">Wiadomość</label>
                <section class="esesja-modal__row">
                    <textarea class="esesja-modal__wraper__body__input" placecholder="Treść wiadomości" rows="8" v-model="formModel.email"></textarea>
                </section>
                <label class="esesja-modal__wraper__body__label">Załączniki</label>
                <section class="esesja-modal__row">
                    <input type="file" class="esesja-modal__wraper__body__input esesja-modal__wraper__body__input__file" @change="filePreview" multiple />
                </section>
                <section class="esesja-modal__row esesja-modal__row--to-right" v-if="attachedFiles.length > 0">
                    <section v-if="showErrorSum(currentAttachemntFileLength)" style="color: red;">Przekroczono limit wagi załączników: {{ byteConverter(currentAttachemntFileLength, 2)}} / 30 MB</section>
                    <section v-else>{{ byteConverter(currentAttachemntFileLength, 2)}} / 30 MB</section>
                </section>
                <section class="esesja-modal__row">
                    <section class="attached-file-wrapper">
                        <section v-for="(item, index) in attachedFiles" :key="index"
                                 class="attached-file-wrapper__item">
                            <section class="attached-file-wrapper__item__filename">
                                {{item.name}}
                            </section>
                            <section class="attached-file-wrapper__item__remove" @click="removeAttachedFile(index)">
                                <img src="@/assets/icons/components/exit-btn.svg" width="15" />
                            </section>
                        </section>
                    </section>
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="() => { $store.commit('setSendMeetingRegardingModalStatus', false); }"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="sendMeetingRegarding" :disabled="showErrorSum(currentAttachemntFileLength)">Wyślij</button>
                </section>
            </perfect-scrollbar>
        </section>

        <Transition>
            <AddCutomMeetingRegardingUser v-if="showAddCustomRegardingUser"
                                          @close="()=> { showAddCustomRegardingUser = false }"
                                          @save="addCustomRecipent"/>
        </Transition>
    </section>
</template>
<script charset="utf-8">
    // services
    import AlertHelper from "../../../helpers/alerts.helper";
    import UserAccountEmailSettingsInfo from "../../Shared/Users/UserAccountEmailSettingsInfo.vue";
    import ContactService from "../../../services/contact.service";
    import SessionService from "../../../services/session.service";
    import moment from "moment";
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "../../../formValidation/main";


    // Components
    import Multiselect from '@vueform/multiselect'
    import ErrorMessage from "../../BasicControls/ErrorMessage.vue";
    import AddCutomMeetingRegardingUser from "./AddCutomMeetingRegardingUser.vue";

    export default {
        name: "SendMeetingRegarding",
        components: {
            Multiselect,
            ErrorMessage,
            AddCutomMeetingRegardingUser,
            UserAccountEmailSettingsInfo
        },
        data() {
            return {
                attachedFiles: [],
                formModel: {
                    subject: "",
                    email: "",
                    selectedUsers: [],
                },
                availableUsers: [],
                temporaryGroupUid: "",
                customUserRecipent: "",
                sessionData: {
                    name: "",
                    meeting_date: "",
                    groups: [],
                },
                sessionDataLink: '',
                errors: [],
                showAddCustomRegardingUser: false,
            }
        },
        computed: {
            userFullName() {
                if (this.$store.state.userStore.isLogged) {
                    return `${this.$store.state.userStore.userData.first_name} ${this.$store.state.userStore.userData.last_name}`;
                }
                return null;
            },
            currentAttachemntFileLength() {
                let localCopy = [...this.attachedFiles];

                return localCopy.reduce((accumulator, object) => {
                    return accumulator + object.size;
                }, 0);
            },
        },
        async mounted() {
            try {
                this.$store.commit("showLoader");
                await this.internalFetchAvailableUsers();
                await this.internalFetchSessionData();
                await this.internalFetchSessionDataLink();

                let sessionName = ""
                if (this.sessionData.name !== "") {
                    sessionName = this.sessionData.name
                }
                else {
                    sessionName = this.sessionData.groups[0].name
                }
                this.formModel.subject += `${sessionName} w dniu ${this.humanDate(this.sessionData.meeting_date)}`

                this.formModel.email = `Szanowni Państwo,\r\nSesja (${sessionName}) odbędzie się w dniu ${this.humanDate(this.sessionData.meeting_date)} o godzinie ${this.humanTime(this.sessionData.meeting_date)}.\r\nMateriały dotyczące posiedzenia są już dostępne w systemie eSesja oraz pod następującym adresem:\r\n${this.sessionDataLink}\r\nZ poważaniem\r\n${this.userFullName}`;

            }
            finally {
                this.$store.commit("hideLoader");
            }
        },
        methods: {
            customSearchFilter(option, query) {
                let localQuery = query.toUpperCase();
                let localcopy = [...this.availableUsers];
                // check if any groups meets criteria
                let filterGroup = localcopy.filter(item => {
                    let copyGroupLabel = item.label.toUpperCase()
                    return copyGroupLabel.includes(localQuery);
                })

                if (filterGroup.length > 0) {
                    let isOptionIsInGroup = filterGroup.filter(item => item.id === option.groupId);
                    return isOptionIsInGroup.length > 0;
                }
                return option.label.toUpperCase().includes(localQuery);
            },
            selectedOptions() {
                this.$refs.searchUsers.clearSearch();
            },
            async internalFetchAvailableUsers() {
                try {
                    let result = await ContactService.getUsersToSmsOrEmail(2);
                    if (result.status !== 200) {
                        throw await Promise.reject(result);
                    }
                    this.temporaryGroupUid = this.uuidv4();
                    this.availableUsers = [ ... result.data]
                    this.availableUsers.push({
                        id: this.temporaryGroupId(this.temporaryGroupUid),
                        label: "Grupa tymczasowa",
                        options: []
                    });

                    this.availableUsers = this.availableUsers.map(item => ({
                        id: item.id,
                        label: item.label,
                        options: item.options.map(opt => ({
                            value: opt.value,
                            label: opt.label,
                            groupId: item.id
                        }))
                    }));
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },

            async internalFetchSessionData() {
                try {
                    let result = await SessionService.getSession(this.$store.state.modals.sessions.sendMeetingRegardingModalSessionId)

                    if (result.status !== 200) {
                        throw await Promise.reject(result);
                    }
                    this.sessionData = result.data;
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },

            async internalFetchSessionDataLink() {
                try {
                    let result = await SessionService.getPublicSessionInfoLink(this.$store.state.modals.sessions.sendMeetingRegardingModalSessionId)

                    if (result.status !== 200) {
                        throw await Promise.reject(result);
                    }
                    this.sessionDataLink = result.data.link;
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },

            async sendMeetingRegarding() {
                try {
                    this.$store.commit("showLoader");
                    let requestData = new FormData();
                    for (let file of this.attachedFiles) {
                        requestData.append("attachments[]", file);
                    }

                    requestData.append("subject", this.formModel.subject);
                    requestData.append("email", this.formModel.email);
                    let localCopy = [...this.formModel.selectedUsers];
                    let selectedUser = localCopy.filter(item => {
                        return Number.isInteger(item);
                    });
                    

                    for (let el of selectedUser) {
                        requestData.append("selectedUsers[]", el);
                    }
                    let customUserUid = localCopy.filter(item => {
                        return !Number.isInteger(item);
                    });

                    let customGroupIndex = this.getCustomGroupIndex();
                    let customUserEmails = this.availableUsers[customGroupIndex].options.
                        filter(item => {
                            return customUserUid.includes(item.value)
                        })
                        .map(item => item.label);

                    for (let el of customUserEmails) {
                        requestData.append("customUser[]", el);

                    }

                    let response = await SessionService.sendMeetingRegarding(requestData);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.$store.commit('setSendMeetingRegardingModalStatus', false);
                    this.$swal(AlertHelper.successAlert("Wiadomość została poprawnie wysłana"));
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            validateEmail(email) {

                var bo = true;
                var err = {};

                if (email.length == 0) {
                    err.customUserRecipent = ['Nie można dodać pustego adresu email']
                    bo = false
                }

                if (email.length > 0) {
                    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) == false) {
                        err.customUserRecipent = ['Niepoprawny format email lub dodano więcej niż jeden adres email']
                        bo = false
                    }
                }

                if (!bo) {
                    this.isLoading = false;
                    this.errors = setErrors(err)
                }
                return bo
            },
            addCustomRecipent(data) {
                try {
                    let copy = {...data}
                    let index = this.getCustomGroupIndex();
                    let customUserUid = this.uuidv4();
                    this.availableUsers[index].options.push({
                        value: customUserUid,
                        label: copy.email
                    });

                    this.formModel.selectedUsers.push(customUserUid);
                }
                catch (e) {
                    console.log(e);
                }
                finally{
                    this.showAddCustomRegardingUser = false;
                }
            },
            getCustomGroupIndex() {
                let localCopy = [...this.availableUsers];
                let mapedCopy = [...localCopy.map(item => item.id)];
                let index = mapedCopy.indexOf(this.temporaryGroupId(this.temporaryGroupUid));

                if (index === -1) {
                    throw new Error("Nie znalezione grupy");
                }
                return index;
            },
            filePreview(e) {
                try {
                    this.$store.commit("showLoader");
                    e.preventDefault();
                    for (const file of e.target.files) {
                        this.attachedFiles.push(file);
                    }
                }
                finally {
                    e.target.value = null;
                    this.$store.commit("hideLoader");
                }
            },
            removeAttachedFile(requestIndex) {
                try {
                    this.attachedFiles = this.attachedFiles.filter((item, index) => {
                        return index !== requestIndex;
                    })
                }
                catch (e) {
                    console.log(e);
                }
            },
            temporaryGroupId(id) {
                return `temporary: ${id}`;
            },
            uuidv4() {
                return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            },
            humanDate(date) {
                return moment(date).format("DD MMMM YYYY");
            },
            humanTime(date) {
                return moment(date).format("HH:mm");
            },
            byteConverter(bytes, decimals, only) {
                const K_UNIT = 1024;
                const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

                if (bytes == 0) return "0 Byte";

                if (only === "MB") return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";

                let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
                let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];

                return resp;
            },
            showErrorSum(bytes) {
                if (bytes > 31457280) {
                        return true;
                }

                return false;
            },
            passErrorMsg,
            setErrors,
            isError,

        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';

    .send-meeting-regarding-modal {
        .esesja-modal__wraper {
            max-height: 90vh;
            min-width: 55vw;
        }

        .attached-file-wrapper {
            display: flex;
            flex-direction: row;
            gap: 10px;
            flex-wrap: wrap;

            &__item {
                display: flex;
                background: #e8ecf2;
                border-radius: 20px;
                padding: 10px 20px;
                flex-direction: row;
                align-content: center;
                align-items: center;
                gap: 5px;
                border: 1px solid transparent;
                transition: all 0.2s ease-in-out;


                &__remove {
                    opacity: 0;
                    transition: all 0.2s ease-in-out;
                }

                &:hover {
                    border-color: #bbc2ce;

                    .attached-file-wrapper__item__remove {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }

        .esesja-modal__row--to-right{
            justify-content: flex-end;
            align-items: flex-end;
        } 
        .esesja-modal__wraper__body__input__file {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            padding-left: 0px !important;


            &::file-selector-button {
                background: #fff;
                padding: 12.5px 15px;
                border: none;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    background: #e8ebef;
                }
            }
        }

        .esesja-modal__row__as-column {
            flex-direction: row;
            column-gap: 10px;

            .esesja-modal__wraper__body__bottom-button {
                padding: 18px 15px 17px;
            }
        }
    }
   
</style>