<template>
    <section class="contacts">
        <section class="contacts__head">
            <h1 class="contacts__head__title">Wiadomości SMS</h1>
            <section class="contact-top__action-buttons">
                <ButtonWithIcon icon="plus-small" style=""
                                text="Wyślij SMS"
                                :classModified="true"
                                @click="(e) => sendSmsToUsers()" />
            </section>
        </section>
        <section class="contacts__statistics">
            <GenericStatisticsBoxVue :label="'Wysłano dzisiaj'"
                                     :value="smsStatistics.today"
                                     :icon="'send_sms'" />
            <GenericStatisticsBoxVue :label="'Wysłano w tym miesiącu'"
                                     :value="smsStatistics.thisMonth"
                                     :icon="'send_sms'" />
            <GenericStatisticsBoxVue :label="'Wysłano w poprzednim miesiącu'"
                                     :value="smsStatistics.lastMonth"
                                     :icon="'send_sms'" />
            <GenericStatisticsBoxVue :label="'Wysłano w tym roku'"
                                     :value="smsStatistics.thisYear"
                                     :icon="'send_sms'" />
            <GenericStatisticsBoxVue :label="'Dostępne kredyty'"
                                     :value="smsStatistics.available"
                                     :icon="'send_sms'" />
        </section>
        <section class="contact-top">
            <section class="contact-top__search">
                <form class="contact-top__form">
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Wyszukaj wiadomość'" />
                        <SearchInputTextComponent v-model="internalRequestQuery.searchText"
                                                  :placeholder="'Wyszukaj wiadomość...'" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data wysłania od'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateFrom" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data wysłania do'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateTo" />
                    </section>
                    <section class="contact-top__form__item" style="margin-left: auto">
                        <SearchLabelComponent :label="'Wyników na stronie'" />
                        <ItemsPerPage v-model="itemsPerPageModel" />
                    </section>
                </form>
            </section>
        </section>

        <Pagination :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />

        

        <section class="contacts__list" v-if="sms.length > 0">
            <section class="contact-list">
                <section v-for="s in checkFilter" :key="s.id">
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section>
                                <span class="contact-box__half-hidden">{{formatDateToDisplay(s.time)}}</span>
                                <span class="contact-box__title">Do <b>{{s.to}}</b> {{ s.phoneNumber!= null && s.phoneNumber.length > 0 ? '('+s.phoneNumber+')' : ''}}</span>
                                <span class="contact-box__half-hidden">Od {{ s.from }}</span>
                            </section>
                            <section class="contact-box__right contact-box__right__waiting" v-if="s.status === 409">
                                <!-- waiting -->
                                <img src="../../../../../assets/icons/components/hourglass.svg" width="20" height="20" />
                                {{prepareStatus(s.status)}}
                            </section>
                            <section class="contact-box__right contact-box__right__waiting" v-else-if="s.status === 403">
                                <!-- send -->
                                <img src="../../../../../assets/icons/components/paper-plane.svg" width="20" height="20" />
                                {{prepareStatus(s.status)}}
                            </section>
                            <section class="contact-box__right contact-box__right__error" v-else-if="s.status === 405">
                                <!-- not deliver -->
                                <img src="../../../../../assets/icons/components/triangle-exclamation.svg" width="20" height="20" />
                                {{prepareStatus(s.status)}}
                            </section>
                            <section class="contact-box__right contact-box__right__success" v-else-if="s.status === 404">
                                <!-- deliver -->
                                <img src="../../../../../assets/icons/components/inbox-in.svg" width="20" height="20" />
                                {{prepareStatus(s.status)}}
                            </section>
                            <section class="contact-box__right contact-box__right__error" v-else >
                                <!-- not known -->
                                <img src="../../../../../assets/icons/components/triangle-exclamation.svg" width="20" height="20" />
                                {{prepareStatus(s.status)}}
                            </section>
                        </section>
                        <section style="padding: 10px" class="contact-box__people contact-box__people--open">
                            <section v-if="s.text.length > 0">
                                <section class="contact-box__people-container">
                                    <p style="margin-top: 5px; word-wrap: break-word;">{{s.text}}</p>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section class="contacts__list" v-else>
            <section class="contact-list">
                <section>
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section>
                                <span class="contact-box__title">Brak SMS spełniających podane kryteria</span>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <Pagination :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />
        <Transition>
            <SMSModal v-if="showSMSModal" @close="closeSMSModal" :options="smsOptions" :selected="selected" />
        </Transition>
    </section>
</template>

<script charset="utf-8">
    // components
    import GenericStatisticsBoxVue from "../../../../BasicControls/GenericStatisticsBox.vue";
    import SearchLabelComponent from "../../../../BasicControls/SearchLabelComponent.vue";
    import SearchInputTextComponent from "../../../../BasicControls/SearchInputTextComponent.vue";
    import SearchInputDateComponent from "../../../../BasicControls/SearchInputDateComponent.vue";
    import Pagination from "../../../../BasicControls/Pagination.vue";
    import SMSModal from "../../Home/Modals/SendSMS.vue";
    import ButtonWithIcon from "../../../../BasicControls/ButtonWithIcon.vue";
    import ItemsPerPage from "../../../../BasicControls/ItemsPerPage.vue"

    // services
    import { encryptStorage } from '../../../../../services/encrypt.service';
    import AlertHelper from '@/helpers/alerts.helper.js';
    import CommonHelper from "../../../../../helpers/common.helper";
    import CommunicationService from "../../../../../services/communication.service";
    import ContactService from "../../../../../services/contact.service";
    import { debounce } from "../../../../../helpers/utils";

    export default {
        name: "sms",
        data() {
            return {
                test: "",
                searchBar: "",
                sms: [],
                paginatedData: {
                    currentPage: -1,
                    itemsPerPage: 50,
                    totalItems: -1,
                },
                page: 1,
                showSMSModal: false,
                selected: [],
                smsOptions: [],
                smsmodule: encryptStorage.getItem('modules').s,


                itemsPerPageModel: {
                    label: "",
                    value: null,
                },

                internalRequestQuery: {
                    searchText: null,
                    searchStatus: null,
                    dateFrom: null,
                    dateTo: null,
                    page: 1,
                    itemsPerPage: 50,
                },

                smsStatistics: {
                    today: 0,
                    thisMonth: 0,
                    lastMonth: 0,
                    thisYear: 0,
                    available: 0,
                },
                isLoading: {
                    data: false,
                    statistics: false,
                }
            };
        },
        watch: {
            isLoading: {
                deep: true,
                handler: function (value) {
                    if (value.data === false && value.statistics === false) {
                        this.$store.commit("hideLoader");
                    }
                    else {
                        this.$store.commit("showLoader");
                    }
                }
            },
            itemsPerPageModel: {
                deep: true,
                handler: function (val) {
                    if (val.value === null) {
                        return;
                    }
                    if (val.value !== this.internalRequestQuery.itemsPerPage) {
                        this.internalRequestQuery.itemsPerPage = val.value;
                    }
                    
                }
            },
            internalRequestQuery: {
                deep: true,
                handler: debounce(function (newValue) {
                    let queryParam = CommonHelper.getNonNullObjectEntries(newValue);
                    this.$router.replace({ name: "sms", query: queryParam });
                    //this.fetchSmsData();
                    //this.fetchSessions(queryParam);
                }, 150)
            },
            $route() {
                if (this.$route.name === "sms" && Object.keys(this.$route.query).length > 0) {
                    this.queryParamsToRequestSearch();
                    this.fetchSmsData();
                }

            },

            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },
        computed: {
            checkFilter() {
                return this.sms.filter((item) => {
                    var elem = String(item.from + " " + item.to + " " + this.formatDateToDisplay(item.time))
                    return elem.toLowerCase().includes(this.searchBar.toLowerCase());
                });
            },
            requestQuery() {
                return this.$route.query;
            },
            isAnyModalOpen(){
                return this.showSMSModal
            }
        },
        async mounted() {
            if (this.smsmodule !== 1) {
                this.$swal(AlertHelper.errorAlert("Brak wykupionego modułu sms."));
                return;
            }
            this.fetchSmsStatistics();

            if (CommonHelper.isObjectIsEmpty(this.requestQuery)) {
                this.setDefaultRequestQuery();
            }
            else {
                this.queryParamsToRequestSearch();
                await this.fetchSmsData();
            }

            
        },
        methods: {

            async fetchSmsData() {
                try {
                    this.isLoading.data = true;
                    let requestData = this.internalRequestDataToAsyncRequestData();
                    let response = await CommunicationService.searchSms(requestData);
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }

                    if (response.data.items != null)
                        this.sms = response.data.items;

                    if (response.data.paginated != null)
                        this.paginatedData = response.data.paginated;
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading.data = false;
                }
            },

            async fetchSmsStatistics() {
                try {
                    this.isLoading.statistics = true;

                    let response = await CommunicationService.getSmsStatistics();

                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }

                    this.smsStatistics = response.data;

                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading.statistics = false;
                }
            },

            internalRequestDataToAsyncRequestData() {
                return {
                    data: {
                        searchText: this.internalRequestQuery.searchText,
                        searchStatus: this.internalRequestQuery.searchStatu,
                        dateFrom: this.internalRequestQuery.dateFrom,
                        dateTo: this.internalRequestQuery.dateTo,
                    },
                    paginated: {
                        page: this.internalRequestQuery.page,
                        itemsPerPage: this.internalRequestQuery.itemsPerPage,
                    }
                }
            },

            setDefaultRequestQuery() {
                this.clearRequestData();
                let queryParam = CommonHelper.getNonNullObjectEntries(this.internalRequestQuery);
                this.$router.replace({ name: "sms", query: queryParam });
            },

            changePage(page) {
                this.internalRequestQuery.page = page;
            },
            clearRequestData() {
                this.internalRequestQuery.searchText = null;
                this.internalRequestQuery.searchStatus = null;
                this.internalRequestQuery.dateFrom = null;
                this.internalRequestQuery.dateTo = null;
                this.internalRequestQuery.page = 1;
                this.internalRequestQuery.itemsPerPage = 50;
            },
            queryParamsToRequestSearch() {

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "searchText")) {
                    this.internalRequestQuery.searchText = this.requestQuery.searchText;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateFrom")) {
                    this.internalRequestQuery.dateFrom = this.requestQuery.dateFrom;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateTo")) {
                    this.internalRequestQuery.dateTo = this.requestQuery.dateTo;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "page")) {
                    this.internalRequestQuery.page = this.requestQuery.page;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "itemsPerPage")) {
                    this.internalRequestQuery.itemsPerPage = this.requestQuery.itemsPerPage;
                    this.itemsPerPageModel.value = this.internalRequestQuery.itemsPerPage;
                    this.itemsPerPageModel.label = `${this.itemsPerPageModel.value}`;
                }


                if (CommonHelper.isObjectHasProperty(this.requestQuery, "_r")) {
                    this.clearRequestData();
                }
            },

            closeSMSModal() {
                this.showSMSModal = false;
            },
            async sendSmsToUsers() {

                try {
                    if (this.smsmodule !== 1) {
                        this.$swal(AlertHelper.errorAlert("Brak wykupionego modułu sms."));
                        return;
                    }
                    this.$store.commit('showLoader');

                    let availableCreditsResponse = await CommunicationService.getSmsCredits();
                    if(availableCreditsResponse.status !== 200){
                        throw await Promise.reject(availableCreditsResponse);
                    }

                    if(availableCreditsResponse.data === 0){
                        this.$swal(AlertHelper.errorAlert("Brak dostępnych kredytów SMS."));
                        return;
                    }


                    let response = await ContactService.getUsersToSmsOrEmail(1);
                    if(response.status !== 200){
                        throw await Promise.reject(response);
                    }
                    var result = response.data;
                    if(Object.keys(result).length === 0){
                        this.$swal(AlertHelper.errorAlert("Żaden z zapisanych kontaktów nie posiada przypisanego numeru telefonu. Wysyłka wiadomości sms jest niemożliwa."));
                        return;
                    }

                    this.smsOptions = result;
                    this.showSMSModal = true;

                } catch (e) {
                    if (e.response.status == 401){
                        this.$router.push({ name: 'Login' })
                    }
                    else{
                        this.$swal(AlertHelper.errorAlert("Wystąpił błąd"));
                    }
                }
                finally{
                    this.$store.commit('hideLoader');
                }
                
            },
            prepareStatus(status) {
                var str = "";
                switch (status) {
                    case 409:
                        str = "oczekuje na wysłanie"
                        break;
                    case 403:
                        str = "wysłano"
                        break;
                    case 404:
                        str = "dostarczona"
                        break;
                    case 405:
                        str = "niedostarczona"
                        break;
                    default:
                        str = "status nieznany"
                        break;
                }

                return str;
            },
            openMessage(id) {
                for (var i = 0; i < this.sms.length; i++) {
                    if (this.sms[i].id == id)
                        if (this.sms[i].show == false)
                            this.sms[i].show = true;
                        else
                            this.sms[i].show = false;
                }
            },
            formatDateToDisplay(value) {

                if (value == null)
                    return '';

                var date = new Date(value)
                

                return `${date.getFullYear()}-${(date.getMonth() +1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
            },
            async nextPage() {
                if (this.smsmodule == 1) {
                    this.page += 1;
                    try {
                        this.sms = [];
                        this.$store.commit("showLoader");
                        this.sms = (await CommunicationService.getSms(this.page)).data;
                        for (var i = 0; i < this.sms.length; i++) {
                            this.sms[i].show = false;
                        }
                        this.$store.commit("hideLoader");
                    } catch (e) {
                        if (e.response.status == 401)
                            this.$router.push({ name: 'Login' })
                    }
                } else {
                    this.$swal(AlertHelper.errorAlert("Brak wykupionego modułu sms."));
                }
            },
            async previousPage() {
                if (this.smsmodule == 1) {
                    if (this.page > 1) {
                        this.page -= 1;
                        try {
                            this.sms = [];
                            this.$store.commit("showLoader");
                            this.sms = (await CommunicationService.getSms(this.page)).data;
                            for (var i = 0; i < this.sms.length; i++) {
                                this.sms[i].show = false;
                            }
                            this.$store.commit("hideLoader");
                        } catch (e) {
                            if (e.response.status == 401)
                                this.$router.push({ name: 'Login' })
                        }
                    }
                } else {
                    this.$swal(AlertHelper.errorAlert("Brak wykupionego modułu sms."));
                }
            }
        },
        components: {
            ButtonWithIcon,
            SMSModal,
            Pagination,
            SearchInputTextComponent,
            SearchInputDateComponent,
            SearchLabelComponent,
            GenericStatisticsBoxVue,
            ItemsPerPage
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/search-component.scss";


    .contact-box__people--open {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .contact-box__people-container {
        padding:0px;
        background:none;
        border-radius: 12px;
        margin: 0 16px
    }

    .contact-box__top {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #dce4ee;
        cursor: pointer;
        justify-content: space-between
    }
</style>
