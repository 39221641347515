<template>
    <eSesjaModal>
        <eSesjaModalHeader @close="$store.commit('setRecorderSelectSessionModalStatus', false)">
            Posiedzenia
        </eSesjaModalHeader>
        <eSesjaModalBody>

            <label class="esesja-modal__wraper__body__label">Wybierz posiedzenie</label>
            <section class="esesja-modal__row">
                <select class="esesja-modal__wraper__body__input" v-model="selectedSession">
                    <option v-for="(item, index) in allSessiosn" :key="index" :value="item">[{{item.id}}] {{item.name != null ? item.name : 'Sesja '}} z dnia {{formatDateToDisplay(item.meeting_date)}}</option>
                </select>
            </section>
        </eSesjaModalBody>
        <eSesjaModalFooter>
            <eSesjaModalButtonCancel @close="$store.commit('setRecorderSelectSessionModalStatus', false)">
                Anuluj
            </eSesjaModalButtonCancel>
            <eSesjaModalButtonSave @click="proceed">
                Wybierz
            </eSesjaModalButtonSave>
        </eSesjaModalFooter>
    </eSesjaModal>
</template>
<script charset="utf-8">
    export default {
        name: "SelectSessionModal",
        emits: ["selectedSession"],
        props: {
            currentSessionId: {
                type: Number,
                default: -1,
            }
        },
        computed: {
            allSessiosn() {
                return this.$store.state.appStore.groups
                    .map(item => item.sessions)
                    .flat()
            }
        },
        mounted() {
            try {
                let filtered = this.allSessiosn.filter(item => item.id === this.currentSessionId);

                if (filtered.length !== 1) {
                    return;
                } 

                this.selectedSession = filtered[0];
            }
            catch {
                console.log("err");
            }
        },
        data() {
            return {
                selectedSession: {},
            } 
        },
        methods: {
            proceed() {
                this.$emit('selectedSession', this.selectedSession);
                this.$store.commit('setRecorderSelectSessionModalStatus', false);
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                });

                return d;
            },
        }

    }
</script>