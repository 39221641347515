import AdditonalMenuMeetings from "../components/Panel/NavMenu/AdditionalMenuMeetings.vue";
import AdditonalMenuRegister from "../components/Panel/NavMenu/AdditionalMenuRegister.vue";
import AdditionalMenuMeetingTheme from "../components/Panel/NavMenu/AdditionalMenuMeetingTheme.vue";

export const NavLinksData = [
    {
        id: 0,
        name: "Zarządzanie",
        abbr: "zarzadzanie",
        accesLevel: 0,
        links: [
            {
                id: 0,
                name: "Panel główny",
                abbr: "panel-glowny",
                link: "/home",
                routerName: "home",
                icon_src: "home.svg",
            },
            {
                id: 10,
                name: "Posiedzenia",
                abbr: "posiedzenia",
                icon_src: "posiedzenia.svg",
                additionalMenu: AdditonalMenuMeetings,
                link: "/sessions/search?page=1&itemsPerPage=10",
                routerName: "sessionSearchRoute"
            },
            {
                id: 20,
                name: "Szablony posiedzeń",
                abbr: "szablony-posiedzen",
                icon_src: "szablon-posiedzenia.svg",
                additionalMenu: AdditionalMenuMeetingTheme,
            },
            {
                id: 30,
                name: "Kontakty",
                abbr: "kontakty",
                link: "/kontakty",
                routerName: "contact",
                icon_src: "users.svg",
            },
            {
                id: 35,
                name: "Kalendarz",
                abbr: "calendar",
                link: "/calendar",
                routerName: "app-new-calendar",
                icon_src: "calendar-days.svg",
            },
            {
                id: 40,
                name: "Rejestr",
                abbr: "rejestr",
                link: "/rejestr",
                icon_src: "rejestr.svg",
                additionalMenu: AdditonalMenuRegister,
            },
            {
                id: 50,
                name: "Interpelacje i zapytania",
                abbr: "interpelacje-i-zapytania",
                link: "/interpellations",
                routerName: "interpellations",
                icon_src: "message.svg",
            },
        ],
    },
    {
        id: 1,
        name: "Komunikacja",
        abbr: "komunikacja",
        accesLevel: 0,
        links: [
            {
                id: 0,
                name: "Informator",
                abbr: "komunikaty",
                link: "/announcements",
                routerName: "announcement",
                icon_src: "contact.svg",
            },
            {
                id: 10,
                name: "Aktualności",
                abbr: "aktualnosci",
                link: "/news",
                routerName: "news",
                icon_src: "news.svg",
            },
            {
                id: 20,
                name: "Wiadomości SMS",
                abbr: "wiadomosci-sms",
                link: "/sms",
                routerName: "sms",
                icon_src: "message-sms.svg",
            },
            {
                id: 30,
                name: "Wiadomości e-mail",
                abbr: "wiadomosci-e-mail",
                link: "/email",
                routerName: "email",
                icon_src: "envelope.svg",
            },
        ],
    },
    {
        id: 2,
        name: "Narzędzia",
        abbr: "narzedzia",
        accesLevel: 0,
        links: [
            {
                id: 0,
                name: "Repozytorium dokumentów",
                abbr: "repozytorium-dokumentow",
                link: "/dokumenty",
                routerName: "doc",
                icon_src: "directory.svg",
            },
            {
                id: 10,
                name: "Raporty obecności",
                abbr: "raporty-obecnosci",
                link: "/raport",
                routerName: "presence",
                icon_src: "circle-chart.svg",
            },
            {
                id: 20,
                name: "Aktywność użytkowników",
                abbr: "aktywnosc-uzytkownikow",
                link: "/aktywnosc",
                routerName: "activity",
                icon_src: "user_activiti.svg",
            },
        ],
    },
];
