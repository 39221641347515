<template>
    <div class="speechrequest-item" :class="{'participant-speech': requestUserItem.isSpeech}">
        <div class="speechrequest-item__user__avatar">
            <img :src="getUserAvata(requestUserItem.userId)" class="speechrequest-item__user__avatar__img" :alt="requestUserItem.name + ' ' + requestUserItem.surname" />
        </div>
        <div class="speechrequest-item__user__data">
            <div class="speechrequest-item__user__data__name">
                {{requestUserItem.name}} {{requestUserItem.surname}} {{getDisscussionType(requestUserItem)}}
                <img v-if="requestUserItem.isSpeech"
                     src="@/assets/icons/components/speaker.svg"
                     alt="Trwa wypowiedź"
                     class="user-speech-image" />
            </div>
            <div v-if="requestUserItem.isSpeech" class="speechrequest-item__user__data__speechTime">
                <div>Czas wypowiedzi: <p :style="isSpeakOutOfRange == true ? 'color: red' : ''"> {{displayTime}}</p> </div>
            </div>
            <div class="speechrequest-item__user__data__function">
                <button v-if="!requestUserItem.isSpeech"
                        class="
                        speechrequest-item__user__data__function__button
                        speechrequest-item__user__data__function__button__white
                        "
                        @click="() => { this.$emit('giveParticipantSpeech', requestUserItem); this.isLoading = false;}">
                    <img src="@/assets/icons/components/microphone.svg" alt="Udziel głosu" class="button-image" />
                    Udziel głosu
                </button>

                <button v-else
                        class="
                        speechrequest-item__user__data__function__button
                        speechrequest-item__user__data__function__button__red
                        "
                        @click="() => { $emit('endParticipantSpeech', requestUserItem); this.isLoading = false;}">
                    Zakończ wypowiedź
                </button>

                <button v-if="requestUserItem.isSpeech && requestUserItem.isCountdown"
                        class="
                        speechrequest-item__user__data__function__button
                        speechrequest-item__user__data__function__button__white
                        "
                        :disabled="isBusy"
                        @click="addSeconds(30)">
                    +30s
                </button>

                <button v-if="requestUserItem.isSpeech && requestUserItem.isCountdown"
                        class="
                        speechrequest-item__user__data__function__button
                        speechrequest-item__user__data__function__button__white
                        "
                        :disabled="isBusy"
                        @click="addSeconds(60)">
                    +1m
                </button>


                <button v-if="!requestUserItem.isSpeech"
                        class="
                        speechrequest-item__user__data__function__button
                        speechrequest-item__user__data__function__button__red
                        "
                       @click="$emit('removeFromRequestList', requestUserItem)">
                    <img src="../../../assets/icons/components/delete-point.svg" class="button-image" alt="Usuń" />
                    Usuń
                </button>
            </div>
        </div>
        <SmallLoader v-if="isLoading" :isTiny="true" />
    </div>
</template>
<script charset="utf-8">
    import dingSound from "../../../assets/sounds/ding.wav";
    import beep1 from "../../../assets/sounds/1beep.wav";
    import beep3 from "../../../assets/sounds/3beep.wav";
    import InteractiveService from "../../../services/interactive.service";
    import toolsService from "../../../services/tools.service";
    import AlertHelper from '@/helpers/alerts.helper.js';
    import SmallLoader from '../../BasicControls/SmallLoader.vue';
    import { getLeadZero, debounce, timeUnitsBetween } from '../../../helpers/utils';

    export default {
        name: "SpeechRequestItem",
        components: {
            SmallLoader
        },
        props: {
            requestUserItem: {
                type: Object,
                required: true,
                default() {
                    return {
                        uid: -1,
                        name: "",
                        surname: "",
                        role: "",
                        isSpeech: false,
                        startTime: ""
                    }
                }
            }
        },
        emits: [
            "giveParticipantSpeech",
            "removeFromRequestList",
            "endParticipantSpeech",
            "addTimeToSpeakerEmit",
        ],
        watch: {
            debounceFlag: debounce(function(){
                let internalDiff = null;
                let offset = timeUnitsBetween(this.fronStartSpeechTimestamp, this.currentServerTimestamp);
                let internalDate = new Date();
                internalDate.setHours(internalDate.getHours() + offset.hours);
                internalDate.setMinutes(internalDate.getMinutes() + offset.minutes);
                internalDate.setSeconds(internalDate.getSeconds() + offset.seconds);

                if(this.requestUserItem.isCountdown){
                    this.isSpeakOutOfRange = (this.estimatedEndTime -internalDate) < 0;
                    internalDiff = timeUnitsBetween(internalDate, this.estimatedEndTime);
                    
                }
                else{
                    internalDiff = timeUnitsBetween(this.startSpeechTimestamp, internalDate);
                }
                
                if(internalDiff !== null){
                    this.diff.days = internalDiff.days;
                    this.diff.hours = internalDiff.hours;
                    this.diff.minutes = internalDiff.minutes;
                    this.diff.seconds = internalDiff.seconds;

                    this.displayTime = `${getLeadZero(this.diff.hours + 24*this.diff.days)}:${getLeadZero(this.diff.minutes)}:${getLeadZero(this.diff.seconds)}`;
                }
                this.debounceFlag = !this.debounceFlag;
            }, 300),
            requestUserItem: {
                immediate: true,
                deep: true,
                async handler(val) {
                    if (val.isSpeech) {
                        this.startSpeechTimestamp = new Date(Date.parse(val.startTime));
                        this.fronStartSpeechTimestamp = new Date();
                        this.currentServerTimestamp = new Date((await toolsService.getServerTime()).data);
                        if (val.isCountdown) {
                            this.estimatedEndTime = this.startSpeechTimestamp.setSeconds(this.startSpeechTimestamp.getSeconds() + val.seconds)
                        }
                        this.debounceFlag = !this.debounceFlag;
                    }
                    else{
                        this.fronStartSpeechTimestamp = null;
                        this.currentServerTimestamp = null;
                    }
                }
            },
            diff:{
                deep: true,
                handler(val){

                    if (this.requestUserItem.isCountdown && !this.isSpeakOutOfRange){
                        if(val.hours === 0 && val.days === 0 && val.minutes == 0 && (val.seconds === 30 || val.seconds === 10)){
                            if (this.requestUserItem.gong){
                                this.getGongWarning();
                            }
                        }
                        else if(val.hours === 0 && val.days === 0 && val.minutes == 0 && val.seconds === 3){
                            if (this.requestUserItem.gong){
                                this.getGong3SecLeft();
                            }
                        }

                        else if(val.hours === 0 && val.days === 0 && val.minutes == 0 && val.seconds === 0){
                            if (this.requestUserItem.gong){
                                this.getGongEnd();
                            }

                            if (this.requestUserItem.turnOff) {
                                this.$emit('endParticipantSpeech', this.requestUserItem);
                            }
                        }
                    }

                }
            }
        },
        data() {
            return {
                speechTimer: null,
                isSpeakOutOfRange: false,
                isLoading: false,
                debounceFlag: false,
                fronStartSpeechTimestamp: null,
                currentServerTimestamp: null,
                startSpeechTimestamp: null,
                estimatedEndTime: null,
                displayTime: "00:00:00",
                diff: {
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                },
                isBusy: false,
            }
        },
        methods: {

            getDisscussionType(user) {
                if (user.type === undefined || user.type === null) {
                    return;
                }

                if (user.type.id === undefined || user.type === null) {
                    return;
                }

                let localTypeId = parseInt(user.type.id);

                switch (localTypeId) {
                    case 1:
                    case 2:
                        return "";
                    case 3:
                        return "(AV)";
                    case 4:
                        return "(WF)";
                    default:
                        return "";
                }
            },
            getUserAvata(uid) {
                return `https://ssl.esesja.pl/users/${uid}.jpg`;
            },
            getGongWarning() {
                var audio = new Audio(beep1);
                audio.play();
            },
            getGong3SecLeft() {
                var audio = new Audio(beep3);
                audio.play();
            },
            getGongEnd() {
                var audio = new Audio(dingSound);
                audio.play();
            },
            async addSeconds(seconds) {
                try {
                    this.isBusy = true;
                    var data = {
                        userId: this.requestUserItem.userId,
                        seconds: seconds
                    };

                    let response = await InteractiveService.addTimeForUserSpeaker(data);
                    if(response.status !== 200){
                        throw await Promise.reject(response);
                    }

                    this.estimatedEndTime = this.startSpeechTimestamp.setSeconds(this.startSpeechTimestamp.getSeconds() + seconds)

                    let internalDiff = timeUnitsBetween(new Date(Date.parse(this.requestUserItem.startTime)), this.estimatedEndTime);
                    const days = internalDiff.days * 24 * 60 * 60;
                    const hours = internalDiff.hours * 60 * 60;
                    const minutes = internalDiff.minutes * 60;
                    const updateTime = days + hours + minutes + internalDiff.seconds;
                    const dataT = {
                        item: this.requestUserItem,
                        time: updateTime
                    };
                    
                    this.$emit("addTimeToSpeakerEmit", dataT);
                    
                }
                catch (e) {
                    var t = this.$swal.mixin(AlertHelper.errorToastElement());
                    t.fire(AlertHelper.errorToastAlert('Błąd dodania czasu mówcy'));
                }
                finally{
                    this.isBusy = false;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>

    .speechrequest-item {
        position: relative;
        display: flex;
        flex-direction: row;
        border-width:5px;
        border-style: solid;
        border-color: transparent;
        &__user {
            &__avatar {
                &__img {
                    width: 60px;
                    height: 60px;
                    margin: 10px;
                    vertical-align: middle;
                    border-radius: 0%;
                }
            }

            &__data {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap:5px;
                justify-content: center;

                &__name {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 132%;
                    color: #00082c;
                    padding: 5px 0px;
                    white-space: pre-wrap;
                }

                &__function {
                    display: flex;
                    visibility: hidden;
                    flex-direction: row;
                    padding-bottom:5px;
                    &__button {
                        padding: 5px 10px;
                        margin-right: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                        border: 0px;
                        transition: all 0.2s ease-in-out;

                        .button-image {
                            height: 20px;
                        }

                        &__white {
                            background: #fff;
                            border: 1px solid #bbc2ce;

                            &:hover {
                                background: #e8ebef;
                            }
                        }


                        &__red {
                            background: #d80418;
                            color: #fff;

                            .button-image {
                                filter: brightness(0) invert(1);
                            }

                            &:hover {
                                background: #ad0313;
                            }
                        }
                    }
                }
            }
        }

        &:hover {
            background: #ddd;
        }

        &:hover .speechrequest-item__user__data__function {
            visibility: visible;
        }
    }
    .participant-speech {
        border-right: 4px solid red;
        border-bottom: 1px solid red;
        border-top: 1px solid red;

        .user-speech-image {
            height: 20px;
            animation: pulse 2s infinite;
        }

        @keyframes pulse {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }
    }
</style>