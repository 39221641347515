<template>
    <section class="voting">
        <div class="voting-form">
            
            <section class="voting-form__form-wrapper">
                <section class="voting-form__title" v-if="internalBasicData.isCheckPresence == true">
                    {{internalBasicData.name}}
                </section>
                <section class="voting-form__title" v-else>
                    Głosowanie z dnia {{formatDateToDisplay(new Date())}}: <b>{{internalBasicData.name}}</b>
                </section>
                <hr />
                <h2 v-if="internalBasicData.isCheckPresence == true" class="pulse-text" style="text-align: center; text-transform: uppercase">Trwa sprawdzanie kworum</h2>
                <h2 v-else class="pulse-text" style="text-align: center; text-transform: uppercase">Trwa głosowanie</h2>
                <perfect-scrollbar class="available-users">
                    <div class="user" v-for="(item, key) in internalBasicData.users" :key="key" :class="getVoteUserClass(item, $store.state.settings.client.interactiveModalVotingShowAvatar)">
                        <div class="user-avatar" v-if="$store.state.settings.client.interactiveModalVotingShowAvatar">
                            <img :src="getUserAvata(item.userId)" :alt="`${item.surname} ${item.name}`" />
                        </div>
                        <div class="user-data">
                            <span class="user-name">{{item.firstName}} {{item.lastName}}</span>
                        </div>
                    </div>
                </perfect-scrollbar>
                <hr />
                <div class="d-flex flex-row" style="align-items: center; justify-content: center; column-gap: 20px">
                    <h2>ZAGŁOSOWAŁO: {{votedUsers}} / {{ presentsUsers }}</h2>
                    <div class="results" v-if="internalBasicData.isPublic && !isHideResults">
                        <div class="uprawniony" :class="optionToClassName(s.option)" v-for="s in votingPossibleAnswersUpdate" :value="s.id" :key="s.id">{{s.option}}: <b>{{s.count}}</b></div>
                    </div>
                    <div class="results" v-else>
                        <div class="uprawniony" style="background-color: #aba9a2">Oddane głosy <b>{{hiddenVoteResult}}</b></div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
    import esesjaInteractivePublicService from "../../services/esesja.interactive.public.service";
    import signalrSocketAction from "../../services/esesja.signalr.action";
    import CommonHelper from '@/helpers/common.helper.js';

    export default {
        name: "InteractiveVotingPublicModal",
        data() {
            return {
                votingPossibleAnswersUpdate: [...this.votingPossibleAnswers],
                internalBasicData: this.basicData,
                hiddenVoteResult: 0,
                isHideResults: this.hiddenResults,
                votingTimer: null,
                avatarVersion: CommonHelper.getRandomVersionImageForUrlRefresh(0, 100)
            };
        },
        components: {

        },
        props: {
            basicData: Object,
            votingPossibleAnswers: Array,
            hiddenResults: Boolean
        },
        computed: {
            presentsUsers() {
                return this.internalBasicData.users.filter(item => parseInt(item.presence) !== 2).length ?? 0
            },
            votedUsers() {
                return this.internalBasicData.users.filter(item => parseInt(item.response) !== 0).length ?? 0
            }
        },
        mounted() {
            this.registerInteractiveEvents();
        },
        unmounted() {
            this.unregisterInteractiveEvents();
        },
        methods: {
            registerInteractiveEvents() {
                esesjaInteractivePublicService.events.on(signalrSocketAction.VoteResultChanged.name, this.partialVoteResult);
                esesjaInteractivePublicService.events.on(signalrSocketAction.ShowHideVotingResultsLive.name, this.ShowHideVotingResultsLive);
            },

            unregisterInteractiveEvents() {
                esesjaInteractivePublicService.events.removeListener(signalrSocketAction.VoteResultChanged.name, this.partialVoteResult);
                esesjaInteractivePublicService.events.removeListener(signalrSocketAction.ShowHideVotingResultsLive.name, this.ShowHideVotingResultsLive);
            },

            optionToClassName(option) {
                switch (option) {
                    case 'ZA':
                    case 'TAK':
                        return "vote-for";
                    case 'PRZECIW':
                    case 'NIE':
                        return "vote-no";
                    case 'WSTRZYMUJĘ SIĘ':
                    case 'NIE WIEM':
                        return "vote-abstain";
                    case 'BRAK GŁOSU':
                        return "vote-no-vote";
                    case 'NIEOBECNI':
                    case 'NIEOBECNY':
                        return "vote-absent";
                    case 'WYKLUCZENI':
                    case 'WYŁĄCZENI':
                        return "vote-exclude";
                    default:
                        return "vote-default";

                }
            },

            partialVoteResult(data) {
                this.internalBasicData.users = [...new Map(data.map((item) => [item["userId"], item])).values()];

                this.votingPossibleAnswersUpdate = this.votingPossibleAnswersUpdate.filter(obj => {

                    if (this.internalBasicData.gType != 4) {
                        if (this.internalBasicData.isPublic) {
                            // for public
                            obj.count = data.filter(item => { return parseInt(item.responseId) === obj.id }).length;

                            // for hidden public
                            this.hiddenVoteResult = data.filter(item => { return parseInt(item.response) !== 0 }).length;
                        }
                        else {
                            this.hiddenVoteResult = data.filter(item => { return parseInt(item.response) === 10 }).length;
                        }
                    }
                    else {
                        if (this.internalBasicData.isPublic) {
                            // for public
                            obj.count = data.filter(item => { return parseInt(item.responseId) === obj.id && parseInt(item.option) === 1 }).length;

                            // for hidden public
                            this.hiddenVoteResult = data.filter(item => { return parseInt(item.response) !== 0 && parseInt(item.option) === 1 }).length;
                        }
                        else {
                            this.hiddenVoteResult = data.filter(item => { return parseInt(item.response) === 10 && parseInt(item.option) === 1 }).length;
                        }
                    }

                    return true;
                })
            },

            ShowHideVotingResultsLive(data) {
                if (data)
                    this.isHideResults = false;
                else
                    this.isHideResults = true;
            },

            getVoteUserClass(item, showAvatar = true) {
                let classes = ""

                if (item === null)
                    classes = "";

                let userVoice = parseInt(item.response);

                if (userVoice === 0) {

                    if (item.excluded) {
                        classes = "excluded";
                    }

                    if (parseInt(item.presence) === 2) {
                        classes = "absent";
                    }
                }
                else {

                    if (this.isHideResults) {
                        if (userVoice != 99)
                            classes = "vote-hidden";
                    } else {
                        switch (userVoice) {
                            case 0: classes = "vote-lack"; break;
                            case 1: classes = "vote-for"; break;
                            case 2: classes = "vote-against"; break;
                            case 3: classes = "vote-abstain"; break;
                            case 4: classes = "absent"; break;
                            case 10:
                                //case 99:
                                classes = "vote-hidden"; break;
                        }
                    }
                }

                if (!showAvatar) {
                    classes += ' no-avatar'
                }
                return classes;
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric'
                });

                return d;
            },
            formatAnswersColorText(result) {
                if (result == "NIEOBECNI" || result == "NIEOBECNY" || result == "WYKLUCZENI" || result == 'PRZECIW' || result == 'NIE')
                    return 'white';
                else
                    return 'black';
            },
            formatAnswersColor(result) {
                if (result != null) {
                    switch (result.option) {
                        case 'ZA':
                        case 'TAK':
                            result.color = '#79e563'; 
                            break;
                        case 'PRZECIW':
                        case 'NIE':
                            result.color = '#e40030';
                            break;
                        case 'WSTRZYMUJĘ SIĘ':
                        case 'NIE WIEM':
                            result.color = '#ffce56';
                            break;
                        case 'BRAK GŁOSU':
                            result.color = '#c0c0c0';
                            break;
                        case 'NIEOBECNI':
                        case 'NIEOBECNY':
                            result.color = '#000';
                            break;
                        default:
                            result.color = '#c0c0c0';
                            break;
                    }
                    return result.color;
                }
            },
            getUserAvata(uid) {
                return `https://api.esesja.pl/users/${uid}.jpg${this.avatarVersion}`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .timerclass {
        color: black !important;
        font-size: 30px !important;
    }

    .download-button {
        float: right;
        margin: 0px;
        padding: 10px;
    }

    .uprawnieni {
        display:block;
    }

    .results {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
        justify-content: center;

        .vote-for {
            background-color: rgb(121, 229, 99);
            color: black;
            text-decoration: none;
        }

        .vote-no {
            background-color: rgb(228, 0, 48);
            color: white;
            text-decoration: none;
        }

        .vote-abstain {
            background-color: rgb(255, 206, 86);
            color: black;
            text-decoration: none;
        }

        .vote-no-vote {
            background-color: rgb(192, 192, 192);
            color: black;
            text-decoration: none;
        }

        .vote-absent {
            background-color: rgb(0, 0, 0);
            color: white;
            text-decoration: none;
        }
    }

    .uprawniony {
        display: inline-block;
        padding: 8px;
        font-size: 1.4em;
        font-weight: 300;
        background-color: #e5e5e5;
    }


    .voting {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 21;
    }

    .voting-form {
        padding: 32px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-height: 100vh;
        padding-bottom: 0px;
        padding-right: 0px;
        max-width: 95vw;
        //height: 100%;

        &__form-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            max-height: 90vh;
            padding-right: 56px;


            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #eef1f7;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #eef1f7;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 0px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }

            .available-users {
                display: flex;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 10px;
                justify-content: center;
                max-height: calc(90vh - 200px);

                .user {
                    width: 250px;
                    display: flex;
                    column-gap: 10px;
                    cursor: pointer;
                    align-items: center;
                    background-color: #e5e5e5;

                    .user-avatar img {
                        width: 70px;
                        vertical-align: middle;
                    }

                    .user-data .user-name {
                        font-size: 1.5em;
                        font-weight: 450;
                    }

                    &:hover {
                        background: #ddd;
                        box-shadow: 0px 0px 10px 5px #b1b1b1;
                    }
                }

                .no-avatar {
                    width: 180px;
                    padding: 5px 10px;
                }

                .user.absent {
                    background: #000 !important;
                    color: white !important;
                }

                .user.excluded {
                    background: #363532 !important;
                    text-decoration-line: line-through !important;
                    color: white;
                }

                .user.vote-for {
                    background: #79e563 !important;
                }

                .user.vote-against {
                    background: #e40030 !important;
                    color: white !important;
                }

                .user.vote-abstain {
                    background: #ffce56 !important;
                }

                .user.vote-hidden {
                    background: #7cd1eb !important;
                }
            }
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: calc(-100% + 48px);
        }

        &__row {
            margin-bottom: 10px;
        }

        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 128%;
            color: #00082c;
            width: 100%;

            &--small {
                font-size: 20px;
                border-bottom: 0px;
                padding-bottom: 16px;
                margin: 0px;
            }

            &--no-padding {
                padding: 0px;
            }

            &--comission {
                width: 100%;
                margin-top: 30px;
                padding-top: 28px;
                border-top: 1px solid #dde0e7;
            }
        }

        &__section-double {
            display: flex;
            justify-content: space-between;
            width: 500px;
        }

        &__col-double {
            width: 238px;
        }

        &__section-triple {
            display: flex;
            justify-content: space-between;
            width: 500px;
        }

        &__col-triple {
            width: 150px;
        }

        &__input {
            width: 100%;
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            margin: 8px 0px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;

            &--checkbox {
                padding-left: 32px;
                z-index: 10;
                cursor: pointer;
                font-weight: normal !important;
            }

            &--normal {
                font-weight: normal !important;
            }
        }

        &__point-status {
            display: flex;
            flex-direction: column;
            //margin-top: 32px;
            padding-bottom: 32px;
            //border-bottom: 1px solid #dde0e7;
            //margin-bottom: 24px;
        }

        &__checkbox-top {
            display: flex;
            align-items: center;
            margin-top: 12px;

            &--col {
                margin-bottom: 13px;
                align-items: flex-start;
            }
        }

        &__checkbox {
            width: 0px;
            height: 0px;
            display: none;

            &:checked ~ .edit-point-form__checkbox-mark {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:checked ~ .add-contact-form__label--checkbox {
                padding-left: 0px;
            }
        }

        &__checkbox-wrapper {
            width: 0px;
            height: 24px;
            position: relative;
            margin-left: 20px;

            &:before {
                content: "";
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
                border-radius: 4px;
                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                top: 0px;
                left: 0px;
                opacity: 1;
            }
        }

        &__checkbox-mark {
            background: #00082c;
            border: 1px solid #00082c;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 24px;
            height: 24px;
            position: absolute;
            z-index: 5;
            display: none;
            top: 0px;
            left: 0px;
            opacity: 1;
        }

        &__buttons-bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            padding-bottom: 56px;
        }

        &__bottom-button {
            //padding: 18px 40px 17px;
            width: 238px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            background: transparent;
            transition: all 0.2s ease-in-out;

            &:disabled {
                background-color: #e5e5e5;
                border: 0;
                color: silver;
                cursor: default;
                pointer-events: none;
            }

            &:hover {
                background: #e8ebef;
            }

            &--red {
                border: 0px;
                background: #d80418;
                color: white;

                &:hover {
                    background: #ad0313 !important;
                }
            }
        }

        &__no-attachments {
            margin-bottom: 34px;
        }

        &__attachments {
            padding-top: 31px;
            border-top: 1px solid #dde0e7;
            margin-top: 26px;
        }

        &__attachments-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 27px;
        }

        &__upload-btn {
            display: flex;
            align-items: center;
            border: 0px;
            padding: 9px 16px;
            background: rgba(220, 228, 238, 0.56);
            border-radius: 4px;
        }

        &__upload-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;
            white-space: nowrap;
            margin-left: 8px;
        }
    }

    .file-input {
        display: none;
    }

    .pulse-text {
        animation: pulse 4s infinite;
    }
    @keyframes pulse {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

</style>
