<template>
    <section class="meeting-wrapper" v-if="list.length > 0" style="position: relative;">
        <section v-for="(item, index) in list" :key="index" style="position: relative; width: 100%; ">
            
            <Meeting v-if="item.groups.length > 0"
                     :name="item.name"
                     :number="item.number"
                     :dateTime="item.meeting_date"
                     :endDateTime="item.end_meeting_date"
                     :id="item.id"
                     :isPublic="item.is_public"
                     :isAuthorized="item.is_authorized"
                     :groupid="item.groups[0].id" 
                     :groupNumeration="item.groups[0].session_number_format_id"
                     :groups="item.groups" />
        </section>
    </section>
    <section v-else class="meeting-wrapper">
        <p class="meeting-wrapper__no-meetings">Brak spotkań</p>
    </section> 
</template>

<script>
    import Meeting from "./Meeting.vue";

    export default {
        name: "MeetingArchiveWrapper",
        components: {
            Meeting,
        },
        props: {
            list: Array,
        },
        data() {
            return {
                tempContextMenu: null,
                isLoading: false,
            }
        },
        mounted() {
            
        },
        methods: {
            
        }
    };
</script>

<style lang="scss" scoped>
    .meeting-wrapper {
        margin-right: 28px;
        margin-left: 28px;
        margin-top: 10px;
        margin-bottom: 10px;

        &__title {
            font-weight: 600;
            font-size: 13px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #666b80;
        }

        &__no-meetings {
            font-size: 20px;
            margin-top: 12px;
        }
    }
</style>
