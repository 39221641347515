export default class eSesjaSignalrAction {
    static ActiveAgendaPointChange = new eSesjaSignalrAction("ActiveAgendaPointChange");
    static SessionBreakStatusChange = new eSesjaSignalrAction("SessionBreakStatusChange");
    static AddTimeToSpeakerForPublic = new eSesjaSignalrAction("AddTimeToSpeakerForPublic");
    static CurrentAgendaItems = new eSesjaSignalrAction("CurrentAgendaItems");
    static UserStartSpeech = new eSesjaSignalrAction("UserStartSpeech");
    static UserEndSpeech = new eSesjaSignalrAction("UserEndSpeech");
    static QuorumStarted = new eSesjaSignalrAction("QuorumStarted");
    static QuorumEnded = new eSesjaSignalrAction("QuorumEnded");
    static QuorumResultsChanged = new eSesjaSignalrAction("QuorumResultsChanged");
    static QuorumSummaryReults = new eSesjaSignalrAction("QuorumSummaryReults");
    static VotingStarted = new eSesjaSignalrAction("VotingStarted");
    static VotingEnded = new eSesjaSignalrAction("VotingEnded");
    static VoteResultChanged = new eSesjaSignalrAction("VoteResultChanged");
    static VoteResultSummary = new eSesjaSignalrAction("VoteResultSummary");
    static SignalRReconnected = new eSesjaSignalrAction("SignalRReconnected");
    static VoiceRequestListChanged = new eSesjaSignalrAction("VoiceRequestListChanged");
    static UpdateDiscussionListAdmin = new eSesjaSignalrAction("UpdateDiscussionListAdmin");
    static CloseVotingResultModal = new eSesjaSignalrAction("CloseVotingResultModal");
    static CloseResultCheckQuorumModal = new eSesjaSignalrAction("CloseResultCheckQuorumModal");
    static OpenAttachment = new eSesjaSignalrAction("OpenAttachment");
    static ChangeAttachmentName = new eSesjaSignalrAction("ChangeAttachemtnPage");
    static CloseAttachment = new eSesjaSignalrAction("CloseAttachemnt");
    static ChangePlaceholderStatus = new eSesjaSignalrAction("ChangePlaceholderStatus");
    static OpenInteractiveVotingPublic = new eSesjaSignalrAction("OpenInteractiveVotingPublic");
    static ShowHideVotingResultsLive = new eSesjaSignalrAction("ShowHideVotingResultsLive");
    static SetDonePoint = new eSesjaSignalrAction("SetDonePoint");
    static CurrentClientSettings = new eSesjaSignalrAction("CurrentClientSettings");

    constructor(name) {
        this.name = name;
    }
}


