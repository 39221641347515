<template>
    <header class="interactive-header">
        <section class="interactive-header-menu">
            <a class="menu-item" @click="$store.commit('showPresenceList', $store.state.appStore.sessionId);">
                <img src="../../../assets/icons/components/user-check-presence.svg" class="menu-icon" alt="Lista obecności" />
                <span class="menu-text">Lista obecności</span>
            </a>
            <a class="menu-item" @click="$store.commit('showCheckQourum')" v-if="$store.state.settings.client.menuInteractiveShowQorum">
                <img src="../../../assets/icons/components/user-check-quorum.svg" class="menu-icon" alt="Sprawdź kworum" />    
                <span class="menu-text">Sprawdź kworum</span>
            </a>
            <div class="dropdown">
                <button class="dropbtn">
                    <img src="../../../assets/icons/components/break-time-icon.svg" class="menu-icon" alt="Przerwa" />
                    Przerwa
                    <img src="../../../assets/icons/panel/header/arrow.svg" class="dropdown-arrow" />
                </button>
                <perfect-scrollbar class="dropdown-content">
                    <a class="menu-item" @click="startSessionBrake(5)"><span class="menu-text">5 minut</span></a>
                    <a class="menu-item" @click="startSessionBrake(10)"><span class="menu-text">10 minut</span></a>
                    <a class="menu-item" @click="startSessionBrake(15)"><span class="menu-text">15 minut</span></a>
                    <a class="menu-item" @click="startSessionBrake(20)"><span class="menu-text">20 minut</span></a>
                    <a class="menu-item" @click="startSessionBrake(30)"><span class="menu-text">30 minut</span></a>
                    <a class="menu-item" @click="startSessionBrake(45)"><span class="menu-text">45 minut</span></a>
                    <a class="menu-item" @click="startSessionBrake(60)"><span class="menu-text">60 minut</span></a>
                    <a class="menu-item" @click="$store.commit('showCustomBreakModal')"><span class="menu-text">Dowolny czas</span></a>
                </perfect-scrollbar>
            </div>
            <a class="menu-item" @click="refreshSession()">
                <img src="../../../assets/icons/components/refresh-list.svg" class="menu-icon" alt="Odśwież porządek" />
                <span class="menu-text">Odśwież porządek</span>
            </a>
            <div class="dropdown">
                <button class="dropbtn">
                    <PulseDot  v-if="isAnyActivePlaceholder" v-tooltip="getPlaceholderTooltip()" color="green"/>
                    <img src="../../../assets/icons/components/presentation-screen.svg" class="menu-icon" alt="Plansze" />
                    Plansze
                    <img src="../../../assets/icons/panel/header/arrow.svg" class="dropdown-arrow" />
                </button>
                <div class="dropdown-content">
                    <a class="menu-item" @click="deActivatePlaceholder">
                        <span class="menu-text">Wyłącz planszę</span>
                    </a>
                    <perfect-scrollbar>
                        <a class="menu-item" :class="{'active': currentActivePlaceholder === item.id}" v-for="(item, index) in availablePlaceholders" :key="index" @click="activatePlaceholder(item)">
                            <PulseDot v-if="currentActivePlaceholder === item.id" v-tooltip="getPlaceholderTooltip()" color="green" style="margin: 0px !important" />
                            <span class="menu-text">{{ item.name }}</span>
                        </a>
                    </perfect-scrollbar>
                    <a class="menu-item" @click="$store.commit('setIsShowAddPlaceholderStatus', true)">
                        <img src="@/assets/icons/components/plus-small.svg" style="filter: invert(1);"/>
                        <span class="menu-text">Dodaj planszę</span>
                    </a>
                </div>
            </div>
        </section>
    </header>
</template>
<script charset="utf-8">
    import eSesjaInteractiveService from "../../../services/esesja.interactive.service";
    import placeholderService from "../../../services/placeholder.service";
    import PulseDot from "../../../components/BasicControls/PulseDot.vue";

    export default {
        name: "InteractiveHeader",
        components: {
            PulseDot
        },
        data() { return {} },
        computed: {
            availablePlaceholders(){
                return this.$store.state.placeholderStore.placeholders;
            },
            currentActivePlaceholder() {
                return this.$store.state.placeholderStore.currentActivePlaceholder;
            },
            isAnyActivePlaceholder() {
                return this.currentActivePlaceholder !== null;
            }
        },
        async mounted() {
            try{
                await placeholderService.GetAll();
            }
            catch(e){
                console.log(e);
            }
        },
        methods: {
            async startSessionBrake(breakDuration) {
                try {
                    let timestamp = new Date();
                    this.$store.commit("setBreakTimestamp", timestamp);
                    this.$store.commit("setBreakDuration", breakDuration);
                    this.$store.commit("startBreak");

                    await eSesjaInteractiveService.startBreak(breakDuration, this.$store.state.appStore.sessionId, timestamp);
                }
                catch (e) {
                    this.$store.commit("showError");
                }
            },
            async refreshSession() {
                try {
                    await eSesjaInteractiveService.changeInAgendaWithSendToTablet(this.$store.state.appStore.sessionId);
                    await eSesjaInteractiveService.reloadSession(this.$store.state.appStore.sessionId);
                }
                catch (e) {
                    console.log(e)
                    this.$store.commit("showError");
                }
            },
            async activatePlaceholder(placeholder){
                try{
                    await eSesjaInteractiveService.activePlaceholder(this.$store.state.appStore.sessionId, placeholder.id);
                    this.$store.commit("setActivePlaceholder", placeholder.id)
                }
                catch (e) {
                    console.log(e)
                    this.$store.commit("showError");
                }
            },
            async deActivatePlaceholder(){
                try{
                    await eSesjaInteractiveService.deActivePlaceholder(this.$store.state.appStore.sessionId);
                    this.$store.commit("setActivePlaceholder", null)
                }
                catch (e) {
                    console.log(e)
                    this.$store.commit("showError");
                }
            },

            getPlaceholderTooltip() {
                if (!this.isAnyActivePlaceholder) {
                    return ""
                }
                let currentPlaceholder = this.availablePlaceholders.filter(item => item.id === this.currentActivePlaceholder);
                if (currentPlaceholder.length != 1) {
                    return "";
                }

                return `Aktywna plansza: ${currentPlaceholder[0].name}`;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/breakpoints.scss";
    .interactive-header {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        padding-right: 48px;
        position: sticky;
        top: 0px;
        z-index: 6;

        &::after {
            content: "";
            position: absolute;
            background: #dde0e7;
            width: 100%;
            height: 1px;
            bottom: -1px;
            left: 0px;
        }

        .interactive-header-menu {
            overflow: hidden;

            .menu-item {
                display: flex;
                column-gap: 10px;
                align-items: center;
                float: left;
                font-size: 16px;
                color: #000;
                text-align: center;
                padding: 38px 20px 38px 20px;
                text-decoration: none;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                .menu-icon {
                    height: 20px;
                }

                .menu-text {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 132%;
                }

                &:hover {
                    background-color: #e8ebef;
                }
            }

            .dropdown {
                float: left;
                overflow: hidden;

                .dropbtn {
                    font-size: 16px;
                    border: none;
                    outline: none;
                    color: #000;
                    padding: 38px 20px 38px 20px;
                    background-color: inherit;
                    font-family: inherit; /* Important for vertical align on mobile phones */
                    margin: 0; /* Important for vertical align on mobile phones */
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 132%;
                    display: flex;
                    column-gap: 10px;
                    align-items: center;

                    .menu-icon {
                        height: 20px;
                    }
                }

                .dropdown-content {
                    max-height: 50vh;
                    display: none;
                    position: absolute;
                    background-color: #fff;
                    min-width: 160px;
                    box-shadow: 0px 10px 9px 0px #b1b1b1;
                    z-index: 1;

                    .menu-item {
                        padding: 10px 0px 10px 20px;
                        float: none;
                        display: flex;
                        text-align: left;
                    }
                }

                &:hover {
                    background-color: #e8ebef;

                    .dropdown-content {
                        display: block;
                    }
                }
            }
        }
    }
</style>