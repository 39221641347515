<template>
    <section class="add-contact">
        <div class="add-contact-form">
            <button class="add-contact-form__exit-btn"
                    @click="this.$emit('closePermission')">
                <img src="../../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <section class="add-contact-form__form-wrapper">
                <h2 class="add-contact-form__title">
                    {{ permissionToEdit.user.id == 0 ? "Dodaj" : "Edytuj" }} uprawnienia <br />
                    <span class="add-contact-form__title--small">
                        {{permissionToEdit.user.firstName}} {{permissionToEdit.user.lastName}}
                    </span>
                </h2>
                <section class="add-contact-form__row-main">
                    <section class="add-contact-form__column">
                        <section class="add-contact-form__login">
                            <section class="add-contact-form__top">
                                <h3 class="add-contact-form__title add-contact-form__title--small">
                                    Dane logowania
                                </h3>
                            </section>
                            <section>
                                <section class="add-contact-form__row">
                                    <section class="add-contact-form__col">
                                        <label class="add-contact-form__label">{{ permissionToEdit.user.id == 0 ? "Utwórz" : "Edytuj" }} Login</label>
                                        <input type="text"
                                               v-model="permissionToEdit.user.login"
                                               class="add-contact-form__input-text"
                                               v-error="{ errors, input_key: 'login' }" />
                                        <ErrorMessage v-if="isError('login', this.errors)"
                                                      :errorMsgs="passErrorMsg('login', this.errors)" />
                                    </section>
                                </section>
                               
                            </section>
                        </section>
                        <section class="add-contact-form__change-password">
                            <section>
                                <section class="add-contact-form__row">
                                    <section class="add-contact-form__col">
                                        <label class="add-contact-form__label">{{ permissionToEdit.user.id == 0 ? "Utwórz" : "Nowe" }} hasło</label>
                                        <div style="display: flex" data-tip="Hasło musi zawierać min. 12 znaków.">
                                            <input :type="passwordFieldType"
                                                   v-model="permissionToEdit.user.password"
                                                   class="add-contact-form__input-text"
                                                   v-error="{ errors, input_key: 'password' }" />
                                            <section class="button-show"
                                                     @click="switchVisibility">
                                                <img src="../../../../../../assets/icons/eye.svg" />
                                            </section>
                                        </div>

                                        <label class="add-contact-form__label">Powtórz hasło</label>
                                        <input type="password"
                                               v-model="permissionToEdit.user.password2"
                                               class="add-contact-form__input-text"
                                               v-error="{ errors, input_key: 'password2' }" />
                                        <ErrorMessage v-if="isError('password2', this.errors)"
                                                      :errorMsgs="passErrorMsg('password2', this.errors)" />
                                    </section>
                                </section>
                                <section class="add-contact-form__row">
                                    <section class="add-contact-form__col">
                                        <section class="add-contact-form__bottom-btns-pass">
                                            <button class="add-contact-form__action-btn" @click="generatePassword()">
                                                Generuj hasło
                                            </button>
                                        </section>
                                        <ErrorMessage v-if="isError('password', this.errors)"
                                                      :errorMsgs="passErrorMsg('password', this.errors)" />
                                    </section>

                                </section>

                                <section class="add-contact-form__custom-btns">
                                    <button class="add-contact-form__action-btn add-contact-form__action-btn--red"
                                            @click="saveLoginData">
                                        Zapisz dane
                                    </button>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section class="add-contact-form__column" v-if="permissionToEdit.user.id > 0">
                        <h3 class=" add-contact-form__title add-contact-form__title--small">
                            <span class="permission" :class="{'selected_card' : isPermissions}" @click="showPermissions()">Uprawnienia</span> 
                            <span v-if="permissionToEdit.groupsPermission != null" class="permission" :class="{'selected_card' : isGroupManage}" @click="showGroupMenage()">Zarządzanie grupami</span>
                        </h3>

                        <section class="add-contact-form__groups" v-if="isPermissions && !isGroupManage">

                            <section v-for="per in permissionToEdit.permission"
                                     :key="per.id"
                                     class="add-contact-form__checkbox">
                                <CheckBoxButton :id="per.id"
                                                :value="per.id"
                                                @setGroupId="(value)=>(this.checkedGroupsId = value)"
                                                :idsArray="checkedGroupsId"
                                                :checked="checkedGroupsId.indexOf(per.id) !== -1" />
                                <label class=" add-contact-form__label add-contact-form__label--checkbox" :for="per.id">{{ per.name }}</label>
                            </section>
                            <section class="add-contact-form__custom-btns">
                                <!--<button class="add-contact-form__action-btn" @click="this.$emit('closePermission')"> Anuluj </button>-->
                                <button class="add-contact-form__action-btn add-contact-form__action-btn--red" @click="(e) => handleAddPermissionClick(e)">
                                    Zapisz uprawnienia
                                </button>
                            </section>
                        </section>

                        <section class="add-contact-form__groups" v-if="!isPermissions && isGroupManage && permissionToEdit.groupsPermission != null">

                            <section v-for="per in permissionToEdit.groupsPermission"
                                     :key="per.id"
                                     class="add-contact-form__checkbox">
                                <CheckBoxButton :id="per.id"
                                                :value="per.id"
                                                @setGroupId="(value)=>(this.checkedPermissionsGroups = value)"
                                                :idsArray="checkedPermissionsGroups"
                                                :checked="checkedPermissionsGroups.indexOf(per.id) !== -1" />
                                <label class=" add-contact-form__label add-contact-form__label--checkbox" :for="per.id">{{ per.name }}</label>
                            </section>
                            <section class="add-contact-form__custom-btns">
                                <!--<button class="add-contact-form__action-btn" @click="this.$emit('closePermission')"> Anuluj </button>-->
                                <button class="add-contact-form__action-btn add-contact-form__action-btn--red" @click="(e) => handleSaveMenageGroupsClick(e)">
                                    Zapisz zarządzanie
                                </button>
                            </section>
                        </section>

                    </section>
                    <section v-else class="add-contact-form__column">
                        <h3 class=" add-contact-form__title add-contact-form__title--small">
                            <span>Uprawnienia</span></h3>
                        <p style="color: red">Sekcja nadawania uprawnień użytkownikowi zostanie odblokowana po utworzeniu danych logowania (login i hasło).</p>
                    </section>
                </section>
            </section>
            <LoaderInside v-if="isLoading" />
        </div>
    </section>
</template>

<script>
    import CheckBoxButton from "../../../../../BasicControls/CheckBoxButton.vue";
    import ContactService from "../../../../../../services/contact.service";
    import LoaderInside from "../../../../../BasicControls/LoaderInside.vue";
    import ErrorMessage from "../../../../../BasicControls/ErrorMessage.vue";
    import UserService from "../../../../../../services/user.service";
    import AlertHelper from '@/helpers/alerts.helper.js';
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "../../../../../../formValidation/main";


    export default {
        name: "AddContact",
        data() {
            return {
                permissionToEdit: { ...this.permission },
                checkedGroupsId: [],
                checkedPermissionsGroups: [],
                isLoading: false,
                errors: [],
                isPermissions: true,
                isGroupManage: false,
                passwordFieldType: "password",
                suggestLogin: false,
            };
        },
        components: {
            CheckBoxButton,
            LoaderInside,
            ErrorMessage,
        },
        props: {
            permission: Object,
            lid: Number
        },
        async mounted() {
            if (this.permissionToEdit !== null) {
                for (var i = 0; i < this.permissionToEdit.permission.length; i++) {
                    if (this.permissionToEdit.permission[i].value == true) {
                        this.checkedGroupsId.push(this.permissionToEdit.permission[i].id);
                    }
                }

                if (this.permissionToEdit.groupsPermission != undefined && this.permissionToEdit.groupsPermission != null) {
                    for (var j = 0; j < this.permissionToEdit.groupsPermission.length; j++) {
                        if (this.permissionToEdit.groupsPermission[j].value == true) {
                            this.checkedPermissionsGroups.push(this.permissionToEdit.groupsPermission[j].id);
                        }
                    }
                }

                if (this.permissionToEdit.user.login === null) {
                    let response = await UserService.getKidRid();
                    if (response.status === 200) {
                        this.suggestLogin = true;
                        this.permissionToEdit.user.login = `${this.replacePolishLetters(this.permissionToEdit.user.firstName.toLowerCase())}.${this.replacePolishLetters(this.permissionToEdit.user.lastName.toLowerCase())}.${response.data.kid}`
                    }
                }

            }
        },
        methods: {
            replacePolishLetters(str) {
                const replacements = {
                    'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z',
                    'Ą': 'A', 'Ć': 'C', 'Ę': 'E', 'Ł': 'L', 'Ń': 'N', 'Ó': 'O', 'Ś': 'S', 'Ź': 'Z', 'Ż': 'Z'
                };

                const expression = /[ąćęłńóśźż]/gi;

                return str.replace(expression, (letter) => replacements[letter]);
            },
            showPermissions() {
                this.isPermissions = true;
                this.isGroupManage = false;
            },
            showGroupMenage() {
                this.isPermissions = false;
                this.isGroupManage = true;
            },
            validateLogin(data) {
                var bo = true;
                var err = {};


                if (data.user.login === null || data.user.login.length <= 0) {
                    err.login = ['Pole wymagane']
                    bo = false
                }

                
                if (data.user.login !== null) {
                    if (data.user.login.length <= 6) {
                        err.login = ['Login musi miec min. 7 znaków']
                        bo = false
                    }

                    if (/\s/.test(data.user.login)) {
                        err.login = ['Login nie może zawierać spacji']
                        bo = false
                    }

                    if (/^[a-zA-Z0-9.@-]+$/.test(data.user.login) === false) {
                        err.login = ['Login zawiera niedozwolone znaki']
                        bo = false
                    }
                }
                
                if (!bo) {
                    this.isLoading = false;
                    this.errors = setErrors(err)
                }
                return bo
            },

            validatePasswords(data) {
                var bo = true;
                var err = {};

                if (data.user.id == 0) {
                    if (data.user.password !== null) {
                        if (data.user.password.length <= 0) {
                            err.password2 = ['Stworzenie hasła jest wymagane']
                            bo = false
                        }
                    }

                }

                if (data.user.password == null) {
                    err.password2 = ['Proszę podać hasło']
                    bo = false
                }


                if (data.user.password != null && data.user.password.length > 0) {
                    if (data.user.password.length < 12) {
                        err.password2 = ['Minimalna ilość znaków 12']
                        bo = false
                    }

                    if (/\s/.test(data.user.password)) {
                        err.password2 = ['Hasło nie może zawierać spacji']
                        bo = false
                    }

                    if (data.user.password.includes("%")) {
                        err.password2 = ['Hasło nie może zawierać znaku %']
                        bo = false
                    }

                    if (data.user.password.length >= 12) {
                        if (data.user.password != data.user.password2) {
                            err.password2 = ['Podane hasła są rózne!']
                            bo = false
                        }
                    }
                }

                if (!bo) {
                    this.isLoading = false;
                    this.errors = setErrors(err)
                }
                return bo
            },

            
            switchVisibility() {
                this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            },
            generatePassword() {
                var ps = "";
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

                for (var i = 0; i < 6; i++)
                    ps += possible.charAt(Math.floor(Math.random() * possible.length));

                var possiblelowercase = "abcdefghijklmnopqrstuvwxyz1234567890";
                for (var j = 0; j < 8; j++)
                    ps += possiblelowercase.charAt(Math.floor(Math.random() * possiblelowercase.length));

                var special = "!@#$&*()";
                ps += special.charAt(Math.floor(Math.random() * special.length));

                this.permissionToEdit.user.password = ps;
                this.permissionToEdit.user.password2 = ps;
            },
            async handleAddPermissionClick(e) {
                try {
                    e.preventDefault();
                    this.errors = [];
                    this.isLoading = true;

                    if (this.permissionToEdit.user.id <= 0)
                        return

                    for (var i = 0; i < this.permissionToEdit.permission.length; i++) {
                        var elem = this.checkedGroupsId.find(x => x == this.permissionToEdit.permission[i].id);
                        if (elem != null)
                            this.permissionToEdit.permission[i].value = true;
                        else
                            this.permissionToEdit.permission[i].value = false;
                    }

                    var result = (await ContactService.addOrEditUserPermissionData(this.permissionToEdit.user.id, this.permissionToEdit.permission));
                    if (result.status == 200) {
                        //this.checkedGroupsId = [];
                        //this.$emit("closePermission");
                        this.$store.commit("showSuccessCustomMessage", `Pomyślnie zapisano uprawnienia`);
                    }

                } catch (e) {

                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally {
                    this.isLoading = false;
                }
            },
            async handleSaveMenageGroupsClick(e) {
                try {
                    e.preventDefault();
                    this.errors = [];
                    this.isLoading = true;

                    for (var i = 0; i < this.permissionToEdit.groupsPermission.length; i++) {
                        var elem = this.checkedPermissionsGroups.find(x => x == this.permissionToEdit.groupsPermission[i].id);
                        if (elem != null)
                            this.permissionToEdit.groupsPermission[i].value = true;
                        else
                            this.permissionToEdit.groupsPermission[i].value = false;
                    }

                    var result = (await ContactService.addOrEditUserGroupsPermissionData(this.lid, this.permissionToEdit.groupsPermission));
                    if (result.status == 200) {
                        //this.checkedPermissionsGroups = [];
                        //this.$emit("closePermission");
                        this.$store.commit("showSuccessCustomMessage", `Pomyślnie zapisano zarządzanie grupami`);
                    }

                } catch (e) {

                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally {
                    this.isLoading = false;
                }
            },
            async saveLoginData() {
                try {
                    this.errors = [];
                    this.isLoading = true;
                    if (this.validateLogin(this.permissionToEdit) == false)
                        return

                    if (this.permissionToEdit.user.id == 0 || (this.permissionToEdit.user.password != null && this.permissionToEdit.user.password.length > 0)) {
                        if (this.validatePasswords(this.permissionToEdit) == false)
                            return
                    }

                    var result = (await ContactService.addOrEditUserLoginData(this.lid, this.permissionToEdit.user));
                    if (result.status !== 200) {
                        throw await Promise.reject(result);
                    }

                    this.checkedGroupsId = [];
                    this.permissionToEdit.user.id = result.data;

                    var result_perm = (await ContactService.getUserDataToEdit(parseInt(this.lid))).data;
                    if (Object.keys(result_perm).length > 0) {

                        this.permissionToEdit.permission = result_perm.permission;

                        for (var i = 0; i < this.permissionToEdit.permission.length; i++) {
                            if (this.permissionToEdit.permission[i].value == true) {
                                this.checkedGroupsId.push(this.permissionToEdit.permission[i].id);
                            }
                        }
                    }
                }
                catch (e) {
                    if (e.response !== undefined && e.response !== null) {

                        if (e.response.status === 401)
                            this.$router.push({ name: 'Login' })

                        if (e.response.status === 400) {
                            if (Array.isArray(e.response.data)) {
                                let err = {}
                                for (let validationError of e.response.data) {
                                    if (Object.hasOwn(err, validationError.propertyName.toLowerCase()) === false){
                                        err[validationError.propertyName.toLowerCase()] = []
                                    }
                                    err[validationError.propertyName.toLowerCase()].push(validationError.errorMessage);
                                }
                                this.errors = setErrors(err)
                            }
                            else {
                                if (e.response.data === 'Login') {
                                    var t = this.$swal.mixin(AlertHelper.errorToastElement());
                                    t.fire(AlertHelper.errorToastAlert('Wskazany login jest już zajęty'));
                                }

                                if (e.response.data === 'Email') {
                                    var t2 = this.$swal.mixin(AlertHelper.errorToastElement());
                                    t2.fire(AlertHelper.errorToastAlert('Email podany w formularzu kontaktu jest niepoprawny lub już zajęty. Zmień go!'));
                                }
                            }
                        }

                        if (e.response.status === 422) {
                            var t3 = this.$swal.mixin(AlertHelper.errorToastElement());
                            t3.fire(AlertHelper.errorToastAlert('Osiągnięto limit użytkowników wynikających z licencji. Nie można utworzyć użytkownika'));
                        }
                        
                    }
                   
                }
                finally {
                    this.isLoading = false;
                }
            },
            passErrorMsg,
            setErrors,
            isError,
        },
    };
</script>

<style lang="scss" scoped>

    [data-tip] {
        position: relative;
    }

    [data-tip]:before {
        content: '';
        /* hides the tooltip when not hovered */
        display: none;
        content: '';
        display: none;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #1a1a1a;
        position: absolute;
        top: 55px;
        left: 35px;
        z-index: 8;
        font-size: 0;
        line-height: 0;
        width: 0;
        height: 0;
        position: absolute;
        top: 50px;
        left: 35px;
        z-index: 8;
        font-size: 0;
        line-height: 0;
        width: 0;
        height: 0;
    }

    [data-tip]:after {
        display: none;
        content: attr(data-tip);
        position: absolute;
        top: 55px;
        left: 0px;
        padding: 5px 8px;
        background: #1a1a1a;
        color: #fff;
        z-index: 9;
        font-size: 0.75em;
        height: 18px;
        line-height: 18px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        white-space: nowrap;
        word-wrap: normal;
    }

    [data-tip]:hover:before,
    [data-tip]:hover:after {
        display: block;
    }

    .add-contact {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 8;
        background: #00082c75;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-show {
        width: 20%;
        height: 50px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        //box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        border-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        //position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: "";
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            height: 40px;
            width: 1px;
            background: #e2e5eb;
        }
    }

    .add-contact-form {
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        width: 65vw;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-width: 1200px;
        padding: 32px 56px;
        max-height: 90vh;
        padding-right: 0px;

        &__login {
            border-bottom: 1px solid #dde0e7;
        }

        &__change-password {
            padding: 32px 0px;
        }

        &__row {
            display: flex !important;
            flex-direction: row;
        }

        &__form-wrapper {
            max-height: 80vh;
            overflow-y: auto;
            padding-right: 56px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: calc(-100% + 48px);
        }

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 15px;

            &--small {
                font-size: 20px;
                margin-bottom: 27px;
            }
        }

        .permission {
            padding: 10px;
            cursor: pointer;
            font-weight: 300;
        }

        .selected_card {
            text-decoration: underline;
            font-weight: 600;
        }

        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__row-main {
            border-top: 1px solid #dde0e7;
            display: flex;
            column-gap: 20px;
        }

        &__column {
            margin-top: 32px;
            min-width: 350px;
        }

        &__column:first-child {
            border-right: 1px solid #dde0e7;
            padding-right: 2vw;
        }

        &__column:last-child {
            padding-left: 2vw;
        }

        &__col {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            &:nth-child(1) {
                margin-right: 24px;
            }
        }

        &__col-duo {
            display: flex;
        }

        &__label {
            font-weight: 500;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;

            &--checkbox {
                font-weight: normal;
                font-size: 16px;
                line-height: 144%;
                color: #333956;
                margin-left: 12px;
                margin-bottom: 0px;
                padding-left: 24px;
                z-index: 6;
                cursor: pointer;
            }
        }

        &__input-text {
            margin-bottom: 10px;
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 100%;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
            min-width: 238px;

            &:focus {
                border: 1px solid #00082c;
            }

            &--small {
                min-width: 107px;
                margin-top: 7px;
            }
        }

        &__col-double {
            &__label {
                font-weight: 500;
                font-size: 14px;
                line-height: 145%;
                color: #00082c;
                margin-bottom: 8px;
            }

            &:nth-child(1) {
                margin-right: 24px;
            }
        }

        &__inputs {
            max-width: 500px;
            padding-bottom: 18px;
        }

        &__groups {
        }

        &__checkbox {
            display: flex;
            align-items: center;
            margin-bottom: 13px;
        }

        &__check-icon {
            width: 0px;
            height: 24px;
            position: relative;
            opacity: 1;

            &:checked ~ .checkbox-mark {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:checked ~ .add-contact-form__label--checkbox {
                padding-left: 0px;
            }

            &:before {
                content: "";
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
                border-radius: 4px;
                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                top: 0px;
                left: 0px;
                opacity: 1;
            }
        }

        &__bottom-btns {
            margin-top: 32.5px;
            padding-bottom: 26px;
        }

        &__custom-btns {
            //display: flex;
            align-items: flex-end;
            flex-direction: column;
        }

        &__bottom-btns-pass {
            margin-top: 0px;
            padding-bottom: 0px;
        }

        &__action-btn {
            padding: 18px 40px 17px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            min-width: 238px;
            border: 1px solid #bbc2ce;
            background-color: transparent;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 0px;
                background: #d80418;
                margin-top: 10px;
                color: white;
                //margin-left: 24px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #ad0313;
                }
            }
        }
    }

    .import-from-db {
        margin-bottom: 27px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: #e9eef5;
        border-radius: 4px;
        padding: 9px 16px;
        border: 0px;

        &__text {
            margin-left: 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;
        }
    }

    .checkbox-mark {
        background: #00082c;
        border: 1px solid #00082c;
        box-sizing: border-box;
        box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        width: 24px;
        height: 24px;
        position: relative;
        z-index: 99;
        display: none;
        top: 0px;
        left: 0px;
        opacity: 1;
    }

    @media (max-width: 1399.98px) {
        .add-contact-form {
            width: 75vw;
        }
    }

    @media (max-width: 991.98px) {
        .add-contact-form {
            width: 85vw;
        }

        .add-contact-form__row-main {
            flex-direction: column;
        }
    }
</style>
