<template>
    <section>
        <PieChart v-if="type==1"
             :chart-options="chartOptions"
             :chart-data="chartData"
             :chart-id="chartId"
             :dataset-id-key="datasetIdKey"
             :plugins="plugins"
             :css-classes="cssClasses"
             :styles="styles"
             :width="width"
             :options="options"
             :height="height" />

        <DoughnutChart v-else-if="type==2"
             :chart-options="chartOptions"
             :chart-data="chartData"
             :chart-id="chartId"
             :dataset-id-key="datasetIdKey"
             :plugins="plugins"
             :css-classes="cssClasses"
             :styles="styles"
             :options="options"
             :width="width"
             :height="height" />

        <BarChart v-else :chart-options="chartOptions"
             :chart-data="chartData"
             :chart-id="chartId"
             :dataset-id-key="datasetIdKey"
             :plugins="plugins"
             :css-classes="cssClasses"
             :styles="styles"
             :width="width"
             :options="options"
             :height="height" />

    </section>
</template>

<script>
    import BarChart from './BarChart.vue'
    import PieChart from './PieChart.vue'
    import DoughnutChart from './DoughnutChart.vue'

    export default {
        name: 'MainChart',
        components: { BarChart, PieChart, DoughnutChart },
        props: {
            chartId: {
                type: String,
                default: 'chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 450
            },
            height: {
                type: Number,
                default: 450
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => { }
            },
            plugins: {
                type: Object,
                default: () => {}
            },
            options: {
                type: Object,
                default: () => {}
            },
            chartData: {
                type: Object,
                default: () => { }
            },
            type: Number,
        },
        data() {
            return {
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                },
            }
        }
    }


</script>

<style></style>