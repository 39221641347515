<template>
    <section>
        <section class="header">
            <section class="header-left">
                <img src="../assets/imgs/logo.png" class="side-bar__logo" />
                
                <div class="header-right__text">
                    <span> {{client}} </span>
                    <img v-if="logo != null && logo.length > 0" :src="logo" class="logo" align="absmiddle" style="margin-left:20px" />
                </div>
                
            </section>
        </section>

        <section class="container" v-if="!error">
            <section class="session__top-left">
                <section class="session__top-box">
                    <img src="../assets/icons/panel/editSession/meeting-place.svg" /><span class="session__top-text session__top-text--name"> {{session.name}}</span>
                </section>
                <section class="session__top-box">
                    <img src="../assets/icons/panel/editSession/meeting-date.svg" /><span class="session__top-text">{{ getDate }}</span>
                </section>
                <section class="session__top-box">
                    <img src="../assets/icons/panel/editSession/meeting-time.svg" /><span class="session__top-text">Godzina: {{ getTime }}</span>
                </section>
                <section class="session__top-box" v-if="session.meeting_place != null && session.meeting_place.length > 0">
                    <img src="../assets/icons/panel/editSession/location-dot.svg" /><span class="session__top-text">Lokalizacja: {{session.meeting_place}} </span>
                </section>
            </section>

            <div class="sesion-container-wrapper">
                <section class="session-agenda">
                    <section v-for="(s) in session.agendaItems" :key="s.id">
                        <section class="box">
                            <section :class="s.is_active ? 'box__active' : 'box__top'" :id="'id-point-'+s.id">
                                <section>
                                    <div class="point-is-done" v-if="s.is_done">
                                        <img class="point-is-done__img" src="../assets/icons/panel/editSession/meeting-time.svg" />
                                        <span>Zakończony: {{ getTimeEndPoint(s) }}</span>
                                    </div>
                                    <div>
                                        <span class="box__title" :class="{'box--takenoff' : s.is_taken_off}">{{filterIndex(s, 0)}} {{ s.name }}</span>
                                    </div>

                                    <div v-if="s.reporter_name != null && s.reporter_name.length > 0" class="referer">
                                        <span>Referuje: <b>{{s.reporter_name}}</b></span>
                                    </div>

                                    <!-- attachements -->
                                    <div v-for="( attachment, index ) in s.attachments" :key="index" class="points-list__attachment-wrapper">
                                        <img src="../assets/icons/components/attachment.svg" v-if="attachment.type_id !== 7 && attachment.type_id !== 1" />
                                        <img src="../assets/icons/components/attachment-red.svg" v-else-if="attachment.type_id !== 7 && attachment.type_id == 1" />
                                        <img src="../assets/icons/components/note.svg" v-else />

                                        <a class="points-list__attachment-link" v-if="attachment.type_id == 6" @click="handleGoToLinkClick(attachment.url)" v-tooltip.bottom="'Przejdź do linku'">
                                            {{ attachment.name }}
                                        </a>
                                        <a class="points-list__attachment-link" download v-else @click="getFile(s.id, attachment.id, attachment.name)" v-tooltip.bottom="'Pobierz'">
                                            {{ attachment.type_id == 1 ? attachment.name + ' (uchw.)' : attachment.name }}
                                        </a>
                                    </div>
                                    <!-- exported ended votings -->
                                    <div class="points-list__attachment-wrapper img-voting" v-for="( exvoting, index ) in s.exported_votings_results" :key="index">
                                        <img src="../assets/icons/components/voting.svg" style="height: 17px;" />
                                        <a class="points-list__attachment-link__exported" @click="handleShowVotingResultsClick(exvoting.orginalPid, exvoting.id, exvoting.orginalSid)" v-tooltip.bottom="'Pokaż wyniki wyeksportowanego głosowania'">
                                            {{ exvoting.name }} {{exvoting.isPublic == false ? '(głosowanie tajne)' : ''}}
                                        </a>
                                    </div>
                                    <!-- ended votings -->
                                    <div class="points-list__attachment-wrapper img-voting" v-for="( voting, index ) in s.votings" :key="index">
                                        <img src="../assets/icons/components/voting.svg" style="height: 17px;" />
                                        <a class="points-list__attachment-link__green" @click="handleShowVotingResultsClick(s.id, voting.id, session.id)" v-tooltip.bottom="'Pokaż wyniki głosowania'">
                                            {{ voting.name }} {{voting.isPublic == false ? '(głosowanie tajne)' : ''}}
                                        </a>
                                    </div>


                                    <section v-for="(child) in s.children" :key="child.id">
                                        <section class="box-children">
                                            <section :class="child.is_active ? 'box__active' : 'box__top'" :id="'id-point-'+child.id">
                                                <section>
                                                    <div class="point-is-done" v-if="child.is_done">
                                                        <img class="point-is-done__img" src="../assets/icons/panel/editSession/meeting-time.svg" />
                                                        <span>Zakończony: {{ getTimeEndPoint(child) }}</span>
                                                    </div>
                                                    <div>
                                                        <span class="box__titlechildren" :class="{'box--takenoff' : child.is_taken_off}">{{filterIndex(child, 1)}} {{ child.name }}</span>

                                                        <div v-if="child.reporter_name != null && child.reporter_name.length > 0" class="referer">
                                                            <span>Referuje: <b>{{child.reporter_name}}</b></span>
                                                        </div>

                                                        <!-- attachements -->
                                                        <div v-for="( attachment, index ) in child.attachments" :key="index" class="points-list__attachment-wrapper">
                                                            <img src="../assets/icons/components/attachment.svg" v-if="attachment.type_id !== 7 && attachment.type_id !== 1" />
                                                            <img src="../assets/icons/components/attachment-red.svg" v-else-if="attachment.type_id !== 7 && attachment.type_id == 1" />
                                                            <img src="../assets/icons/components/note.svg" v-else />

                                                            <a class="points-list__attachment-link" v-if="attachment.type_id == 6" @click="handleGoToLinkClick(attachment.url)" v-tooltip.bottom="'Przejdź do linku'">
                                                                {{ attachment.name }}
                                                            </a>
                                                            <a class="points-list__attachment-link" download v-else @click="getFile(child.id, attachment.id, attachment.name)" v-tooltip.bottom="'Pobierz'">
                                                                {{ attachment.type_id == 1 ? attachment.name + ' (uchw.)' : attachment.name }}
                                                            </a>
                                                        </div>
                                                        <!-- exported ended votings -->
                                                        <div class="points-list__attachment-wrapper img-voting" v-for="( exvoting, index ) in child.exported_votings_results" :key="index">
                                                            <img src="../assets/icons/components/voting.svg" style="height: 17px;" />
                                                            <a class="points-list__attachment-link__exported" @click="handleShowVotingResultsClick(exvoting.orginalPid, exvoting.id, exvoting.orginalSid)" v-tooltip.bottom="'Pokaż wyniki wyeksportowanego głosowania'">
                                                                {{ exvoting.name }} {{exvoting.isPublic == false ? '(głosowanie tajne)' : ''}}
                                                            </a>
                                                        </div>
                                                        <!-- ended votings -->
                                                        <div class="points-list__attachment-wrapper img-voting" v-for="( voting, index ) in child.votings" :key="index">
                                                            <img src="../assets/icons/components/voting.svg" style="height: 17px;" />
                                                            <a class="points-list__attachment-link__green" @click="handleShowVotingResultsClick(child.id, voting.id, session.id)" v-tooltip.bottom="'Pokaż wyniki głosowania'">
                                                                {{ voting.name }} {{voting.isPublic == false ? '(głosowanie tajne)' : ''}}
                                                            </a>
                                                        </div>


                                                        <section v-for="(ch) in child.children" :key="ch.id">
                                                            <section class="box-children">
                                                                <section :class="ch.is_active ? 'box__active' : 'box__top'" :id="'id-point-'+ch.id">
                                                                    <section>
                                                                        <div class="point-is-done" v-if="ch.is_done">
                                                                            <img class="point-is-done__img" src="../assets/icons/panel/editSession/meeting-time.svg" />
                                                                            <span>Zakończony: {{ getTimeEndPoint(ch) }}</span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="box__titlechildren" :class="{'box--takenoff' : ch.is_taken_off}">{{filterIndex(ch, 2)}} {{ ch.name }}</span>
                                                                        </div>

                                                                        <div v-if="ch.reporter_name != null && ch.reporter_name.length > 0" class="referer">
                                                                            <span>Referuje: <b>{{ch.reporter_name}}</b></span>
                                                                        </div>

                                                                        <!-- attachements -->
                                                                        <div v-for="( attachment, index ) in ch.attachments" :key="index" class="points-list__attachment-wrapper">
                                                                            <img src="../assets/icons/components/attachment.svg" v-if="attachment.type_id !== 7 && attachment.type_id !== 1" />
                                                                            <img src="../assets/icons/components/attachment-red.svg" v-else-if="attachment.type_id !== 7 && attachment.type_id == 1" />
                                                                            <img src="../assets/icons/components/note.svg" v-else />

                                                                            <a class="points-list__attachment-link" v-if="attachment.type_id == 6" @click="handleGoToLinkClick(attachment.url)" v-tooltip.bottom="'Przejdź do linku'">
                                                                                {{ attachment.name }}
                                                                            </a>
                                                                            <a class="points-list__attachment-link" download v-else @click="getFile(ch.id, attachment.id, attachment.name)" v-tooltip.bottom="'Pobierz'">
                                                                                {{ attachment.type_id == 1 ? attachment.name + ' (uchw.)' : attachment.name }}
                                                                            </a>
                                                                        </div>
                                                                        <!-- exported ended votings -->
                                                                        <div class="points-list__attachment-wrapper img-voting" v-for="( exvoting, index ) in ch.exported_votings_results" :key="index">
                                                                            <img src="../assets/icons/components/voting.svg" style="height: 17px;" />
                                                                            <a class="points-list__attachment-link__exported" @click="handleShowVotingResultsClick(exvoting.orginalPid, exvoting.id, exvoting.orginalSid)" v-tooltip.bottom="'Pokaż wyniki wyeksportowanego głosowania'">
                                                                                {{ exvoting.name }} {{exvoting.isPublic == false ? '(głosowanie tajne)' : ''}}
                                                                            </a>
                                                                        </div>
                                                                        <!-- ended votings -->
                                                                        <div class="points-list__attachment-wrapper img-voting" v-for="( voting, index ) in ch.votings" :key="index">
                                                                            <img src="../assets/icons/components/voting.svg" style="height: 17px;" />
                                                                            <a class="points-list__attachment-link__green" @click="handleShowVotingResultsClick(ch.id, voting.id, session.id)" v-tooltip.bottom="'Pokaż wyniki głosowania'">
                                                                                {{ voting.name }} {{voting.isPublic == false ? '(głosowanie tajne)' : ''}}
                                                                            </a>
                                                                        </div>


                                                                    </section>
                                                                </section>
                                                            </section>
                                                        </section>
                                                    </div>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </div>

            <InteractiveVotingResults v-if="resultsVotingVisible"
                                      :basicData="resultsVotingData"
                                      :isPublicView="true"
                                      :votingResults="res"
                                      :chartData="chartData"
                                      :canClose="true"
                                      :fromEndVoting="false"
                                      @closeResultVotingt ="closeResultVotingt"
                                      />

        </section>
    </section>
</template>

<script>
    import InteractiveVotingResults from "../components/Panel/Content/SessionPoints/Modals/InteractiveVotingResults.vue"
    import PublicService from "../services/public.service";
    import moment from "moment";
    import AlertHelper from '@/helpers/alerts.helper.js';
    import { encryptStorage } from "../services/encrypt.service"
    import PointsNumerationHelper from "@/helpers/pointsNumeration.helper";

export default {
    name: "PublicSessionInfo",
    data() {
        return {
            session: {},
            code: '',
            error: false,
            client: '',
            resultsVotingVisible: false,
            hiddenResults: false,
            resultsVotingData: {},
            elemToDispay: '',
            res: {},
            logo: '',
            numerations: {},
            chartData: {
                    labels: [],
                    datasets: [{ data: [] }]
            },
        };
    },
    components: {
        InteractiveVotingResults,
    },
    computed: {
        getDate() {
            return `${moment(this.session.meeting_date).format("dddd")}, ${moment(this.session.meeting_date).format("DD.MM.YYYY")}`;
        },
        getTime() {
            return moment(this.session.meeting_date).format("LT");
        },
    },
    mounted() {
        this.error = false;
        this.$store.commit("showLoader");
        this.code = this.$route.params.code;

        if (this.code != undefined && this.code != null && this.code.length == 15) {
            PublicService.getPublicSessionInfo(this.code).then((response) => {
                this.session = response.data.session;
                this.session.agendaItems = PointsNumerationHelper.setOrderToDisplayRecursive([...this.session.agendaItems]);
                this.client = response.data.client;
                this.numerations = response.data.numerations;

                encryptStorage.setItem('sfiv', response.data.tk);

                if (response.data.logo != null && response.data.logo.image != null) {
                    this.logo = response.data.logo.image;
                }
                else {
                    this.logo = '';
                }

            }).catch((err) => {
                this.session = {};
                this.client = '';
                this.error = true;
                if (err.response.status == 404)
                    this.$swal(AlertHelper.errorAlert("Brak wskazanego posiedzenia lub posiedzenie tymczasowo ukryte"));
                else
                    this.$swal(AlertHelper.errorAlert("Wystąpił błąd - sprawdź dane dostępowe"));
            });
        }
        else {
            this.session = {};
            this.error = true;
            this.$swal(AlertHelper.errorAlert("Błędne dane identyfikacyjne"));
        }
        this.$store.commit("hideLoader");
    },
    methods: {
        filterIndex(agendaItem, level) {

            return PointsNumerationHelper.filterIndex(agendaItem.orderToDisplay, agendaItem.number, level, this.numerations.agenda_number_format_1_id, this.numerations.agenda_number_format_2_id, this.numerations.agenda_number_format_3_id);
        },
        getTimeEndPoint(item) {
            if (item.is_done) {
                return moment(item.done_at).format("DD-MM-YYYY, HH:mm");
            }
        },
        formatAnswersColor(result) {
            if (result != null && result.length > 0) {
                switch (result) {
                    case 'ZA':
                    case 'TAK':
                        return '#79e563';
                    case 'PRZECIW':
                    case 'NIE':
                        return '#e40030';
                    case 'WSTRZYMUJĘ SIĘ':
                    case 'NIE WIEM':
                        return '#ffce56';
                    case 'BRAK GŁOSU':
                        return '#c0c0c0';
                    case 'NIEOBECNI':
                    case 'NIEOBECNY':
                        return '#000';
                    case 'WYKLUCZENI':
                        return '#363532';
                    default:
                        return '#c0c0c0';
                }
            }
        },
        handleGoToLinkClick(item) {
            window.open(item, '_blank');
        },
        async getFile(pid, id, name) {
            try {

                var data = {
                    id: id,
                    pid: pid,
                    tk: encryptStorage.getItem('sfiv')
                }

                var result = (await PublicService.downloadAttachmentPublicInfo(data));
                if (result.status == 200) {
                    const contentType = result.headers['content-type'];
                    var a = document.createElement('a');
                    var blob = new Blob([result.data], { 'type': contentType });
                    a.href = window.URL.createObjectURL(blob);
                    a.download = name;
                    a.click();
                }

            } catch (e) {
                console.log(e);
            }
        },
        async handleShowVotingResultsClick(point, voting, sessionId) {
            try {

                var data = {
                    id: voting,
                    pid: point,
                    sid: sessionId,
                    tk: encryptStorage.getItem('sfiv')
                };

                let result = await PublicService.getVotingInteractiveDataForResults(data)

                if (result.status == 200) {
                    this.resultsVotingData = result.data;

                    var data2 = {
                        id: voting,
                        mode: this.resultsVotingData.mode.id,
                        public: this.resultsVotingData.isPublic,
                        type: this.resultsVotingData.gType,
                        tk: encryptStorage.getItem('sfiv')
                    };

                    var resultVoting = (await PublicService.getVotingsSummaryResults(data2));
                    if (resultVoting.status == 200) {
                        this.res = resultVoting.data;
                        var tempdata = [];

                        if (this.res != null) {
                            this.chartData.datasets[0].backgroundColor = []
                            for (var i = 0; i < this.res.options.length; i++) {
                                this.chartData.labels.push(this.res.options[i].option);
                                tempdata.push(this.res.options[i].count);
                                this.chartData.datasets[0].data = tempdata;
                                this.chartData.datasets[0].backgroundColor.push(this.formatAnswersColor(this.res.options[i].option));
                            }
                        }
                        this.resultsVotingVisible = true;
                    }
                }

            } catch (e) {
                console.log(e)
            }
        },
        closeResultVotingt() {
            this.resultsVotingData = {};
            this.resultsVotingVisible = false;
            this.chartData = {
                labels: [],
                datasets: [{ data: [] }]
            };
        }
    }
};
</script>

<style lang="scss" scoped>

    @import "../assets/scss/breakpoints.scss";

    .referer {
        font-weight: normal;
        font-size: 14px;
        color: #333956;
        padding-top: 15px;
    }

    .point-is-done {
        font-size: 14px;
        height: 30px;
        float: right;
        min-width: 180px;
        text-align: right;

        &__img {
            margin-right: 5px;
            vertical-align: middle;
        }
    }

    .header {
        width: 100%;
        box-sizing: border-box;
        padding: 26px 45px 23px 49px;
        justify-content: space-between;
        align-items: center;
        background: radial-gradient( 235.38% 418.45% at 0% 0%, #00082c 0%, #004dcc 100% );
        position: sticky;
        top: 0px;
        z-index: 6;

        &::after {
            content: "";
            position: absolute;
            background: #dde0e7;
            width: 100%;
            height: 1px;
            bottom: -1px;
            left: 0px;
        }
    }

    .header-left {
        display: flex;
        align-items: center;

        &__icon {
            margin-right: 11px;
        }

        &__text {
            font-size: 16px;
            line-height: 144%;
            color: #333956;

            &--bold {
                font-weight: 600;
            }

            &--link {
                color: #d80418;
                text-decoration: underline;
                font-weight: 600;
            }
        }

        @media only screen and (max-width:1850px) {
            width: 100%;
        }
    }

    .header-right {
        display: flex;
        align-items: center;

        &__text {
            font-weight: 600;
            font-size: 24px;
            color: white;
            margin-left: auto;
            margin-right: 0;
        }
    }

    .logo {
        width: 80px;
        margin-left: auto;
    }

    .container {
        margin: 49px 48px;

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 20px;
        }
    }

    .sesion-container-wrapper {
        display: flex;
        flex-direction: row;
    }

    .session-agenda {
        flex-grow: 1;
    }

    .disscusion-list {
        min-width: 300px;
        padding-left: 15px;
    }

    .box {
        background: #ffffff;
        width: 100%;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        margin-bottom: 12px;
        transition: all 0.2s ease-in-out;

        &__title {
            font-size: 1.7em;
            font-weight: 500;
            margin-right: 16px;
            line-height: 132%;
            color: #00082c;
            overflow-wrap: break-word;
            word-break: break-word;
        }

        &__top {
            align-items: center;
            padding: 29px;
            justify-content: space-between;
            font-size: 16px;
        }

        &__active {
            align-items: center;
            padding: 29px;
            justify-content: space-between;
            border: 1px solid green;
            background: #f7fff7;
            border-radius: 12px;
            font-size: 24px;
        }

        &--takenoff {
            text-decoration: line-through !important;
        }
    }

    .box-children {
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        margin: 12px 12px 0px 32px;
        transition: all 0.2s ease-in-out;

        &__titlechildren {
            margin-left: 32px;
            line-height: 132%;
            color: #00082c;
            text-align: justify;
            word-break: break-word;
            white-space: normal;
            font-size: 1.6em;
            font-weight: 500;
        }
    }

    .session {
        &__top-left {
            display: flex;
            background-color: #e8ecf2;
            border-radius: 10px;
            margin: 0px 0px 20px 0px;
            text-align: center;
        }

        &__top-box {
            display: flex;
            align-items: center;
            padding: 14px 32px;
            position: relative;

            &:nth-child(1)::after,
            &:nth-child(2)::after,
            &:nth-child(3)::after {
                content: "";
                position: absolute;
                top: 12px;
                right: 0px;
                width: 1px;
                height: 24px;
                background-color: #d1d6df;
            }
        }

        &__top-text {
            margin-left: 11px;
        }
    }

    .points-list {
        width: 100%;
        position: relative;

        &__point-box-left {
            display: flex;
            flex-direction: column;
            min-height: 41px;
        }

        &__arrow {
            position: absolute;
            top: -50px;
            left: -37px;
            display: block;

            &--next {
                top: -120px;
            }
        }

        &__arrow-next {
            position: absolute;
            top: 60px;
        }

        &__attachments {
            margin-left: 50px;
            align-items: center;
            flex-wrap: wrap;



            &__drag-votng {
                padding: 0;
            }

            &__drag-attachment {
                padding: 0;
            }
        }

        &__attachment-link {
            font-weight: normal;
            font-size: 14px;
            color: #333956;
            cursor: pointer;
            margin-left: 11px;

            &:hover {
                text-decoration-line: underline;
                color: #808396;
            }

            &__green {
                color: #4cad39;
                font-size: 14px;
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;
                margin-left: 11px;
            }

            &__exported {
                color: #1d51b9;
                font-size: 14px;
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;
                margin-left: 11px;
            }
        }

        &__attachment-wrapper {
            margin-right: 42px;
            display: flex;
            align-items: center;
            position: relative;
            margin-top: 10px;

            &:nth-child(1)::after {
                width: 0px;
                height: 0px;
            }

            &:after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -21px;
                width: 1px;
                height: 16px;
                background: #dde0e7;
            }
        }

        &__actions {
            opacity: 0;
            transition: all 0.2s ease-in-out;
            display: flex;
            align-items: flex-start;
        }

        &__actions_right-corner {
            display: none;
        }

        &__point-done {
            display: flex;
            align-items: flex-start;
            height: 41px;
        }

        &__data-wrapper {
            display: flex;
            position: relative;
            width: 100%;
        }

        &__checkbox {
            position: relative;
            display: flex;
        }

        &__checkbox-label {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 6;
        }

        &__point-box {
            padding: 18px;
            padding-bottom: 18px;
            border: 1px solid #dce4ee;
            box-sizing: border-box;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            transition: all 0.2s ease-in-out;
            width: 100%;
            cursor: move;
            position: relative;

            &--selected {
                border: 1px solid #00082c;

                &:hover {
                    border-color: #00082c !important;
                }
            }

            &--hide {
                border: 1px solid red !important;

                &:hover {
                    border-color: red;
                }
            }

            &--active {
                border: 1px solid green !important;

                &:hover {
                    border-color: green;
                }
            }

            &:hover .points-list__actions {
                opacity: 1;
            }

            &:hover .points-list__actions_right-corner {
                display: flex;
                height: 41px;
            }

            &:hover .points-list__point-done {
                display: none;
            }

            &:hover {
                border-color: #bbc2ce;
            }

            &--no-border {
                padding: 10px 10px 0px 10px;
                border: 0px;
                margin: 0px !important;
            }
        }

        &__number {
            margin-left: 48px;
        }

        &__name {
            font-weight: normal;
            font-size: 16px;
            line-height: 132%;
            overflow-wrap: anywhere;

            &--bold {
                font-weight: 600;
                font-size: 18px;
                line-height: 132%;
                color: #00082c;
            }

            &--pointtakenoff {
                text-decoration: line-through !important;
            }
        }

        &__number {
            font-weight: 600;
            margin-right: 8px;
        }

        &__file-input {
            display: none;
        }

        &__action-btn {
            width: 32px;
            height: 32px;
            margin-left: 8px;
            background-color: white;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 1px solid hsla(354, 96%, 43%, 0.317);
            }
        }


        &__action-btn-text {
            width: 32px;
            height: 32px;
            margin-left: 8px;
            background-color: white;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;


            img {
                width: 2.2em
            }

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 1px solid hsla(354, 96%, 43%, 0.317);
            }
        }

        &__action-btnatt {
            width: 25px;
            height: 25px;
            margin-left: 8px;
            background-color: white;
            border: none;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }
        }
    }

</style>
