<template>
    <router-link class="meeting-box" :to="{ name: 'sessionEdit', params: { sessionid: id, groupid: groupid },}">
        <section class="meeting-box__left">
            <h4 class="meeting-box__title">
                {{ name }}
                <span v-if="number"> nr {{number !== null ? numberConversion(number) : "0"}} </span>
                <span class="groups">{{getGroupsNames(groups)}}</span>
            </h4>
        </section> 
        <section class="meeting-box__right">
            <section class="meeting-box__info-box">
                <img src="../../../../../assets/icons/panel/home/callendar.svg" class="meeting-box__icon" />
                <span class="meeting-box__info-text meeting-box__info-text--date">{{ getDate(dateTime, endDateTime) }},  {{ getTime(dateTime) }}</span>
                <img v-if="!isPublic && !isAuthorized" src="../../../../../assets/icons/panel/home/eye-slash.svg" class="meeting-box__icon"  style="color:red" v-tooltip="'Posiedzenie ukryte'" />
                <img v-if="!isPublic && isAuthorized" src="../../../../../assets/icons/panel/home/eye.svg" class="meeting-box__icon" v-tooltip="'Widoczne dla użytkowników'" />
                <img v-if="isPublic" src="../../../../../assets/icons/panel/home/globe.svg" class="meeting-box__icon" v-tooltip="'Posiedzenie publiczne'" />
            </section>
            <!--<section class="meeting-box__info-box">
                <img src="../../../../../assets/icons/panel/home/time.svg"
                     class="meeting-box__icon" />
                <span class="meeting-box__info-text">Godzina:</span>
            </section>-->
        </section>
    </router-link>
</template>

<script>
    import moment from "moment";
    import {
        switchToRoman,
        switchIndexToLetter,
    } from "../../../../../helpers/index.conversion";

    export default {
        name: "Meeting",
        components: {
        },
        props: {
            name: String,
            number: Number,
            dateTime: String,
            endDateTime: String,
            id: Number,
            groupid: Number,
            groupNumeration: Number,
            groups: Array,
            isPublic: Boolean,
            isAuthorized: Boolean
        },
        methods: {
            getGroupsNames(groups) {
                if (groups != null && groups.length > 0) {
                    var grName = '(';
                    for (var i = 0; i < groups.length; i++) {
                        grName += groups[i].name + ', ';
                    }
                    grName = grName.replace(/,\s*$/, "");
                    grName += ')';

                    return grName;
                }

                return '';
            },
            getDate(dateTime, endDateTime) {

                if (endDateTime !== null) {
                    let splitedMeetingDate = dateTime.split("T")[0];
                    let splitedEndMeetingDate = endDateTime.split("T")[0];
                    let meetingDate = new Date(splitedMeetingDate);
                    let endMeetingDate = new Date(splitedEndMeetingDate);
                    let diff = Math.abs(endMeetingDate - meetingDate);
                    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

                    if (diffDays === 0)
                        return `${moment(dateTime).format("dddd")}, ${moment(dateTime).format("DD.MM.YYYY")}`;
                    else
                        return `${moment(dateTime).format("dddd")}, ${moment(dateTime).format("DD.MM")} - ${moment(endDateTime).format("DD.MM.YYYY")}`;
                }
                else {
                    return `${moment(dateTime).format("dddd")}, ${moment(dateTime).format("DD.MM.YYYY")}`;
                }

                //const day = moment(dateTime).format("dddd");
                //const full_date = moment(dateTime).format("DD.MM.YYYY");

                //return `${day}, ${full_date}`;
            },
            getTime(dateTime) {
                const hour = moment(dateTime).format("LT");
                return hour;
            },
            numberConversion(index) {
                switch (this.groupNumeration) {
                    case 1:
                        return `${switchToRoman(index)}`;
                    case 2:
                        return `${index}.`;
                    case 3:
                        return `${index})`;
                    case 4:
                        return `${switchIndexToLetter(index)})`;
                    default:
                        return `${index}.`;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>

    .groups {
        font-weight: 300;
        font-size: 0.8em;
        margin-left: 10px;
    }

    .red {
        color: red;
    }

    .meeting-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 12px;
        padding: 19px 28px;
        margin-top: 12px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: #f4f6fa;
            border: 1px solid #bbc2ce;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        }

        &__title {
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
        }

        &__under-text {
            font-size: 14px;
            line-height: 145%;
            color: #333956;
        }

        &__right {
            display: flex;
        }

        &__info-box {
            margin-left: 41px;
            display: flex;
            align-items: center;
        }

        &__icon {
            width: 18px;
            height: 18px;
            margin-left: 8px;
           
        }

        &__info-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-left: 11px;

            &--date::first-letter {
                text-transform: uppercase;
            }
        }
    }
</style>
