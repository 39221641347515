export const contactsStore = {
    state: () => ({
        selectedPeopleToSendMessage: [],
        sendMessageSuccess: false,
    }),
    mutations: {
        setSelectedPeopleToSendMessage(state, status) {
            state.selectedPeopleToSendMessage = status;
        },
        setSendMessageSuccess(state, status) {
            state.sendMessageSuccess = status;
        }
    }
}