import { uploaderConfig } from "../config/uploader.file.config";
import ApiService from "./api.service";
import { generateUUID, formatBytes } from "../helpers/utils";
import mainStore from "../store/mainStore";
import UploaderFileState from "./uploader.file.state";
import UploaderFileValidationEnum from "./uploader.file.validation.enum";

class UploaderService extends ApiService {

    async UploadPointAttachements(pointId, event, isResolution) {
        try {

            this._clearFilesToUpload();
            let url = `Session/agenda-items/${pointId}/attachment/file`;

            const files = [...event.target.files]

            mainStore.commit('setUploaderModalStauts', true);
            mainStore.commit('setUploaderFileDictionary', this._createUploadFileDictionary(files));
            this._validateSelectedFiles(uploaderConfig.session.allowerMimeType, uploaderConfig.global.maxUploadFileSize);

            for (const [key, value] of Object.entries(mainStore.state.uploaderStore.fileUploadDictionary)) {
                try {

                    // skip files with validations errors
                    if (value.errors.length > 0) {
                        mainStore.state.uploaderStore.fileUploadDictionary[key].status = { ...UploaderFileState.UploadFail }
                        continue
                    }

                    let fd = new FormData();
                    fd.append("resolution", isResolution);
                    fd.append("files", value.file);
                    fd.append("name", value.file.name);
                    let response = await this._uploadFile(url, fd, this._onUploadProgress, key);

                    await this._setUploadFileStatus(response, key);
                }
                catch {
                    mainStore.state.uploaderStore.fileUploadDictionary[key].status = { ...UploaderFileState.UploadFail }
                }
            }
        }
        catch (e) {
            console.log("UploadPointAttachements", e);
        }
    }

    async UploadAnnouncementAttachements(annoucementId, event) {
        try {
            this._clearFilesToUpload();
            let url = `Message/announcement/attachment/file`;

            const files = [...event.target.files]

            mainStore.commit('setUploaderModalStauts', true);
            mainStore.commit('setUploaderFileDictionary', this._createUploadFileDictionary(files));
            this._validateSelectedFiles(uploaderConfig.announcement.allowerMimeType, uploaderConfig.global.maxUploadFileSize);

            let uploadedFiles = [];
            for (const [key, value] of Object.entries(mainStore.state.uploaderStore.fileUploadDictionary)) {
                try {
                    // skip files with validations errors
                    if (value.errors.length > 0) {
                        mainStore.state.uploaderStore.fileUploadDictionary[key].status = { ...UploaderFileState.UploadFail }
                        continue
                    }

                    let fd = new FormData();
                    fd.append("kid", annoucementId);
                    fd.append("files[0]", value.file);
                    let response = await this._uploadFile(url, fd, this._onUploadProgress, key);

                    await this._setUploadFileStatus(response, key);

                    if (response.data?.length === 1)
                        uploadedFiles.push(response.data[0]);
                }
                catch {
                    mainStore.state.uploaderStore.fileUploadDictionary[key].status = { ...UploaderFileState.UploadFail }
                }
            }

            return uploadedFiles;
        }
        catch (e) {
            console.log("UploadAnnouncementAttachements", e);
        }
    }

    async UploadInterpelationAttachements(interpelationId, interpelationPersonId, event, isReponse = false) {
        try {
            this._clearFilesToUpload();
            let url = `Interpellation/addInterpellationAttachement`;

            const files = [...event.target.files]

            mainStore.commit('setUploaderModalStauts', true);
            mainStore.commit('setUploaderFileDictionary', this._createUploadFileDictionary(files));
            this._validateSelectedFiles(uploaderConfig.interpellation.allowerMimeType, uploaderConfig.global.maxUploadFileSize);

            let uploadedFiles = [];
            for (const [key, value] of Object.entries(mainStore.state.uploaderStore.fileUploadDictionary)) {
                try {
                    // skip files with validations errors
                    if (value.errors.length > 0) {
                        mainStore.state.uploaderStore.fileUploadDictionary[key].status = { ...UploaderFileState.UploadFail }
                        continue
                    }

                    let fd = new FormData();
                    fd.append("id", interpelationId);
                    fd.append("lid", interpelationPersonId);
                    fd.append("response", isReponse);
                    fd.append("files[0]", value.file);
                    let response = await this._uploadFile(url, fd, this._onUploadProgress, key);

                    await this._setUploadFileStatus(response, key);

                    uploadedFiles.push(response.data);
                }
                catch {
                    mainStore.state.uploaderStore.fileUploadDictionary[key].status = { ...UploaderFileState.UploadFail }
                }
            }

            return uploadedFiles;
        }
        catch (e) {
            console.log("UploadAnnouncementAttachements", e);
        }
    }

    async UploadDocument(groupId, event) {
        try {
            this._clearFilesToUpload();
            let url = `Tools/addNewDocument`;

            const files = [...event.target.files]

            mainStore.commit('setUploaderModalStauts', true);
            mainStore.commit('setUploaderFileDictionary', this._createUploadFileDictionary(files));
            this._validateSelectedFiles(uploaderConfig.document.allowerMimeType, uploaderConfig.global.maxUploadFileSize);
            let uploadedFiles = [];

            for (const [key, value] of Object.entries(mainStore.state.uploaderStore.fileUploadDictionary)) {
                try {
                    // skip files with validations errors
                    if (value.errors.length > 0) {
                        mainStore.state.uploaderStore.fileUploadDictionary[key].status = { ...UploaderFileState.UploadFail }
                        continue
                    }

                    let fd = new FormData();
                    fd.append("grid", groupId);
                    fd.append("files[0]", value.file);
                    let response = await this._uploadFile(url, fd, this._onUploadProgress, key);

                    await this._setUploadFileStatus(response, key);

                    uploadedFiles.push(response.data);
                }
                catch {
                    mainStore.state.uploaderStore.fileUploadDictionary[key].status = { ...UploaderFileState.UploadFail }
                }
            }

            return uploadedFiles;

        }
        catch (e) {
            console.log("UploadDocument", e);
        }
    }

    async _uploadFile(url, req, onProgress, fileId) {
        let config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: progressEvent => {
                var percentCompleted = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                if (onProgress) onProgress(percentCompleted, progressEvent.loaded, progressEvent.total, fileId);

                return percentCompleted;
            }
        }
        return await this.post(url, req, config);
    }

     async _setUploadFileStatus(response, fileId) {
        if (response.status === 200) {
            mainStore.state.uploaderStore.fileUploadDictionary[fileId].status = { ...UploaderFileState.UploadSuccess }
        }
        else {
            mainStore.state.uploaderStore.fileUploadDictionary[fileId].status = { ...UploaderFileState.UploadFail }
            await Promise.reject(response)
        }
    }

    _clearFilesToUpload() {
        mainStore.state.uploaderStore.fileUploadDictionary = {};
    }

    _createUploadFileDictionary(files) {
        let dict = {}
        for (let file of files) {
            dict[generateUUID()] = {
                file: file,
                loaded: 0,
                total: file.size,
                percent: 0,
                status: { ...UploaderFileState.UploadWaiting },
                errors: [],
            }
        }
        return dict;
    }

    _onUploadProgress(percent, loaded, total, fileId) {
        try {
            if (mainStore.state.uploaderStore.fileUploadDictionary[fileId] === undefined || mainStore.state.uploaderStore.fileUploadDictionary[fileId] === null) {
                return;
            }

            mainStore.state.uploaderStore.fileUploadDictionary[fileId].status = { ...UploaderFileState.UploadProgres };
            mainStore.state.uploaderStore.fileUploadDictionary[fileId].loaded = loaded;
            mainStore.state.uploaderStore.fileUploadDictionary[fileId].total = total;
            mainStore.state.uploaderStore.fileUploadDictionary[fileId].percent = percent;
        }
        catch (e) {
            console.log(e);
        }
    }

    // generic file validator
    _validateSelectedFiles(acceptedMimeType, maxAllowedSize) {
        for (const [key, value] of Object.entries(mainStore.state.uploaderStore.fileUploadDictionary)) {

            let errors = this._validateSelectedFile(value.file, acceptedMimeType, maxAllowedSize);

            mainStore.state.uploaderStore.fileUploadDictionary[key].errors = [...errors];
        }
    }

    _validateSelectedFile(file, acceptedMimeType, maxAllowedSize) {
        if (file === undefined || file === null) {
            return [];
        }

        let errors = [];
        if (file.size > maxAllowedSize) {
            errors.push(this._createValidateObjectError(UploaderFileValidationEnum.FileToBig, formatBytes(maxAllowedSize)))
        }

        if (file.name === undefined || file.name === null || file.name.length <= 4) {
            errors.push(this._createValidateObjectError(UploaderFileValidationEnum.FileNameToShortError, 1))
        }

        if (file.name.length > 200) {
            errors.push(this._createValidateObjectError(UploaderFileValidationEnum.FileNameToLongError, 200))
        }

        let fileType = file.type;

        if (!fileType) {
            fileType = this._getMimeTypeByFileExtensions(file);
        }

        if (acceptedMimeType.includes(fileType) === false) {
            errors.push(this._createValidateObjectError(UploaderFileValidationEnum.UnsupportedMediaType, acceptedMimeType))
        }

        return errors;
    }

    _createValidateObjectError(rule, ...args) {
        if (args.length === 1) {
            rule.description = rule.description.replace("{0}", args[0]);
        }
        else {
            let stringArgs = args.join(', ');
            rule.description = rule.description.replace("{0}", stringArgs);
        }

        return {
            rule: rule
        }
    }

    _getMimeTypeByFileExtensions(file) {
        try {
            if (!file?.name) {
                return "";
            }

            let fileExtensions = file.name
                .split(".")
                .pop()
                .toLowerCase();

            switch (fileExtensions) {
                case "zipx": return "application/octet-stream";
                default: return "";
            }
        }
        catch {
            return "";
        }


    }
}

export default new UploaderService();