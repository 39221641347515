import ApiService from "./api.service";

class RegisterService extends ApiService {
    getSessionsForRegister() {
        return this.get(`Register/getSessionsForRegister`);
    }

    getRegistryForSession(id) {
        return this.get(`Register/getRegistryForSession/${id}`)
    }

    getRegisterByAttachementId(id, pointId) {
        return this.get(`Register/getRegisterByAttachementId/${id}/${pointId}`)
    }

    saveRegister(attId, pointId, sid, data) {
        return this.post(`Register/saveRegister/${attId}/${pointId}/${sid}`, data)
    }

    getRegister(id) {
        return this.get(`Register/getRegister/${id}`)
    }

    updateRegister(id, pointId, data) {
        return this.post(`Register/updateRegister/${id}/${pointId}`, data)
    }

    searchRegister(data) {
        return this.post(`Register/search`, data)
    }
}

export default new RegisterService();
