<template>
    <section class="check-quourm-overlay">
        <section class="check-quorum-container">
            <header class="check-quorum-container__header">
                <section class="check-quorum-container__header__action">
                    <button v-if="!isPublicView" class="check-quorum-container__header__action__exit-btn"
                            @click="closeModal()">
                        <img src="../../../../assets/icons/components/exit-btn.svg" />
                    </button>
                </section>
            </header>

            <section class="check-quorum-container__title">
                <span class="modal-title">
                    Wyniki kworum
                </span>
            </section>
            <section class="check-quorum-container__body">
                <div class="user-quorum-container">
                    <div class="user-quorum-container__item" v-for="(item, index) in quorumresult" :key="index" :class="{'user-presence': item.isPresence}">
                        <div class="user-quorum-container__item__avatar">
                            <img :src="getUserAvata(item.id)" :alt="`${item.surname} ${item.name}`" />
                        </div>
                        <div class="user-quorum-container__item__data">
                            <span class="username">{{item.surname}} {{item.name}}</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="check-quorum-container__footer">
                <div class="check-quorum-container__footer__presence">
                    <div class="presence-type">
                        Obecni:
                    </div>
                    <div class="presence-count">
                        {{quorumresult.filter(obj => {return obj.isPresence}).length}} ({{calculatePertencage(quorumresult.filter(obj => {return obj.isPresence}).length)}}%)
                    </div>
                </div>
                <div class="check-quorum-container__footer__absent">
                    <div class="presence-type">
                        Nieobecni:
                    </div>
                    <div class="presence-count">
                        {{quorumresult.filter(obj => {return !obj.isPresence}).length}} ({{calculatePertencage(quorumresult.filter(obj => {return !obj.isPresence}).length)}}%)
                    </div>
                </div>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import CommonHelper from '@/helpers/common.helper.js';
    export default {
        name: "QuorumResultModal",
        components: {},
        computed: {
  
        },
        props: {
            quorumresult: Array,
            isPublicView: Boolean
        },
        data() {
            return {
                avatarVersion: CommonHelper.getRandomVersionImageForUrlRefresh(0, 100)
            }
        },
        mounted() {
            setTimeout(() => {
                this.autoScroll();
            }, 3000);
        },
        methods: {
            getUserAvata(uid) {
                return `https://api.esesja.pl/users/${uid}.jpg${this.avatarVersion}`;
            },
            calculatePertencage(value) {
                if (value > 0 && this.quorumresult.length > 0) {
                    var all = this.quorumresult.length;
                    return Math.round((value / all) * 100);
                }
            },
            closeModal() {
                this.$emit('closeResultCheckQuorumModal');
            },
            autoScroll() {
                try {
                    let domElement = document.querySelector(".user-quorum-container");
                    let childrens = domElement.children;
                    let lastDomElement = childrens[childrens.length - 1];
                    lastDomElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .check-quourm-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        padding: 20px;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        overflow: hidden;
        z-index: 9;

        .check-quorum-container {
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-height: 90vh;
            padding-bottom: 0px;
            padding-right: 0px;
            width: 90vw;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            &__header {
                padding: 20px;

                &__action {
                    &__exit-btn {
                        border: 0px;
                        background-color: transparent;
                        position: relative;
                        right: calc(-100% + 48px);
                    }
                }
            }

            &__title {
                display: flex;
                justify-content: center;
                font-size: 30px;
            }

            &__body {
                max-height: calc(90vh - 170px);
                overflow-y: auto;
                padding: 15px;

                &::-webkit-scrollbar {
                    width: 12px;
                    height: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #b5b8bd;
                    border-radius: 30px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #b5b8bd;
                }

                &::-webkit-scrollbar-track {
                    background: #ffffff;
                    border: 0.8px solid #dce4ee;
                    border-radius: 30px;
                    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
                }

                .user-quorum-container {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    row-gap: 10px;
                    justify-content: center;

                    &__item {
                        width: 250px;
                        display: flex;
                        column-gap: 10px;
                        /*cursor: pointer;*/
                        align-items: center;
                        background: #f2f2f2;
                        transition: 0.1s;

                        &__avatar img {
                            width: 70px;
                            vertical-align: middle;
                        }

                        &__data .username {
                            font-size: 20px;
                        }

                    }

                    &__item.user-presence {
                        background: #caffc1;
                    }
                }
            }

            &__footer {
                display: flex;
                justify-content: center;
                column-gap: 20px;
                font-size: 25px;
                padding: 10px 0px;
                border-top: 1px solid #dde0e7;
                ;

                &__presence, &__absent {
                    display: flex;
                    column-gap: 10px;
                }

                .presence-count {
                    font-weight: bold;
                }
            }
        }
    }
</style>