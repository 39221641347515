<template>
    <button :type="type" :class="buttonClass" :disabled="disable">
        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
        <slot></slot>
    </button>
</template>
<script charset="utf-8">
    export default {
        name: "ButtonBasic",
        props: {
            size: {
                type: String,
                default: ""
            },
            type: {
                type: String,
                default: "button"
            },
            color: {
                type: String,
                default: "primary"
            },
            disable: {
                type: Boolean,
                default: false
            },
            isLoading: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            buttonClass() {
                let btnClass = "btn";

                switch (this.color) {
                    case "primary": btnClass += " btn-primary"; break;
                    case "secondary": btnClass += " btn-secondary"; break;
                    case "success": btnClass += " btn-success"; break;
                    case "danger": btnClass += " btn-danger"; break;
                    case "warning": btnClass += " btn-warning"; break;
                    case "info": btnClass += " btn-info"; break;
                    case "light": btnClass += " btn-light"; break;
                    case "dark": btnClass += " btn-dark"; break;
                    case "link": btnClass += " btn-link"; break;
                    default: btnClass += " btn-primary"; break;
                }

                switch (this.size) {
                    case "lg": btnClass += " btn-lg"; break;
                    case "md": btnClass += " btn-md"; break;
                    case "sm": btnClass += " btn-sm"; break;
                }
                return btnClass;
            }
        }
    }
</script>
<style lang="scss">
    .btn-danger {
        img {
            filter: brightness(0) invert(1) !important;
        }
    }
</style>