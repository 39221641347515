<template>
    <aside class="side-bar" :class="{ 'collapsed-menu' : !humburgerMenuStatus }">
        <section class="side-bar__top">
            <router-link :to="{name: 'home'}" class="side-bar__top__anchor">
                <img src="../../../assets/imgs/logo.png"
                     class="side-bar__top__logo" />
                <!--<span class="side-bar__top__version"></span>-->
            </router-link>
            <nav class="side-bar__navigation">
                <section v-for="link in checkActionIsEnabled(categoryLink)" :key="link.id">
                    <NavBlock :blockTitle="link.name"
                              :links="link.links"
                              :additionalMenu="link.additionalMenu" />
                </section>
            </nav>
        </section>
        <section class="side-bar__bottom">
            <!--<section class="link-button link-button--left link-button link-button--left--red" @click="() => { $store.commit('setReportBugModalStatus', true); }">
                <img src="../../../assets/icons/navMenu/bug.svg"
                     class="link-button__icon" /><span class="link-button__link">Zgłoś błąd / sugestię</span>
            </section>-->
            <section class="link-button link-button--left" @click="goToServerStatus">
                <img src="../../../assets/icons/navMenu/server-white.svg"
                     class="link-button__icon" /><span class="link-button__link">Status serwerów</span>
            </section>
            <section class="link-button link-button--left" @click="goToHelpPage">
                <img src="../../../assets/icons/navMenu/help.svg"
                     class="link-button__icon" /><span class="link-button__link">Instrukcja obsługi</span>
            </section>
            <section class="link-button link-button--left" @click="logOut">
                <img src="../../../assets/icons/navMenu/logout.svg"
                     class="link-button__icon" /><span class="link-button__link">Wyloguj</span>
            </section>
        </section>
    </aside>
</template>

<script charset="utf-8">
    import NavBlock from "./NavBlock.vue";
    import { NavLinksData } from "../../../componentsData/navData.js";
    import UserService from "../../../services/user.service";
    import InterpellationService from "../../../services/interpellation.service";
    import { encryptStorage } from '../../../services/encrypt.service';

    export default {
        name: "NavMenu",
        data() {
            return {
                categoryLink: NavLinksData,
                hamburgerMenuToogle: false,
            };
        },
        computed: {

            humburgerMenuStatus() {
                return this.$store.state.appStore.humburgerMenuState;
            },
        },
        methods: {
            logOut() {
                UserService.logout(this.$store.state.userStore.token);
            },
            goToHelpPage() {
                //window.open('https://pomoc.mwc.pl/', '_blank');
                window.open('https://pomoc.mwc.pl/esesjaadmin/', '_blank');
            },

            goToServerStatus() {
                window.open('https://status.esesja.pl/', '_blank');
                
            },
            toggleMenu() {
                this.hamburgerMenuToogle = !this.hamburgerMenuToogle;
            },
            getNewInterpellationCountForDisplay(id) {
                InterpellationService.getNewInterpelationsCount().then((response) => {
                    this.$store.commit('setNewInterpellationsCount', response.data);
                    this.$store.commit('seInterpellationsMenuId', id);
                    this.$store.commit('seInterpellationsIsAccess', true);
                }).catch((e) => {
                    console.log(e);
                });
            },
            checkActionIsEnabled(actions) {
                var modules = encryptStorage.getItem('modules');
                var priv = encryptStorage.getItem('priv');
                var act = [];

                if (priv === undefined || priv.komunikator === undefined) {
                    UserService.logout();
                    return;
                }

                if (modules === undefined || modules.int === undefined || modules.inf === undefined || modules.s === undefined) {
                    UserService.logout();
                    return;
                }

                for (var i = 0; i < actions.length; i++) {
                    if (actions[i].id == 0) {
                        var temp_zaz = { ...actions[i] };
                        temp_zaz.links = [];
                        for (var k = 0; k < actions[i].links.length; k++) {
                            if (actions[i].links[k].id == 30) {
                                if (priv.komunikator)
                                    temp_zaz.links.push(actions[i].links[k]);
                            }
                            else if (actions[i].links[k].id == 50) {
                                if (modules.int == 1 && priv.zarzadzanie_interpelacjami == true) {
                                    this.getNewInterpellationCountForDisplay(actions[i].links[k].id);
                                    temp_zaz.links.push(actions[i].links[k]);
                                }  
                            }
                            else
                                temp_zaz.links.push(actions[i].links[k]);
                        }

                        act.push(temp_zaz);
                    }
                    if (actions[i].id == 1) {
                        var temp = { ...actions[i] };
                        temp.links = [];

                        for (var j = 0; j < actions[i].links.length; j++) {
                            if (actions[i].links[j].id == 0) {
                                if (modules.inf == 1)
                                    temp.links.push(actions[i].links[j]);
                            }
                            else if (actions[i].links[j].id == 20) {
                                if (modules.s == 1)
                                    temp.links.push(actions[i].links[j]);
                            }
                            else
                                temp.links.push(actions[i].links[j]);
                        }

                        act.push(temp);
                    }
                    if (actions[i].id == 2) {
                        act.push(actions[i]);
                    }
                }
                return act;
            }
        },
        components: {
            NavBlock,
        },
    };
</script>

<style lang="scss" scoped>
    .side-bar {
        height: 100vh;
        background: linear-gradient(139.17deg, #00082c 0%, #004dcc 148.71%);
        min-width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: sticky;
        top: 0px;
        z-index: 7;
        transition: min-width 350ms;
        transition: width 350ms;

        @media only screen and (max-width:1600px) {
            min-width: auto;
            width: 310px;
        }

        &__navigation {
            overflow-y: auto;
            max-height: calc(100vh - 220px);

            &::-webkit-scrollbar {
                width: 12px;
                height: 7px;
            }

            &::-webkit-scrollbar-thumb {
                background: #001347;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #001347;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff00;
                border-radius: 0px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f000;
            }
        }

        &__top {
            &__anchor {
                text-decoration: none;
                display: flex;
                flex-direction: row;
                align-items: baseline;
            }

            &__version {
                margin-left: 10px;
                color: #888;
            }

            &__logo {
                width: 160px;
                margin: 24px 0px 48px 32px;

                @media only screen and (max-width:1600px) {
                    margin: 24px 0px 18px 18px;
                    height: 40px;
                    width: auto;
                }
            }
        }



        &__bottom {
            margin-bottom: 10px;
            position: relative;
        }
    }


    .collapsed-menu {
        min-width: 90px;
        width: 90px;
        transition: min-width 350ms;
        transition: width 350ms;

        &__top {

            &__logo {
                display: none;
            }
        }
    }

    .collapsed-menu .side-bar__top{
        padding-top:20px;
    }

    .collapsed-menu .side-bar__top__logo {
        display: none;
    }
</style>
