import ApiService from "./api.service";

class SessionService extends ApiService {
    getSession(id) {
        return this.get(`Session/sessions/${id}`);
    }
    getSessionForEdit(id) {
        return this.get(`Session/sessions/${id}/for-edit`);
    }
    getSessionWithPointsById(id) {
        return this.get(`Session/sessionswithpoints/${id}`);
    }
    getSessionsBetweenDates(date_from, date_to) {
        return this.post("Session/sessions/between", { date_from, date_to });
    }
    getAllSessionsYear() {
        return this.get("session/getAllSessionsYear")
    }
    getSessionTemplate() {
        return this.get("Session/sessions/template");
    }
    getNextPreviousSessionId(sid, type) {
        return this.get(`Session/sessions/next-previous-session/${sid}/${type}`);
    }
    update(id, data) {
        return this.post(`Session/sessions/${id}`, data);
    }
    updateSessionWithAllVotings(id, data) {
        return this.post(`Session/sessions/${id}/with-votings-groups`, data);
    }
    getGroups(ses) {
        return this.get(`Group/groups/${ses}`);
    }
    getPoints(id) {
        return this.post(`Session/sessions/${id}/agenda-items`);
    }
    getPoint(id, sid) {
        return this.get(`Session/sessions/${sid}/agenda-items/${id}`);
    }
    addPoint(id, data) {
        return this.post(`Session/sessions/${id}/agenda-items-add`, data);
    }
    removePoint(id, sid) {
        return this.delete(`Session/sessions/${sid}/agenda-items/${id}`);
    }
    removeFewPoints(data, sid) {
        return this.delete(`Session/sessions/${sid}/agenda-items/bulk`, data);
    }
    reorderPointAttachemnt(pid, data) {
        return this.post(`Session/attachment/${pid}/attachemnt-reorder`, data);
    }
    movePoints(id, data) {
        return this.post(`Session/sessions/${id}/agenda-items/move`, data);
    }
    editPoint(id, data, sid) {
        return this.post(`Session/sessions/${sid}/agenda-items/${id}`, data);
    }
    duplicatePoints(id, pid, len) {
        return this.post(`Session/sessions/${id}/agenda-items/${pid}/copy/${len}`);
    }
    getPublicSessionLink(id) {
        return this.get(`Session/sessions/public/${id}`);
    }
    getPublicSessionInfoLink(id) {
        return this.get(`Session/sessions/publicInfo/${id}`);
    }
    getSessionMetadata(id) {
        return this.get(`Session/sessions/${id}/getMetadata`);
    }
    getSessionMetadataToWord(id) {
        return this.get(`Session/sessions/${id}/getMetadata/to-word`, { responseType: "arraybuffer" });
    }
    getExportedSessionToWord(sessionId) {
        return this.get(`Session/session/${sessionId}/export/to-word`, { responseType: "arraybuffer" });
    }
    getExportedSessionProtocolToWord(sessionId) {
        return this.get(`Session/session/${sessionId}/protocol/to-word`, { responseType: "arraybuffer" });
    }
    createExportedInvitationToWord(sessionId, data) {
        return this.post(`Session/session/${sessionId}/invitation/to-word`, data, { responseType: "arraybuffer" });
    }
    getDiscussionProfiles() {
        return this.get(`Session/sessions/discussionprofiles`);
    }
    copyPointsBetweenSessions(data) {
        return this.post(`Session/sessions/copy-agenda-item`,data);
    }

    addSessionTemplate(data) {
        return this.post(`Session/sessions/template`, data);
    }
    updateSessionTemplate(id, data) {
        return this.post(`Session/sessions/template/${id}`, data);
    }
    saveSessionAsTemplate(id)
    {
        return this.post(`Session/sessions/save-session-as-template/${id}`)
    }
    importAgendatItems(sessionId, data) {
        return this.post(`Session/sessions/${sessionId}/agenda-items/import`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    importAgendatItemsFronText(sessionId, data) {
        return this.post(`Session/sessions/${sessionId}/agenda-items/import-text`, data)
    }

    searchSession(data) {
        return this.post(`Session/sessions/search`, data);
    }

    duplicateSessions(sourceSessionId, data) {
        return this.post(`Session/sessions/${sourceSessionId}/duplicate`, data);
    }

    setPointIsDone(pid, sid) {
        return this.post(`Session/setPointIsDone/${pid}/${sid}`)
    }

    setPointIsHide(pid, sid) {
        return this.post(`Session/setPointIsHide/${pid}/${sid}`)
    }

    setPointIsTakenOff(pid, sid) {
        return this.post(`Session/setPointIsTakenOff/${pid}/${sid}`)
    }

    exportSessionVotingsToBip(sid) {
        return this.post(`Session/sessions/exportSessionVotingsToBip/${sid}`)
    }

    movePointLeft(sid, pid) {
        return this.post(`Session/sessions/${sid}/agenda-items/${pid}/move-left`)
    }

    getCameraTokenSSO(sid) {
        return this.get(`Session/getCameraTokenSSO/${sid}`)
    }

    lockSelectedSession(sid) {
        return this.post(`Session/lockSelectedSession/${sid}`)
    }

    unlockSelectedSession(sid) {
        return this.post(`Session/unlockSelectedSession/${sid}`)
    }

    sendMeetingRegarding(data) {
        return this.post(`Session/send-meeting-regarding`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    movePointToSpecyficPlace(sid, data) {
        return this.post(`Session/movePointToSpecyficPlace/${sid}`, data);
    }

    getSessionCodeToRecorded(sid) {
        return this.get(`Session/getSessionCodeToRecorded/${sid}`);
    }

    saveSessionAudioRecord(sessionId, data) {
        return this.post(`Session/sessions/${sessionId}/audio-records`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    getWordListPresence(sid) {
        return this.get(`Session/presenceList/to-word/${sid}`, { responseType: "arraybuffer" })
    }

    getWordListPresenceTime(sid) {
        return this.get(`Session/presenceListTime/to-word/${sid}`, { responseType: "arraybuffer" })
    }

    async getSessionNameById(sessionId) {
        try {
            let response = await this.getSessionWithPointsById(sessionId);

            if (response.status !== 200) {
                throw await Promise.reject(response);
            }
            let session = response.data;
            let sessionName = session.name;

            return sessionName;
        }
        catch {
            return `${sessionId}`;
        }
    }

    activateSession(sid) {
        return this.put(`Session/sessions/${sid}/activate`)
    }

    deActivateSession(sid) {
        return this.put(`Session/sessions/${sid}/deactivate`)
    }

    isSessionInInteractive(sid) {
        return this.get(`Session/sessions/${sid}/is-interactive`)
    }

    getSessionAudioLink(sid) { 
        return this.get(`Session/sessions/${sid}/audio-video-link`)
    }

    setSessionAudioLink(sid, data) {
        return this.post(`Session/sessions/${sid}/audio-video-link`, data)
    }

    hideMultiplePoints(sid, data) {
        return this.put(`Session/${sid}/hide-points`, data);
    }

    unhideMultiplePoints(sid, data) {
        return this.delete(`Session/${sid}/hide-points`, data);
    }


    takenOffMultiplePoints(sid, data) {
        return this.put(`Session/${sid}/taken-off-points`, data);
    }

    untakenOffMultiplePoints(sid, data) {
        return this.delete(`Session/${sid}/taken-off-points`, data);
    }
}

export default new SessionService();
