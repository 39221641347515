import Vuex from "vuex";

import { appDataStore } from "./appDataStore";
import { userStore } from "./userStore";
import { interactiveStore } from "./interactiveStore";
import { modalsStore } from "./modalsStore";
import { publicStore } from "./publicStore";
import { settingsStore } from "./settingsStore";
import { contactsStore } from "./contactsStore";
import { audioRecorderStore } from "./audioRecorderStore";
import { wizardNewCadenceStore } from "./wizard.new.cadence.store";
import { importContactStore } from "./import.contact.store";
import { uploaderStore } from "./uploader.store";
import { votingStore } from "./votingStore";
import { interpellationsStore } from "./interpellationsStore";
import { placeholderStore } from "./placeholderStore"; 
import { calendarStore } from "./calendarStore";

const store = new Vuex.Store({
    modules: {
        appStore: appDataStore,
        userStore: userStore,
        interactiveStore: interactiveStore,
        modals: modalsStore,
        public: publicStore,
        settings: settingsStore,
        contacts: contactsStore,
        audioRecorder: audioRecorderStore,
        wizardNewCadence: wizardNewCadenceStore,
        importContactStore: importContactStore,
        uploaderStore: uploaderStore,
        votingStore: votingStore,
        interpellationsStore: interpellationsStore,
        placeholderStore: placeholderStore,
        calendarStore: calendarStore
    },
});

export default store;
