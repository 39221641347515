<template>
    <section class="contacts">
        <section class="contacts__head">
            <h1 class="contacts__head__title">Wiadomości Email</h1>
            <section class="contact-top__action-buttons">
                <ButtonWithIcon icon="plus-small" style=""
                                text="Wyślij Email"
                                :classModified="true"
                                @click="(e) => sendEmailToUsers()" />
            </section>
        </section>
        <section class="contacts__statistics">
            <GenericStatisticsBoxVue :label="'Wysłano dzisiaj'"
                                     :value="emailStatistics.today"
                                     :icon="'send_e-mail'" />
            <GenericStatisticsBoxVue :label="'Wysłano w tym miesiącu'"
                                     :value="emailStatistics.thisMonth"
                                     :icon="'send_e-mail'" />
            <GenericStatisticsBoxVue :label="'Wysłano w poprzednim miesiącu'"
                                     :value="emailStatistics.lastMonth"
                                     :icon="'send_e-mail'" />
            <GenericStatisticsBoxVue :label="'Wysłano w tym roku'"
                                     :value="emailStatistics.thisYear"
                                     :icon="'send_e-mail'" />
        </section>
        <section class="contact-top">
            <section class="contact-top__search">
                <form class="contact-top__form">
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Wyszukaj wiadomość'" />
                        <SearchInputTextComponent v-model="internalRequestQuery.searchText"
                                                  :placeholder="'Wyszukaj wiadomość...'" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data wysłania od'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateFrom" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data wysłania do'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateTo" />
                    </section>
                </form>
            </section>
        </section>
        <Pagination :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />
        <section class="contacts__list" v-if="email.length > 0">
            <section class="contact-list">
                <section v-for="s in checkFilter" :key="s.id">
                    <section class="contact-box">
                        <section class="contact-box__top"
                                 @click="openMessage(s.id)">
                            <section>
                                <!--<span class="contact-box__title">Od: <b>{{ s.from }}</b> do: <b>{{s.to}}</b> ({{formatDateToDisplay(s.time)}}) </span>-->
                                <span class="contact-box__title">Do: <b>{{s.to}}</b> ({{formatDateToDisplay(s.time)}}) </span>
                                <img src="@/assets/icons/panel/Contacts/list-arrow.svg"
                                     class="contact-box__arrow"
                                     :class="{ 'contact-box__arrow--open': s.show }" />
                            </section>
                        </section>
                        <section style="padding: 10px" class="contact-box__people contact-box__people--open" v-if="s.show">
                            <section v-if="s.text.length > 0">
                                <section class="contact-box__people-container">
                                    <h4>Temat: {{s.subject}}</h4>
                                    <span style="margin-top: 5px;" v-html="s.text.replace(/\n/g, '<br />')"></span>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>

            </section>
        </section>
        <section class="contacts__list" v-else>
            <section class="contact-list">
                <section>
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section>
                                <span class="contact-box__title">Brak wiadomości EMail spełniających podane kryteria</span>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <Pagination :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />
        <Transition>
            <EmailModal v-if="showEmailModal" @closeEmail="closeEmailModal" :options="emailOptions" :selected="selectedEmail" />
        </Transition>
    </section>
</template>

<script charset="utf-8">
    import GenericStatisticsBoxVue from "@/components/BasicControls/GenericStatisticsBox.vue";
    import SearchLabelComponent from "@/components/BasicControls/SearchLabelComponent.vue";
    import SearchInputTextComponent from "@/components/BasicControls/SearchInputTextComponent.vue";
    import SearchInputDateComponent from "@/components/BasicControls/SearchInputDateComponent.vue";
    import Pagination from "@/components/BasicControls/Pagination.vue";
    import CommunicationService from "@/services/communication.service";
    import ContactService from "@/services/contact.service";
    import ButtonWithIcon from "@/components/BasicControls/ButtonWithIcon.vue";
    import EmailModal from "@/components/Panel/Content/Home/Modals/SendEmail.vue";
    import CommonHelper from "@/helpers/common.helper";
    import AlertHelper from "@/helpers/alerts.helper";
    export default {
        name: "email",
        data() {
            return {
                searchBar: "",
                email: [],
                showEmailModal: false,
                page: 1,
                selectedEmail: [],
                emailOptions: [],

                paginatedData: {
                    currentPage: -1,
                    itemsPerPage: 10,
                    totalItems: -1,
                },

                internalRequestQuery: {
                    searchText: null,
                    searchStatus: null,
                    dateFrom: null,
                    dateTo: null,
                    page: 1,
                    itemsPerPage: 10,
                },
                emailStatistics: {
                    today: 0,
                    thisMonth: 0,
                    lastMonth: 0,
                    thisYear: 0,
                },
                isLoading: {
                    data: false,
                    statistics: false,
                }
            };
        },
        watch: {
            isLoading: {
                deep: true,
                handler: function (value) {
                    if (value.data === false && value.statistics === false) {
                        this.$store.commit("hideLoader");
                    }
                    else {
                        this.$store.commit("showLoader");
                    }
                }
            },
            internalRequestQuery: {
                deep: true,
                handler: function (newValue) {
                    let queryParam = CommonHelper.getNonNullObjectEntries(newValue);
                    this.$router.replace({ name: "email", query: queryParam });
                    //this.fetchSmsData();
                    //this.fetchSessions(queryParam);
                }
            },
            $route() {
                if (this.$route.name === "email" && Object.keys(this.$route.query).length > 0) {
                    this.queryParamsToRequestSearch();
                    this.fetchEmailData();
                }

            },

            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },
        computed: {
            checkFilter() {
                return this.email.filter((item) => {
                    var elem = String(item.from + " " + item.to + " " + this.formatDateToDisplay(item.time))
                    return elem.toLowerCase().includes(this.searchBar.toLowerCase());
                });
            },
            requestQuery() {
                return this.$route.query;
            },

            isAnyModalOpen(){
                return this.showEmailModal
            }
        },
        async mounted() {
            this.fetchEmailStatistics();
            if (CommonHelper.isObjectIsEmpty(this.requestQuery)) {
                this.setDefaultRequestQuery();
            }
            else {
                this.queryParamsToRequestSearch();
                await this.fetchEmailData();
            }
        },
        methods: {

            async fetchEmailData() {
                try {
                    this.isLoading.data = true;
                    //this.$store.commit("showLoader");
                    let requestData = this.internalRequestDataToAsyncRequestData();
                    let response = await CommunicationService.searchEmails(requestData);
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }

                    if (response.data.items != null)
                        this.email = response.data.items;

                    if (response.data.paginated)
                        this.paginatedData = response.data.paginated;
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'email' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading.data = false;
                    //this.$store.commit("hideLoader");
                }
            },

            async fetchEmailStatistics() {
                try {
                    this.isLoading.statistics = true;

                    let response = await CommunicationService.getEmailStatistics();

                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }
                    this.emailStatistics = response.data;
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'email' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading.statistics = false;
                }
            },

            internalRequestDataToAsyncRequestData() {
                return {
                    data: {
                        searchText: this.internalRequestQuery.searchText,
                        searchStatus: this.internalRequestQuery.searchStatu,
                        dateFrom: this.internalRequestQuery.dateFrom,
                        dateTo: this.internalRequestQuery.dateTo,
                    },
                    paginated: {
                        page: this.internalRequestQuery.page,
                        itemsPerPage: this.internalRequestQuery.itemsPerPage,
                    }
                }
            },

            setDefaultRequestQuery() {
                this.clearRequestData();
                let queryParam = CommonHelper.getNonNullObjectEntries(this.internalRequestQuery);
                this.$router.replace({ name: "email", query: queryParam });
            },
            changePage(page) {
                this.internalRequestQuery.page = page;
            },
            clearRequestData() {
                this.internalRequestQuery.searchText = null;
                this.internalRequestQuery.searchStatus = null;
                this.internalRequestQuery.dateFrom = null;
                this.internalRequestQuery.dateTo = null;
                this.internalRequestQuery.page = 1;
                this.internalRequestQuery.itemsPerPage = 10;
            },

            queryParamsToRequestSearch() {

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "searchText")) {
                    this.internalRequestQuery.searchText = this.requestQuery.searchText;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateFrom")) {
                    this.internalRequestQuery.dateFrom = this.requestQuery.dateFrom;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateTo")) {
                    this.internalRequestQuery.dateTo = this.requestQuery.dateTo;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "_r")) {
                    this.clearRequestData();
                }
            },

            closeEmailModal() {
                this.showEmailModal = false;
            },
            async sendEmailToUsers() {
                try {
                    this.$store.commit('showLoader');
                    var result = (await ContactService.getUsersToSmsOrEmail(2)).data;
                    if (Object.keys(result).length > 0) {
                        this.emailOptions = result;
                        this.showEmailModal = true;
                    }
                    else {
                        this.$swal(AlertHelper.errorAlert("Żaden z zapisanych kontaktów nie posiada przypisanego adresu e-mail. Wysyłka wiadomości e-mail jest niemożliwa."));
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.$store.commit('hideLoader');
                }
            },
            openMessage(id) {
                for (var i = 0; i < this.email.length; i++) {
                    if (this.email[i].id == id)
                        if (this.email[i].show == false)
                            this.email[i].show = true;
                        else
                            this.email[i].show = false;
                }
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                });

                return d;
            },
            async nextPage() {
                this.page += 1;
                try {
                    this.email = [];
                    this.$store.commit("showLoader");
                    this.email = (await CommunicationService.getEmails(this.page)).data;
                    for (var i = 0; i < this.email.length; i++) {
                        this.email[i].show = false;
                    }
                    this.$store.commit("hideLoader");
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async previousPage() {
                if (this.page > 1) {
                    this.page -= 1;
                    try {
                        this.email = [];
                        this.$store.commit("showLoader");
                        this.email = (await CommunicationService.getEmails(this.page)).data;
                        for (var i = 0; i < this.email.length; i++) {
                            this.email[i].show = false;
                        }
                        this.$store.commit("hideLoader");
                    } catch (e) {
                        if (e.response.status == 401)
                            this.$router.push({ name: 'Login' })
                    }
                }
            }
        },
        components: {
            ButtonWithIcon,
            EmailModal,
            Pagination,
            SearchInputTextComponent,
            SearchInputDateComponent,
            SearchLabelComponent,
            GenericStatisticsBoxVue
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/search-component.scss";
</style>
