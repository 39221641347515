<template>
    <section class="meetings">
        <section class="meetings-top">
            <section class="meetings-top__left">
                <img
                    src="../../../../../assets/icons/panel/home/warning.svg"
                    class="meetings-top__icon"
                />
                <span class="meetings-top__title">Nadchodzące posiedzenia</span>
            </section>
            <section class="meetings-top__right">
                <section
                    v-for="button in filterButtons"
                    :key="button.id"
                    class="meetings-top__btn-wrapper"
                >
                    <FilterButton
                        :name="button.name"
                        :selected="button.selected"
                        @click="button.handler"
                    />
                </section>
            </section>
        </section>
        <section class="meetings__bottom" v-if="currentView === 'all'">
            <SmallLoader v-if="isLoading" />
            <section v-for="meeting in meetings" :key="meeting.id">
                <MeetingsWrapper
                    :name="meeting.name"
                    :date="meeting.date"
                    :list="meeting.meetingsList"
                />
            </section>
        </section>
        <section class="meetings__bottom" v-else>
            <SmallLoader v-if="isLoading" />
            <MeetingsWrapper
                :name="meetings[currentView].name"
                :date="meetings[currentView].date"
                :list="meetings[currentView].meetingsList"
            />
        </section>
    </section>
</template>

<script>
import MeetingsService from "../../../../../services/meetings.service";
import MeetingsWrapper from "./MeetingsWrapper.vue";
import FilterButton from "./FilterButton";
import SmallLoader from "../../../../BasicControls/SmallLoader.vue";

import moment from "moment";

export default {
    name: "Meetings",
    data() {
        return {
            filterButtons: [
                {
                    id: 0,
                    name: "Wszystkie",
                    selected: true,
                    handler: () => {
                        this.reloadAllData();
                        this.handleChangeFilterButton(0);
                    },
                },
                {
                    id: 1,
                    name: "Dzisiaj",
                    selected: false,
                    handler: () => {
                        this.sessionsToday();
                        this.currentView = 0;
                        this.handleChangeFilterButton(1);
                    },
                },
                {
                    id: 3,
                    name: "Kolejne posiedzenia",
                    date: '',
                    selected: false,
                    handler: () => {
                        this.sessionsThisWeek();
                        this.currentView = 1;
                        this.handleChangeFilterButton(2);
                    },
                },
            ],
            isLoading: false,
            currentView: "all",
            meetings: [
                {
                    id: 0,
                    filterDate: "today",
                    name: "dzisiaj",
                    date:  `${moment().format("dddd")}, ${moment().format("DD.MM")}`,
                    meetingsList: [],
                },
                {
                    id: 1,
                    filterDate: "thisWeek",
                    name: "Kolejne posiedzenia",
                    date: null,
                    meetingsList: [],
                },
            ],
        };
    },
    components: {
        MeetingsWrapper,
        FilterButton,
        SmallLoader,
    },
    mounted() {
        this.isLoading = true;
        this.reloadAllData();
    },
    computed: {
        meetingsReload() {
            return this.$store.state.appStore.groups;
        },
    },
    watch: {
        meetingsReload() {
            this.meetings[0].meetingsList = [];
            this.meetings[1].meetingsList = [];
            this.reloadAllData();
        },
    },
    methods: {
        reloadAllData() {
            this.isLoading = true;
            MeetingsService.sessions().then((response) => {
                this.isLoading = false;
                this.currentView = "all";
                this.meetings[0].meetingsList = [];
                this.meetings[1].meetingsList = [];

                response.data.forEach((item) => {
                    const today = moment().startOf("day").format();
                    const tomorrow = moment().add(1, "days").startOf("day").format();

                    const end = moment().endOf("month").format();
                    const endOfMonth = moment(end).add(1, 'months').format();
                    const inToday = moment().endOf("day").format();
                    const itemDate = moment(item.meeting_date).format();

                    if (moment(itemDate).isBetween(today, inToday)) {
                        this.meetings[0].meetingsList.push(item);
                    }
                    else {
                        const itemEndDate = moment(item.end_meeting_date).format();
                        if (moment(itemEndDate).isBefore(tomorrow)) {

                            let itemCopy = {...item}
                            this.meetings[0].meetingsList.push(itemCopy);
                        }

                    }

                    if (moment(itemDate).isBetween(tomorrow, endOfMonth)) {
                        this.meetings[1].meetingsList.push(item);
                    }
                    else {
                        if (item.end_meeting_date !== undefined && item.end_meeting_date !== null) {
                            const itemEndDate = moment(item.end_meeting_date).format();

                            if (moment(itemEndDate).isSameOrAfter(tomorrow) && moment(itemDate).isSameOrBefore(endOfMonth)) {
                                let itemCopy = { ...item }
                                this.meetings[1].meetingsList.push(itemCopy);
                            }
                        }
                    }
                });

                this.meetings[0].meetingsList = this.meetings[0].meetingsList.sort((a, b) => {
                    return new Date(a.meeting_date) - new Date(b.meeting_date)
                });

                this.meetings[1].meetingsList = this.meetings[1].meetingsList.sort((a, b) => {
                    return new Date(a.meeting_date) - new Date(b.meeting_date)
                });
            });
        },
        sessionsToday() {
            this.isLoading = true;
                this.isLoading = false;
                this.isTodayLoaded = true;

        },
        sessionsTomorrow() {
            this.isLoading = true;
                this.isLoading = false;
                this.isTommorowLoaded = true;

        },
        sessionsThisWeek() {
            this.isLoading = true;
                this.isLoading = false;
                this.isThisWeekLoaded = true;

        },
        sessionsThisMonth() {
            this.isLoading = true;
                this.isLoading = false;
                this.isThisMonthLoaded = true;

        },
        handleChangeFilterButton(id) {
            this.filterButtons = this.filterButtons.map((item) => {
                if (item.id === id) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }
                return item;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/scss/breakpoints.scss";

    .meetings {
        max-height: 86vh;
        background-color: white;
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        border-radius: 12px;
        box-sizing: border-box;

        &__bottom {
            max-height: 70vh;
            overflow-y: auto;
            position: relative;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }
    }

.meetings-top {
    border-bottom: 1px solid #dce4ee;
    padding: 20px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon {
        margin-right: 12px;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 132%;
        color: #00082c;
    }

    &__right {
        display: flex;
    }
}
</style>
